/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect, useRef } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useLlm, useViz } from "@/hooks";
import { Patent } from "@/types/types";
import { DocumentsTable } from "../components";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Loader } from "@/components";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { nanoid } from "nanoid";

interface PruneReferencesModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const PruneReferencesModal: React.FC<PruneReferencesModalProps> = ({
  open,
  onOpenChange,
}) => {
  const { pruneReferences } = useLlm();
  const { getProjectMetadata, updateProjectDetails } = useViz();

  const { currentProjectId } = useProjectStore();
  const { addErrorMessage, addLoadingMessage, removeLoadingMessage } = useAppStateStore();

  const [error, setError] = useState<string | boolean>(false);
  const [isSourcesLoading, setIsSourcesLoading] = useState(false);
  const [foundSources, setFoundSources] = useState<Patent[]>([]);
  const [selectedReferences, setSelectedReferences] = useState<string[]>([]);

  const tableRef = useRef<HTMLDivElement>(null);
  const [modalHeight, setModalHeight] = useState<string>("auto");

  useEffect(() => {
    if (open && tableRef.current) {
      const tableHeight = tableRef.current.offsetHeight;
      const maxHeight = Math.min(tableHeight + 200, window.innerHeight * 0.8);
      setModalHeight(`${maxHeight}px`);
    }
  }, [open, foundSources]);

  useEffect(() => {
    const pruneResponse = async () => {
      setIsSourcesLoading(true);
      const response = await pruneReferences(currentProjectId);
      setFoundSources(response.data);
      setIsSourcesLoading(false);
    };

    if (open) {
      resetModal();
      pruneResponse();
    }
  }, [open]);

  const resetModal = () => {
    setError(false);
    setFoundSources([]);
    setSelectedReferences([]);
    setIsSourcesLoading(false);
  };

  const handlePruneReferences = async (references: string[]) => {
    const key = nanoid();
    addLoadingMessage("Pruning references from project...", key);
    onOpenChange(false);
    const response = await updateProjectDetails(currentProjectId, {
      old_references: references,
    });
    if (!response.success) {
      addErrorMessage("Failed to prune references.");
      setError("Failed to prune references.");
      removeLoadingMessage(key);
      return;
    }
    await getProjectMetadata(currentProjectId);
   
    removeLoadingMessage(key);
    resetModal();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="sm:max-w-[800px] flex flex-col"
        style={{ height: modalHeight }}
      >
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            Prune references
          </DialogTitle>
        </DialogHeader>

        {isSourcesLoading  ? (
          <Loader
            message={
             "Finding sources to prune..."
            }
          />
        ) : (
          <>
            {foundSources?.length > 0 ? (
              <div ref={tableRef} className="flex-grow overflow-auto">
                <DocumentsTable
                  referenceDetails={foundSources}
                  searchResultsBoolean={true}
                  showHead={true}
                  setSelectedReferences={setSelectedReferences}
                  selectedReferences={selectedReferences}
                  isPruneTable={true}
                />
              </div>
            ) : (
              <div className="mt-4 text-center">
                <p>
                  No references found to prune. Prune manually from the table or charts.
                </p>
              </div>
            )}
          </>
        )}

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <DialogFooter>
          {foundSources?.length > 0 && (
            <>
              <Button
                onClick={() =>
                  handlePruneReferences(foundSources.map((source) => source.id))
                }
                variant={selectedReferences.length === 0 ? "default" : "outline"}
              >
                Prune All
              </Button>
              <Button
                onClick={() => handlePruneReferences(selectedReferences)}
                disabled={selectedReferences.length === 0}
              >
                Prune Selected
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PruneReferencesModal;
