/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useLlm, useViz } from "@/hooks";
import { Loader } from "@/components";
import { ElementType } from "@/types/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import MultiDocumentSelect from "@/components/files/MultiDocumentSelect";
import { nanoid } from "nanoid";

interface PruneInvalidityScoreModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Prune invalidity charts by score
 */
const PruneInvalidityScoreModal: React.FC<PruneInvalidityScoreModalProps> = ({
  open,
  handleClose,
}) => {
  const { pruneInvalidityScore } = useLlm();
  const { getProjectMetadata } = useViz();

  const { currentProjectId, selectedElementType } = useProjectStore();
  const { addErrorMessage, addLoadingMessage, removeLoadingMessage } = useAppStateStore();

  const [selectedReferences, setSelectedReferences] = useState<string[]>([]);
  const [score, setScore] = useState(50);

  useEffect(() => {
    resetModal();
  }, [open]);

  const resetModal = () => {
    setSelectedReferences([]);
    setScore(50);
  };

  const onClose = () => {
    resetModal();
    handleClose();
  };

  const handleConfirmPrune = async () => {
    const key = nanoid();
    addLoadingMessage("Pruning citations by score...", key);
    handleClose();
    const pruneResponse = await pruneInvalidityScore(
      currentProjectId,
      selectedReferences,
      score,
      selectedElementType == ElementType.FEATURE
    );
    if (!pruneResponse.success) {
      addErrorMessage("Failed to prune references.");
      removeLoadingMessage(key);
      return;
    }

    await getProjectMetadata(currentProjectId);
    removeLoadingMessage(key);
    resetModal();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Prune citations by score</DialogTitle>
          <DialogDescription>
            Remove the weakest selected percentile citations for the selected
            reference(s). This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
      
        <div className="flex items-center gap-2">
          <Select
            value={score.toString()}
            onValueChange={(value) => setScore(Number(value))}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Select percentile" />
            </SelectTrigger>
            <SelectContent>
              {[90, 80, 70, 60, 50, 40, 30, 20, 10].map((val) => (
                <SelectItem key={val} value={val.toString()}>
                  {val} %
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <MultiDocumentSelect handleChange={setSelectedReferences} general={true} />
        </div>
       
        <DialogFooter>
          <div className="flex items-center justify-end gap-2">
            <Button variant="outline" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleConfirmPrune}>
              Prune
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PruneInvalidityScoreModal;
