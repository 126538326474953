/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ScrollArea } from "@/components/ui/scroll-area";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import {
  SourceNumberButton,
  HighlightedText,
  PatentViewer,
  ReferenceImage,
  CopyToClipboardIconButton,
} from "@/components";
import { useProjectStore, useAppStateStore } from "@/store";
import { useViz } from "@/hooks";
import { SearchResult } from "@/types/types";

interface SearchResultsTableProps {
  searchResults: SearchResult[];
  sentSearchQuery: string;
  general: boolean;
  searchChatProjectId: string;
}

/**
 * @description Search results table component
 */
export default function SearchResultsTable({
  searchResults,
  sentSearchQuery,
  general,
  searchChatProjectId,
}: SearchResultsTableProps) {
  const { getFullDocument } = useViz();

  // Global state from store
  const { currentProjectId } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProjectDetails.documentsToNumbers
  );

  // Local state setup
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [patent, setPatent] = useState<any>(null);
  const [citationText, setCitationText] = useState<string | null>(null);

  const handlePreviewClick = async (refId: string, text: string) => {
    const id = general ? searchChatProjectId : currentProjectId;
    const response = await getFullDocument(id, refId);
    if (response.success) {
      setPatent(response.data);
      if (text.startsWith('"') && text.endsWith('"')) {
        const strippedText = text.substring(1, text.length - 1);
        setCitationText(strippedText);
      } else {
        setCitationText(text);
      }
      setShowPreview(true);
    } else {
      addErrorMessage("Error fetching reference details.");
    }
  };

  const handleClosePreview = () => {
    setPatent(null);
    setCitationText(null);
    setShowPreview(false);
  };

  return (
    <>
      {searchResults.length > 0 ? (
        <Table className="px-4">
          <TableHeader>
            <TableRow>
              <TableHead className="w-[12%]">Document</TableHead>
              <TableHead className="w-[10%]">Citation</TableHead>
              <TableHead className="w-[80%]">Text</TableHead>
              <TableHead className="w-[8%]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableCell className="p-0 m-0" colSpan={5}>
            <ScrollArea className="h-[calc(100vh-165px)]">
              <TableBody>
                {searchResults.map((result, index) => (
                  <TableRow key={index}>
                    <TableCellCondensed className="w-[12%]">
                      <SourceNumberButton
                        sourceNumber={
                          documentsToNumbers?.[
                            result.reference_id || result.referenceId
                          ] ?? ""
                        }
                        prefix={result.prefix || ""}
                      />
                    </TableCellCondensed>
                    <TableCellCondensed className="w-[10%] whitespace-nowrap">
                      {result.paragraph}
                    </TableCellCondensed>
                    <TableCellCondensed className="w-[80%]">
                      <div className="flex items-center">
                        <div
                          className="w-4 flex-shrink-0 mr-3 self-stretch min-h-[45px] rounded"
                          style={{ backgroundColor: result.color || "lightgray" }}
                        />
                        <div>
                          <div className="mb-2.5">
                            <HighlightedText
                              text={result.text}
                              highlight={sentSearchQuery}
                              highlightColor="#e1e9ff"
                            />
                          </div>
                          {result.figure_urls?.map((imageUrl) => (
                            <div key={imageUrl} className="mt-2">
                              <ReferenceImage
                                imageUrl={imageUrl}
                                imageName={imageUrl}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </TableCellCondensed>
                    <TableCellCondensed className="w-[8%]">
                      <div className="flex items-center space-x-2">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <CopyToClipboardIconButton
                                index={index}
                                text={result.text}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Copy to Clipboard</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() =>
                                  handlePreviewClick(
                                    result.reference_id || result.referenceId,
                                    result.text
                                  )
                                }
                              >
                                <EyeOpenIcon className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>View in Document</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </TableCellCondensed>
                  </TableRow>
                ))}
              </TableBody>
            </ScrollArea>
          </TableCell>
        </Table>
      ) : (
        <div className="text-center p-8">
          <h3 className="text-lg font-semibold mb-2">No results found.</h3>
          <p>
            Expand your search to include more sources or try a different search query.
          </p>
        </div>
      )}
      <PatentViewer
        open={showPreview}
        handleClose={handleClosePreview}
        patent={patent}
        citationText={citationText || ""}
      />
    </>
  );
}
