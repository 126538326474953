/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useProcessReferences } from "@/hooks";
import { useProjectStore } from "@/store";
import { ParentType } from "@/types/types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { CheckboxWithText } from "@/components/ui/checkbox";
import PatentNumbersTextField from "@/components/patents/PatentNumbersTextField";

interface AddReferenceByNumberModalProps {
  open: boolean;
  handleClose: () => void;
  general: boolean;
}

const AddReferenceByNumberModal: React.FC<AddReferenceByNumberModalProps> = ({
  open,
  handleClose,
  general,
}) => {
  const { addAndProcessReferences } = useProcessReferences();

  // Modal state
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(true);

  // Global store state
  const { currentProjectDetails, currentProjectId, currentParent } =
    useProjectStore();

  // Reset the modal state on close
  const handleResetModal = () => {
    setIsCheckboxChecked(true);
    handleClose();
  };

  // Handle the add to project button click
  const handleAddClick = async (refNums: string[]) => {
    handleClose();
    if (refNums.length > 0) {
      let filteredReferenceNumbers = refNums;
      if (currentProjectDetails.referenceDetails.length > 0) {
        const existingReferenceNumbers =
          currentProjectDetails.referenceDetails.map(
            (ref) => ref.number || ref.reference_number
          );
        // Filter out the reference numbers that are already in the project
        filteredReferenceNumbers = refNums.filter(
          (number) => !existingReferenceNumbers.includes(number)
        );
        // If there are no new reference numbers to add, return
        if (filteredReferenceNumbers.length === 0) {
          return;
        }
      }

      await addAndProcessReferences(
        currentProjectId,
        filteredReferenceNumbers,
        isCheckboxChecked,
        false
      );
    }
    setIsCheckboxChecked(true);
  };

  return (
    <Dialog open={open} onOpenChange={handleResetModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add by number</DialogTitle>
          <DialogDescription>
            Add references by entering a comma-separated list of published
            patents and applications numbers. If no country prefix is provided,
            it is assumed to be a US patent.
          </DialogDescription>
        </DialogHeader>

        {!general && (
          <CheckboxWithText
            id="complete-charts"
            checked={isCheckboxChecked}
            onCheckedChange={(checked) =>
              setIsCheckboxChecked(checked as boolean)
            }
            label="Complete claim and feature chart(s)"
          />
        )}

        <PatentNumbersTextField
          isPortfolio={currentParent === ParentType.PORT}
          handleAdd={handleAddClick}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddReferenceByNumberModal;
