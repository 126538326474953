import { create } from "zustand";
import { Patent, Project, ElementType } from "../types/types";

interface ProjectStore {
  organizationProjects: any[];
  projectsList: any[];
  documentsToNumbers: { [key: string]: string };
  currentProjectId: string;
  currentProjectDetails: Project;
  currentPortfolioId: string;
  currentPortfolioDetails: Project;
  currentParent: string;
  summaryChartData: any[];
  summaryChartHeaders: string[];
  summaryChartRowNames: string[];
  summaryChartRowHeightCollapsed: number;
  chartData: any[];
  subjectDetails: Patent;
  selectedReferenceDetails: any[];
  selectedDocumentIds: string[];
  selectedElements: any[];
  selectedColors: string[];
  selectedElementType: ElementType;
  elementEditIndex: number;
  archivedProjectsList: any[];

  updateOrganizationProjects: (organizationProjects: any[]) => void;
  updateProjectsList: (projectsList: any[]) => void;
  addProjectToProjectsList: (project: any) => void;
  updateDocumentsToNumbers: (documentsToNumbers: { [key: string]: string }) => void;
  updateCurrentProjectId: (currentProject: string) => void;
  updateCurrentProjectDetails: (details: Project) => void;
  updateCurrentPortfolioId: (currentPortfolioId: string) => void;
  updateCurrentPortfolioDetails: (details: Project) => void;
  updateSummaryChartData: (data: any[]) => void;
  updateChartData: (data: any[]) => void;
  clearChartData: () => void;
  updateSubjectDetails: (details: Patent) => void;
  updateSelectedReferenceDetails: (details: any[]) => void;
  addSelectedReferenceDetails: (refDetails: any) => void;
  removeSelectedReferenceDetails: (refId: string) => void;
  updateSelectedDocumentIds: (selectedDocumentIds: string[]) => void;
  updateSelectedElements: (selectedElements: any[]) => void;
  updateSummaryChartHeaders: (headers: string[]) => void;
  updateSummaryChartRowNames: (rowNames: string[]) => void;
  updateSummaryChartRowHeightCollapsed: (rowHeightCollapsed: number) => void;
  updateArchivedProjectsList: (projectsList: any[]) => void;
  updateCurrentParent: (parent: string) => void;
  updateSelectedColors: (colors: string[]) => void;
  updateSelectedElementType: (elementType: ElementType) => void;
  updateElementEditIndex: (index: number) => void;
}

const initialState: Omit<
  ProjectStore,
  | "updateOrganizationProjects"
  | "updateProjectsList"
  | "addProjectToProjectsList"
  | "updateDocumentsToNumbers"
  | "updateCurrentProjectId"
  | "updateCurrentProjectDetails"
  | "updateCurrentParent"
  | "updateSummaryChartData"
  | "updateChartData"
  | "updateSubjectDetails"
  | "updateSelectedReferenceDetails"
  | "addSelectedReferenceDetails"
  | "removeSelectedReferenceDetails"
  | "updateSelectedDocumentIds"
  | "updateSelectedElements"
  | "clearChartData"
  | "updateSummaryChartHeaders"
  | "updateSummaryChartRowNames"
  | "updateSummaryChartRowHeightCollapsed"
  | "updateArchivedProjectsList"
  | "updateCurrentPortfolioId"
  | "updateCurrentPortfolioDetails"
  | "updateCurrentParent"
  | "updateSelectedColors"
  | "updateSelectedElementType"
  | "updateElementEditIndex"
> = {
  organizationProjects: [],
  projectsList: [],
  archivedProjectsList: [],
  documentsToNumbers: {},
  currentProjectId: "",
  currentProjectDetails: {
    id: "",
    name: "",
  
    subjectId: "",
    referenceIds: [],
    referenceDetails: [],
    documentIds: [],
    keywords: [],
    context: null,
    priorityDate: null,
  },
  currentPortfolioId: "",
  currentPortfolioDetails: {
    id: "",
    name: "",
    projects: [],
    type: "",
  },
  currentParent: "",
  summaryChartData: [],
  summaryChartHeaders: [],
  summaryChartRowNames: [],
  summaryChartRowHeightCollapsed: 0,
  chartData: [],
  subjectDetails: {
    id: "",
    number: "",
    name: "",
    publicationDate: "",
    filingDate: "",
    inventors: "",
    assignee: "",
    primaryExaminer: "",
    nickname: "",
    notes: "",
    claims: [],
    features: [],
    fullBody: [],
    figureUrls: [],
  },
  selectedReferenceDetails: [],
  selectedDocumentIds: [],
  selectedElements: [],
  selectedColors: [],
  selectedElementType: ElementType.CLAIM,
  elementEditIndex: -1,
};

const useProjectStore = create<ProjectStore>((set) => ({
  ...initialState,

  updateCurrentParent: (parent) => set(() => ({ currentParent: parent })),

  updateOrganizationProjects: (organizationProjects) =>
    set(() => ({ organizationProjects })),

  updateProjectsList: (projectsList) => set(() => ({ projectsList })),

  updateArchivedProjectsList: (projectsList) =>
    set(() => ({ archivedProjectsList: projectsList })),

  addProjectToProjectsList: (project) =>
    set((state) => ({
      projectsList: [...state.projectsList, project],
    })),

  updateDocumentsToNumbers: (documentsToNumbers) => set(() => ({ documentsToNumbers })),

  updateCurrentProjectId: (currentProjectId) => {
    localStorage.setItem("currentProject", currentProjectId);
    set({ currentProjectId });
  },

  updateCurrentProjectDetails: (details) =>
    set(() => ({ currentProjectDetails: details })),

  updateCurrentPortfolioId: (currentPortfolioId) => set(() => ({ currentPortfolioId })),

  updateCurrentPortfolioDetails: (details) =>
    set(() => ({ currentPortfolioDetails: details })),

  updateSummaryChartData: (data) => set(() => ({ summaryChartData: data })),

  updateChartData: (data) => set(() => ({ chartData: data })),

  clearChartData: () =>
    set(() => ({
      chartData: [],
      summaryChartData: [],
    })),

  updateSubjectDetails: (details) => set(() => ({ subjectDetails: details })),

  updateSelectedReferenceDetails: (details) =>
    set(() => ({ selectedReferenceDetails: details })),

  addSelectedReferenceDetails: (refDetails) =>
    set((state) => ({
      selectedReferenceDetails: [...state.selectedReferenceDetails, refDetails],
    })),

  removeSelectedReferenceDetails: (refId) =>
    set((state) => ({
      selectedReferenceDetails: state.selectedReferenceDetails.filter(
        (details) => details.id !== refId
      ),
    })),

  updateSelectedDocumentIds: (selectedDocumentIds) =>
    set(() => ({ selectedDocumentIds })),

  updateSelectedElements: (selectedElements) => set(() => ({ selectedElements })),

  updateSummaryChartHeaders: (headers) => set(() => ({ summaryChartHeaders: headers })),

  updateSummaryChartRowNames: (rowNames) =>
    set(() => ({ summaryChartRowNames: rowNames })),

  updateSummaryChartRowHeightCollapsed: (rowHeightCollapsed) =>
    set(() => ({ summaryChartRowHeightCollapsed: rowHeightCollapsed })),

  updateSelectedColors: (colors) => set(() => ({ selectedColors: colors })),

  updateSelectedElementType: (elementType) =>
    set(() => ({ selectedElementType: elementType })),

  updateElementEditIndex: (index) => set(() => ({ elementEditIndex: index })),
}));

export default useProjectStore;
