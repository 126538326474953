/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import useViz from "@/hooks/useViz";
import {
  splitId,
  getShortenedNum,
  getColumnHeaders,
} from "@/utils/projectUtils";
import { useProjectStore, useAppStateStore } from "@/store";
import { SummaryChartData } from "@/types/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Loader } from "@/components";
import { cn } from "@/lib/utils";

interface SummaryTableProps {
  refreshReferenceData: (data: any) => void;
  handleSelect: (
    isExpanded: boolean,
    claimNumber: string | null,
    key: string
  ) => void;
  isExpanded: boolean;
}

const SummaryTable: React.FC<SummaryTableProps> = ({
  refreshReferenceData,
  handleSelect,
  isExpanded,
}) => {
  const { deleteReferenceFromProject } = useViz();

  // Global state from store
  const { addErrorMessage } = useAppStateStore();
  const {
    currentProjectId,
    selectedReferenceDetails,
    summaryChartData,
    updateSummaryChartData,
    summaryChartHeaders,
    summaryChartRowNames,
    updateSummaryChartRowNames,
    summaryChartRowHeightCollapsed,
    updateSummaryChartHeaders,
  } = useProjectStore();
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProjectDetails.documentsToNumbers
  );

  // Local state setup
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [useShortHeaders, setUseShortHeaders] = useState<boolean[]>([]);
  const headerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const observerRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    setIsLoading(false);
    if (summaryChartData.length > 0 && summaryChartHeaders.length > 0) {
      updateSummaryChartRowNames(
        summaryChartData.map((row: SummaryChartData) => row.claim_number)
      );
    }
  }, [summaryChartData, summaryChartHeaders]);

  useEffect(() => {
    const updateHeaderWidth = (index: number, width: number) => {
      setUseShortHeaders((prev) => {
        const newState = [...prev];
        newState[index] = width < 200;
        return newState;
      });
    };

    observerRef.current = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const index = headerRefs.current.findIndex(
          (ref) => ref === entry.target
        );
        if (index !== -1) {
          updateHeaderWidth(index, entry.contentRect.width);
        }
      });
    });

    headerRefs.current.forEach((ref, index) => {
      if (ref) {
        observerRef.current?.observe(ref);
        updateHeaderWidth(index, ref.offsetWidth);
      }
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [summaryChartHeaders]);

  // Deletes a reference from the project
  const handleDelete = useCallback(async () => {
    if (selectedKey) {
      const referenceId = selectedKey;
      const deleteResponse = await deleteReferenceFromProject(
        currentProjectId,
        referenceId
      );
      if (!deleteResponse.success) {
        addErrorMessage(`Error deleting reference from project.`);
        return;
      }
      // remove the key from each row in the summary data
      const newSummaryData = summaryChartData.map((row: SummaryChartData) => {
        const newRow = { ...row };
        delete newRow[selectedKey];
        return newRow;
      });

      updateSummaryChartData(newSummaryData);
      updateSummaryChartHeaders(getColumnHeaders(newSummaryData));

      if (selectedReferenceDetails && Array.isArray(selectedReferenceDetails)) {
        const hasMatchingReferenceId = selectedReferenceDetails.some(
          (details) => details.id === referenceId
        );

        if (hasMatchingReferenceId) {
          refreshReferenceData(null);
        }
      }
    }
    setSelectedKey(null);
  }, [
    selectedKey,
    currentProjectId,
    summaryChartData,
    deleteReferenceFromProject,
    addErrorMessage,
    updateSummaryChartData,
    selectedReferenceDetails,
    refreshReferenceData,
  ]);

  const memoizedSummaryChartData = useMemo(() => {
    return summaryChartData.map((row, rowIndex) => (
      <div
        key={rowIndex}
        className={`contents m-1 ${isExpanded ? "mb-2" : ""}`}
      >
        {isExpanded && (
          <div className="sticky left-0 z-50 min-w-[45px] max-w-[45px] bg-background flex items-center justify-center">
            {summaryChartRowNames[rowIndex]}
          </div>
        )}
        {summaryChartHeaders.map((key, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            className="px-1 bg-background"
            style={{
              minWidth: useShortHeaders[colIndex] ? "45px" : "200px",
            }}
          >
            <div
              key={`${rowIndex}-${colIndex}`}
              className={`px-1 w-full h-full flex items-center justify-center cursor-pointer ${
                isExpanded ? "rounded m-1" : ""
              } transition-colors duration-200`}
              style={{
                backgroundColor: row[key] || "lightgray",
                height: isExpanded
                  ? "25px"
                  : `${summaryChartRowHeightCollapsed}px`,
                minWidth: useShortHeaders[colIndex] ? "45px" : "200px",
              }}
              onClick={() => handleSelect(isExpanded, row.claim_number, key)}
            />
            <DropdownMenu>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={handleDelete}>
                  Remove from project
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ))}
      </div>
    ));
  }, [
    summaryChartData,
    isExpanded,
    summaryChartRowHeightCollapsed,
    summaryChartRowNames,
    summaryChartHeaders,
    handleDelete,
    handleSelect,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col relative pt-2">
      <div
        className={cn(
          "overflow-x-auto w-[calc(100vw-130px)]",
          isExpanded
            ? "h-[50vh] overflow-y-auto"
            : "h-[220px] overflow-y-hidden"
        )}
      >
        <div>
          <div
            className="grid gap-x-1 bg-background w-full"
            style={{
              gridTemplateColumns: `${isExpanded ? "45px " : ""}repeat(${summaryChartHeaders.length}, minmax(45px, 1fr))`,
            }}
          >
            {isExpanded && (
              <div className="sticky bg-background left-0 top-0 z-50 min-w-[45px] max-w-[45px]" />
            )}
            {summaryChartHeaders.map((key, idx) => (
              <div
                key={idx}
                ref={(el) => (headerRefs.current[idx] = el)}
                className="z-10 bg-background cursor-pointer px-2 pb-1 font-medium sticky top-0 flex items-center justify-center text-center"
                style={{
                  minWidth: useShortHeaders[idx] ? "45px" : "200px",
                }}
                onClick={() => handleSelect(false, null, key)}
              >
                {useShortHeaders[idx] ? (
                  getShortenedNum(documentsToNumbers?.[key] ?? "")
                ) : (
                  <>{splitId(documentsToNumbers?.[key] ?? "")}</>
                )}
              </div>
            ))}

            {memoizedSummaryChartData}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryTable;
