/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Patent } from "@/types/types";
import { SourceNumberButton } from "@/components";

interface AssigneePortfolioTableProps {
  patents: Patent[];
  setSelectedReferences?: (references: string[]) => void;
  selectedReferences?: string[];
}

const AssigneePortfolioTable: React.FC<AssigneePortfolioTableProps> = ({
  patents,
  setSelectedReferences,
  selectedReferences,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelect = (event: ChangeEvent<HTMLInputElement>, ref: Patent) => {
    let newItem;

    newItem = ref.number;
    if (event.target.checked) {
      const newSelected = [...(selectedReferences || []), newItem];
      setSelectedReferences?.(newSelected);
    } else {
      const newSelected = (selectedReferences || []).filter((item) => item !== newItem);
      setSelectedReferences?.(newSelected);
    }
  };

  useEffect(() => {
    if (selectAll) {
      const allReferences = patents.map((patent) => patent.number);
      setSelectedReferences?.(allReferences);
    } else if (selectedReferences?.length === patents.length) {
      setSelectedReferences?.([]);
    }
  }, [selectAll]);

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
  };

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[15%]">Number</TableHead>
            <TableHead className="w-[25%]">Title</TableHead>
            <TableHead className="w-[15%]">Priority Date</TableHead>
            <TableHead className="w-[15%]">Inventor</TableHead>
            <TableHead className="w-[15%]">Assignee</TableHead>
            <TableHead className="w-[10%]">Classifications</TableHead>
            <TableHead className="w-[5%] text-right">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Checkbox
                    checked={selectAll}
                    onCheckedChange={(checked) => {
                      setSelectAll(checked as boolean);
                      handleSelectAll({
                        target: { checked },
                      } as ChangeEvent<HTMLInputElement>);
                    }}
                  />
                </TooltipTrigger>
                <TooltipContent>Select All</TooltipContent>
              </Tooltip>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableCell className="p-0 m-0" colSpan={7}>
          <ScrollArea className="h-[calc(80vh-64px)]">
            <TableBody>
              {patents &&
                patents
                  .filter((reference) => reference.number)
                  .map((reference) => {
                    const isSelected = selectedReferences?.includes(reference.number);
                    return (
                      <TableRow
                        key={reference.number}
                        className={isSelected ? "bg-muted/50" : ""}
                      >
                        <TableCell>
                          {reference.number && reference.number.length < 20 ? (
                            <SourceNumberButton
                              sourceNumber={reference.number}
                              prefix={reference.prefix || ""}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>{reference.title || "-"}</TableCell>
                        <TableCell>
                          {reference.filingDate || reference.priorityDate
                            ? new Date(
                                reference.filingDate || reference.priorityDate
                              ).toLocaleDateString()
                            : "—"}
                        </TableCell>
                        <TableCell>
                          {reference.inventors
                            ? reference.inventors.replace(/,\s*$/, "")
                            : "-"}
                        </TableCell>
                        <TableCell>{reference.assignee || "-"}</TableCell>
                        <TableCell>
                          {reference.cpcCodes
                            ? reference.cpcCodes.map((code, index) => (
                                <Badge
                                  key={index}
                                  variant="secondary"
                                  className="mr-1 mb-1"
                                >
                                  {code.trim()}
                                </Badge>
                              ))
                            : "-"}
                        </TableCell>
                        <TableCell className="text-right">
                          <Checkbox
                            checked={(selectedReferences || []).includes(
                              reference.number
                            )}
                            onCheckedChange={(checked) => {
                              handleSelect(
                                {
                                  target: { checked },
                                } as ChangeEvent<HTMLInputElement>,
                                reference
                              );
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </ScrollArea>
        </TableCell>
      </Table>
    </div>
  );
};

export default AssigneePortfolioTable;
