/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useEffect, useMemo } from "react";
import { useAdminStore, useUserStore } from "@/store";
import { useDocumentTitle, useOrgs } from "@/hooks";
import { Loader } from "@/components";
import { RoleType } from "@/types/types";
import { InvoicesTable, AdminPageLayout } from "@/features/admin/components";

/**
 * @description Super admin page component
 */
function SuperAdminBillingPage() {
  useDocumentTitle("Super Admin - Billing - &AI");

  const { getSuperAdminDetails } = useOrgs();
  const { superAdminInvoices } = useAdminStore();

  // Global state from store
  const { role } = useUserStore();

  useEffect(() => {
    if (!superAdminInvoices) {
      getSuperAdminDetails();
    }
  }, [superAdminInvoices, getSuperAdminDetails]);
  const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role]);

  return (
    <AdminPageLayout isSuperAdmin={true}>
      {isSuperAdmin && (
        <div className="space-y-8 mt-2">
          {superAdminInvoices === null ? (
            <Loader />
          ) : (
            <InvoicesTable invoices={superAdminInvoices} />
          )}
        </div>
      )}
    </AdminPageLayout>
  );
}

export default SuperAdminBillingPage;
