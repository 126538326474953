import React, { useState, useEffect } from "react";
import { useLlm, useVector, useViz } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { ProjectPage, Loader } from "@/components";
import {
  ExpertKnowledge,
  TermsAndDefinitions,
  ImportantFacts,
  PriorArtSearchInstructions,
  PriorArtFilterInstructions,
} from "@/components/context/context";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import DragAndDropFileArea from "@/components/files/DragAndDropFileArea";
import UploadedFileDisplay from "@/components/files/UploadedFileDisplay";
import { PatentContext, ProjectType } from "@/types/types";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogCancel,
} from "@/components/ui/alert-dialog";
import { Paragraph } from "@/components/ui/typography";
import { Spinner } from "@/components/ui/spinner";
import SearchResultsTable from "@/features/explore/search/components/SearchResultsTable";
import { nanoid } from "nanoid";



const defaultContextData: PatentContext = {
  expertKnowledge: "",
  termsAndDefinitions: {},
  importantFacts: [],
  priorArtSearchInstructions: "",
  priorArtSearchFilterInstructions: "",
  relevantDocumentIds: [],
};

const ContextPage: React.FC = () => {
  const { uploadFile } = useViz();
  const { rerankReferences, semanticSearchDocuments } = useVector();
  const { currentProjectDetails, currentProjectId, subjectDetails} = useProjectStore();
  const { addErrorMessage, addSuccessMessage, addLoadingMessage, removeLoadingMessage } = useAppStateStore();
  const [isLoading, setIsLoading] = useState(false);
  const {
    generatePatentContextFromId,
    generatePatentContextFromText,
    getPatentContext,
    savePatentContext,
  } = useLlm();
  const [contextData, setContextData] = useState<PatentContext>(currentProjectDetails.context ?? defaultContextData);
  const [showReprocessDialog, setShowReprocessDialog] = useState(false);
  const [isReprocessingReferences, setIsReprocessingReferences] = useState(false);
  const [isBulkAddModalOpen, setIsBulkAddModalOpen] = useState(false);
  const [bulkAddType, setBulkAddType] = useState<"facts" | "terms">("facts");
  const [bulkAddContent, setBulkAddContent] = useState("");
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sentSearchQuery, setSentSearchQuery] = useState("");
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const searchTerm = async (query: string) => {
    setSentSearchQuery(query);
    setShowSpinner(true);
    setIsSearchModalOpen(true);

    const response = await semanticSearchDocuments(
      currentProjectId,
      query,
      [subjectDetails.id],
      "semantic"
    );

    if (response.success) {
      setSearchResults(response.data.sources[0]);
    } else {
      addErrorMessage("Error searching for term.");
    }
    setShowSpinner(false);
  };

  const handleSearchResultsClose = () => {
    setIsSearchModalOpen(false);
    setSearchResults([]);
    setSentSearchQuery("");
    setShowSpinner(false);
  };

  const isContextEmpty = () => {
    return (
      !contextData.expertKnowledge &&
      Object.keys(contextData.termsAndDefinitions).length === 0 &&
      contextData.importantFacts.length === 0 &&
      !contextData.priorArtSearchInstructions &&
      !contextData.priorArtSearchFilterInstructions
    );
  };

  const handleClearConfirmation = () => {
    setShowClearConfirmation(true);
  };

  useEffect(() => {
    const fetchContextData = async () => {
      try {
        const response = await getPatentContext(currentProjectId);
        setContextData(response.data);
      } catch (error) {
        console.error("Error fetching patent context:", error);
        addErrorMessage("Failed to fetch patent context");
      }
    };
    if (!currentProjectDetails.context) {
      setIsLoading(true);
      fetchContextData();
      setIsLoading(false);
    }
  }, [currentProjectDetails.context]);

  // Page name
  const pageName = currentProjectDetails.name
    ? `${currentProjectDetails.name} - Context - &AI`
    : "Context - &AI";

  const handleSave = async () => {
    try {
      await savePatentContext(currentProjectId, contextData);
      addSuccessMessage("Patent context saved successfully");
    } catch (error) {
      console.error("Error saving patent context:", error);
      addErrorMessage("Failed to save patent context. Please try again.");
    }
  };

  const handleClear = async () => {
    try {
      setContextData(defaultContextData);
      await savePatentContext(currentProjectId, defaultContextData);
      setIsEditing(false);
      addSuccessMessage("Patent context cleared successfully");
    } catch (error) {
      addErrorMessage("Failed to clear patent context. Please try again.");
    }
  };

  const handleBulkAdd = () => {
    const items = bulkAddContent.split("\n").filter(item => item.trim() !== "");
    
    if (bulkAddType === "facts") {
      setContextData({
        ...contextData,
        importantFacts: [...contextData.importantFacts, ...items]
      });
    } else {
      const newTerms = { ...contextData.termsAndDefinitions };
      items.forEach(item => {
        const [term, definition] = item.split(":");
        if (term && definition) {
          newTerms[term.trim()] = definition.trim();
        }
      });
      setContextData({
        ...contextData,
        termsAndDefinitions: newTerms
      });
    }

    setBulkAddContent("");
    setIsBulkAddModalOpen(false);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isGeneratingContext, setIsGeneratingContext] = useState(false);
  const [url, setUrl] = useState("");
  const [inputContext, setInputContext] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"url" | "document" | "input" | null>(null);
  const [files, setFiles] = useState<File[]>([]);

  const setContextDataFromPatentContext = (patentContext: PatentContext) => {
    setContextData({
      expertKnowledge:
        patentContext.expertKnowledge ?? defaultContextData.expertKnowledge,
      termsAndDefinitions:
        patentContext.termsAndDefinitions ?? defaultContextData.termsAndDefinitions,
      importantFacts: patentContext.importantFacts ?? defaultContextData.importantFacts,
      priorArtSearchInstructions:
        patentContext.priorArtSearchInstructions ??
        defaultContextData.priorArtSearchInstructions,
      priorArtSearchFilterInstructions:
        patentContext.priorArtSearchFilterInstructions ??
        defaultContextData.priorArtSearchFilterInstructions,
      relevantDocumentIds:
        patentContext.relevantDocumentIds ?? defaultContextData.relevantDocumentIds,
    });
  };

  const handleReprocessReferences = async () => {
    const key = nanoid();
   
    try {
      addLoadingMessage("Reprocessing references...", key);
      setShowReprocessDialog(false);
      await rerankReferences(currentProjectId);
      addSuccessMessage("References reprocessed");
    } catch (error) {
      console.error("Error reprocessing references:", error);
      addErrorMessage("Failed to reprocess references. Please try again.");
    } finally {
      removeLoadingMessage(key);
      
    }
  };

  const handleAddContext = async (source: string) => {
    try {
      let patentContext: PatentContext;
      switch (source) {
        case "subject-patent":
          setIsGeneratingContext(true);
          const patentResponse = await generatePatentContextFromId(
            currentProjectDetails.subjectId
          );
          patentContext = patentResponse.data;
          setContextDataFromPatentContext(patentContext);
          setIsGeneratingContext(false);
          break;
        case "document":
          setModalType("document");
          setIsModalOpen(true);
          break;
        case "url":
          setModalType("url");
          setIsModalOpen(true);
          break;
        case "input":
          setModalType("input");
          setIsModalOpen(true);
          break;
      }
    } catch (error) {
      console.error("Error generating patent context:", error);
      setIsGeneratingContext(false);
      addErrorMessage("Failed to generate patent context. Please try again.");
    }
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleUrlSubmit = () => {
    if (isValidUrl(url)) {
      setIsModalOpen(false);
      setUrl("");
    } else {
      addErrorMessage("Invalid URL. Please enter a valid URL.");
    }
  };

  const handleFileChange = (selectedFiles: File[]) => {
    setFiles(selectedFiles);
  };

  const handleFileUpload = async () => {
    try {
      setIsModalOpen(false);
      setIsGeneratingContext(true);
      const response = await uploadFile(files[0]);
      const document_ids = response.data.map((file: any) => file.document_id);
      const patentContext = await generatePatentContextFromId(document_ids[0]);
      setContextData(patentContext.data);
    } catch (error) {
      console.error("Error generating patent context:", error);
      addErrorMessage("Failed to generate patent context. Please try again.");
    } finally {
      setFiles([]);
      setIsGeneratingContext(false);
    }
  };

  const handleInputSubmit = async () => {
    try {
      setIsGeneratingContext(true);
      setIsModalOpen(false);
      const textResponse = await generatePatentContextFromText(inputContext);
      const patentContext: PatentContext = textResponse.data;
      setContextDataFromPatentContext(patentContext);
      setInputContext("");
    } catch (error) {
      console.error("Error generating patent context:", error);
      addErrorMessage("Failed to generate patent context. Please try again.");
    } finally {
      setIsGeneratingContext(false);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSaveContext = () => {
    if (isEditing) {
      handleSave();
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  return (
    <ProjectPage pageName={pageName}>
      <div className="flex flex-col h-[calc(100vh-60px)]">
        <div className="flex justify-between items-center p-3 bg-background z-10 border-b">
          <div className="gap-2 flex flex-row">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant={isEditing || isGeneratingContext ? "outline" : "default"} disabled={isGeneratingContext || isEditing}>
                  Generate Context
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {currentProjectDetails.type !== ProjectType.APP && (
                  <DropdownMenuItem
                    onClick={() =>
                      handleAddContext("subject-patent").catch(console.error)
                    }
                  >
                    From subject patent
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem onClick={() => handleAddContext("document")}>
                  From document
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleAddContext("input")}>
                  Input context
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button
              onClick={() => setShowReprocessDialog(true)}
              variant="outline"
              disabled={isGeneratingContext || isEditing}
            >
              Process References
            </Button>
          </div>
          <div className="space-x-2">
            {isEditing && !isContextEmpty() && (
              <Button
                disabled={isGeneratingContext}
                onClick={handleClearConfirmation}
                variant="outline"
              >
                Clear All
              </Button>
            )}
            <Button disabled={isGeneratingContext} onClick={handleSaveContext}>
              {isEditing ? "Save Changes" : "Edit Context"}
            </Button>
          </div>
        </div>
        <ScrollArea className="flex-grow">
          <div className="p-3">
            {isLoading ? (
              <div className="mt-10">
                <Loader />
              </div>
            ) : isGeneratingContext ? (
              <div className="mt-10">
                <Loader message="Generating context..." />
              </div>
            ) : (
              <div className="flex flex-col space-y-4">
                <ExpertKnowledge
                  value={contextData.expertKnowledge}
                  onChange={(value) =>
                    setContextData({ ...contextData, expertKnowledge: value })
                  }
                  isEditing={isEditing}
                />
                <TermsAndDefinitions
                  terms={contextData.termsAndDefinitions}
                  onChange={(terms) =>
                    setContextData({
                      ...contextData,
                      termsAndDefinitions: terms as Record<string, string>,
                    })
                  }
                  isEditing={isEditing}
                  onBulkAdd={() => {
                    setBulkAddType("terms");
                    setIsBulkAddModalOpen(true);
                  }}
                  searchTerm={searchTerm}
                />
                <ImportantFacts
                  facts={contextData.importantFacts}
                  onChange={(facts) =>
                    setContextData({ ...contextData, importantFacts: facts })
                  }
                  isEditing={isEditing}
                  onBulkAdd={() => {
                    setBulkAddType("facts");
                    setIsBulkAddModalOpen(true);
                  }}
                  searchTerm={searchTerm}
                />
                <div className="flex flex-col space-y-2 border border-gray-200 rounded-md px-4 py-3">
                  <div className="flex flex-row space-x-8 w-full">
                    <div className="w-1/2">
                      <PriorArtSearchInstructions
                        instructions={contextData.priorArtSearchInstructions}
                        onChange={(instructions) =>
                          setContextData({
                            ...contextData,
                            priorArtSearchInstructions: instructions,
                          })
                        }
                        isEditing={isEditing}
                      />
                    </div>
                    <div className="w-1/2">
                      <PriorArtFilterInstructions
                        instructions={contextData.priorArtSearchFilterInstructions}
                        onChange={(instructions) =>
                          setContextData({
                            ...contextData,
                            priorArtSearchFilterInstructions: instructions,
                          })
                        }
                        isEditing={isEditing}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ScrollArea>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="sm:max-w-[600px] max-h-[80vh]">
          <DialogHeader>
            <DialogTitle>
              {modalType === "url"
                ? "Generate context from URL"
                : modalType === "document"
                  ? "Generate context from file"
                  : "Generate context from input"}
            </DialogTitle>
          </DialogHeader>
          {modalType === "url" && (
            <div className="space-y-4">
              <Input
                type="text"
                placeholder="Enter URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          )}
          {modalType === "document" && (
            <div className="space-y-4">
              {files.length === 0 && (
                <DragAndDropFileArea
                  handleFiles={handleFileChange}
                  supportedFileTypes={[".pdf", ".txt"]}
                />
              )}
              {files.map((file, index) => (
                <UploadedFileDisplay
                  key={index}
                  file={file}
                  onRemove={() => handleRemoveFile(index)}
                  fullWidth={true}
                />
              ))}
            </div>
          )}
          {modalType === "input" && (
            <div className="space-y-4">
              <AutosizeTextarea
                value={inputContext}
                onChange={(e) => setInputContext(e.target.value)}
                maxHeight={500}
              />
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={
                modalType === "url"
                  ? handleUrlSubmit
                  : modalType === "document"
                    ? handleFileUpload
                    : handleInputSubmit
              }
              disabled={
                modalType === "url"
                  ? !isValidUrl(url)
                  : modalType === "document"
                    ? files.length === 0
                    : modalType === "input"
                      ? inputContext.length === 0
                      : false
              }
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={showReprocessDialog} onOpenChange={setShowReprocessDialog}>
        <DialogContent className="sm:max-w-[600px] max-h-[80vh]">
            <DialogHeader>
              <DialogTitle>Process references</DialogTitle>
              <DialogDescription>
                This action will regenerate feature and claim charts with respect to the
                new context. Any citations you have added or edited will be overwritten.
                </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowReprocessDialog(false)}>
                Cancel
              </Button>
              <Button onClick={handleReprocessReferences}>Confirm</Button>
            </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isBulkAddModalOpen} onOpenChange={setIsBulkAddModalOpen}>
        <DialogContent className="sm:max-w-[600px] max-h-[80vh]">
          <DialogHeader>
            <DialogTitle>Bulk add {bulkAddType === "facts" ? "key facts" : "terms and definitions"}</DialogTitle>
          </DialogHeader>
              <AutosizeTextarea
                value={bulkAddContent}
                onChange={(e) => setBulkAddContent(e.target.value)}
                placeholder={bulkAddType === "facts" ? "Enter one fact per line" : "Enter in the format 'Term: Definition', one per line"}
                className="min-h-[200px]"
              />
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsBulkAddModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleBulkAdd}>Add</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={showClearConfirmation} onOpenChange={setShowClearConfirmation}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to clear all context?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. 
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleClear}>Clear All</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Dialog open={isSearchModalOpen} onOpenChange={handleSearchResultsClose}>
        <DialogContent className="max-w-6xl">
          <DialogHeader>
            <DialogTitle>{sentSearchQuery}</DialogTitle>
          </DialogHeader>
          {showSpinner ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : searchResults && searchResults.length > 0 ? (
            <SearchResultsTable
              searchResults={searchResults}
              sentSearchQuery={sentSearchQuery}
              general={false}
              searchChatProjectId={currentProjectId}
            />
          ) : (
            <Paragraph>No search results found.</Paragraph>
          )}
        </DialogContent>
      </Dialog>
    </ProjectPage>
  );
};

export default ContextPage;