/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useNavigate, useLocation } from "react-router-dom";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
} from "@/components/ui/navigation-menu";

/**
 * @description Invalidity project header containing title, navigation tabs, and sharing
 */
function AdminHeader({ isSuperAdmin }: { isSuperAdmin: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();

  const adminPath = isSuperAdmin ? "superadmin" : "admin";

  const getTabValue = (pathname: string): string => {
    if (pathname.includes("/users")) {
      return "users";
    } else if (pathname.includes("/billing")) {
      return "billing";
      // } else if (pathname.includes("/usage")) {
      //   return "usage";
    } else if (pathname.includes("/organizations")) {
      return "organizations";
    } else {
      return "overview";
    }
  };

  const currentTabValue = getTabValue(location.pathname);

  // Handle nav item selection
  const handleTabChange = (event: React.SyntheticEvent, newValue: string): void => {
    let path = "";

    switch (newValue) {
      case "overview":
        navigate(`${path}/${adminPath}`);
        break;
      case "users":
        navigate(`${path}/${adminPath}/users`);
        break;
      case "billing":
        navigate(`${path}/${adminPath}/billing`);
        break;
      //   case "usage":
      //     navigate(`${path}/${adminPath}/usage`);
      //     break;
      case "organizations":
        navigate(`${path}/${adminPath}/organizations`);
        break;

      default:
        break;
    }
  };

  // Render tab label
  const renderNavItem = (text: string, value: string) => (
    <NavigationMenuItem
      className={`px-3 py-1 text-sm hover:cursor-pointer font-medium ${
        currentTabValue === value
          ? "bg-accent text-accent-foreground rounded-full"
          : "text-muted-foreground"
      }`}
      onClick={(event) => {
        handleTabChange(event, value);
      }}
    >
      {text}
    </NavigationMenuItem>
  );

  return (
    <div className="flex items-center justify-between px-4 py-2 border-b">
      <div className="flex items-center space-x-2">
        <NavigationMenu>
          <NavigationMenuList>
            {renderNavItem("Overview", "overview")}
            {!isSuperAdmin && renderNavItem("Users", "users")}
            {isSuperAdmin && renderNavItem("Organizations", "organizations")}
            {renderNavItem("Billing", "billing")}
            {/* {renderNavItem("Usage", "usage")} */}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </div>
  );
}

export default AdminHeader;
