/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { useAppStateStore, useProjectStore } from "@/store";
import { SidebarPage } from "@/components";
import { useDocumentTitle } from "@/hooks";
import ExploreHeader from "../components/ExploreHeader";
import { BaseChat } from "./components";

/**
 * @description Explore chat page
 */
const ExploreChatPage: React.FC = () => {
  useDocumentTitle("Explore - Chat&AI");
  // Global state from store
  const { searchChatProjectId } = useAppStateStore();

  // Local state setup
  const referenceIds = useProjectStore(
    (state: any) => state.currentProjectDetails.referenceIds
  );

  return (
    <SidebarPage>
      <ExploreHeader />
      <BaseChat
        generalBool={true}
        projectId={searchChatProjectId}
        disabledBool={referenceIds.length === 0}
        documents={referenceIds}
      />
    </SidebarPage>
  );
};

export default ExploreChatPage;
