/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { Button } from "@/components/ui/button";
import { H4, Paragraph } from "@/components/ui/typography";

interface ErrorMessageProps {
  showButton?: boolean;
  handleButtonClick?: () => void;
  buttonLabel?: string;
}

/**
 * @description Error message component on page
 */
const ErrorMessage: React.FC<ErrorMessageProps> = ({
  showButton,
  handleButtonClick,
  buttonLabel,
}) => {
  const shouldRenderButton = showButton && handleButtonClick && buttonLabel;

  return (
    <div className="flex flex-col items-center justify-center mt-8">
      <H4>Oops! An error occurred.</H4>
      <Paragraph className="mt-2 mb-0">
        We're looking into it. Please try again later.
      </Paragraph>
      <Paragraph className="m-0">
        If the problem persists, contact{" "}
        <a href="mailto:support@tryandai.com">support@tryandai.com</a>.
      </Paragraph>
      {shouldRenderButton && (
        <Button onClick={handleButtonClick} aria-label={buttonLabel}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default ErrorMessage;
