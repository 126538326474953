/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Loader } from "@/components";

interface ConfirmRemovePatentModalProps {
  open: boolean;
  onClose: () => void;
  removePatentFromPortfolio: (projectId: string) => Promise<void>;
  name: string;
  projectId: string;
}

/**
 * @description Remove organization modal
 */
function ConfirmRemovePatentModal({
  open,
  onClose,
  name,
  projectId,
  removePatentFromPortfolio,
}: ConfirmRemovePatentModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleRemovePatentFromPortfolio = async () => {
    setIsLoading(true);
    await removePatentFromPortfolio(projectId);
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        {isLoading ? (
          <Loader message="Removing patent from portfolio..." />
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Remove patent from portfolio</DialogTitle>
              <DialogDescription>
                Are you sure you want to remove the patent <strong>{name}</strong> from
                this portfolio? This action cannot be undone. All associated project
                data will be deleted.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button variant="outline" onClick={onClose} aria-label="Cancel deletion">
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={handleRemovePatentFromPortfolio}
                aria-label="Delete patent"
              >
                Remove
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmRemovePatentModal;
