/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useViz } from "@/hooks";
import { ProjectPage, Loader } from "@/components";
import { FeatureClaimList, Abstract } from "./components";
import { ElementType, ProjectType } from "@/types/types";
import SubjectPatentLayout from "@/components/patents/SubjectPatentLayout";
import { cn } from "@/lib/utils";

const SubjectPage: React.FC = () => {
  const { fetchProjectData } = useViz();
  const { currentProjectDetails, subjectDetails, currentProjectId } = useProjectStore();
  const { isLoading } = useAppStateStore();

  // Page name
  const pageName = currentProjectDetails.name
    ? `${currentProjectDetails.name} - Subject - &AI`
    : "Subject - &AI";

  const urlParams = new URLSearchParams(window.location.search);
  const projectIdUrl = urlParams.get("id");

  useEffect(() => {
    const effectiveProjectId = projectIdUrl || currentProjectId;
    if (effectiveProjectId && currentProjectId === "") {
      fetchProjectData(effectiveProjectId);
    }
  }, [projectIdUrl, currentProjectId, fetchProjectData]);

  const NonPatentSubjectContent = () => (
    <div className="flex h-[calc(100vh-55px)]">
      <div
        className={cn("w-[100%] overflow-y-auto overflow-x-hidden hide-scrollbar px-3")}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            {currentProjectDetails.type === ProjectType.APP && <Abstract />}
            <FeatureClaimList
              list={currentProjectDetails.features}
              itemType={ElementType.FEATURE}
              startCollapsed={false}
              editingBool={true}
            />
          </div>
          <div>
            <FeatureClaimList
              list={currentProjectDetails.claims}
              itemType={ElementType.CLAIM}
              startCollapsed={false}
              editingBool={true}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <ProjectPage pageName={pageName}>
      {isLoading ? (
        <div className="mt-10">
          <Loader />
        </div>
      ) : (
        <div>
          {subjectDetails.isPatent ||
          currentProjectDetails.type === ProjectType.PFP ||
          currentProjectDetails.type === ProjectType.PFC ? (
            <SubjectPatentLayout patent={subjectDetails} />
          ) : (
            <NonPatentSubjectContent />
          )}
        </div>
      )}
    </ProjectPage>
  );
};

export default SubjectPage;
