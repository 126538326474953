/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useRef, useEffect, useState, KeyboardEvent } from "react";
import { PaperPlaneIcon, SquareIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { cn } from "@/lib/utils";
import MultiDocumentSelect from "@/components/files/MultiDocumentSelect";

/**
 * @description Chat footer component.
 *
 * @param {function} setSelectedDocumentIds - Function to set the selected document ids.
 * @param {function} handleSendMessage - Function to send the message.
 * @param {boolean} general - Whether the chat is general.
 * @param {boolean} disabledBool - Whether the chat is disabled.
 * @param {string} id - The id of the chat.
 * @param {array} documents - The list of documents.
 * @param {string} sessionId - The id of the session.
 */
interface ChatFooterProps {
  setSelectedDocumentIds: (ids: string[]) => void;
  handleSendMessage: (
    message: string,
    id?: string,
    documents?: any[],
    sessionId?: string
  ) => void;
  general: boolean;
  disabledBool: boolean;
  id: string;
  documents: any[];
  sessionId: string;
  isAIResponding: boolean;
  handleStopStreaming: () => void;
  updateFooterHeight: (height: number) => void;
}

export default function ChatFooter({
  setSelectedDocumentIds,
  handleSendMessage,
  general,
  disabledBool,
  id,
  documents,
  sessionId,
  isAIResponding,
  handleStopStreaming,
  updateFooterHeight,
}: ChatFooterProps) {
  const [newMessage, setNewMessage] = useState<string>("");
  const [messageHasBeenSent, setMessageHasBeenSent] = useState<boolean>(false);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (footerRef.current) {
        updateFooterHeight(footerRef.current.offsetHeight);
      }
    };

    // Call updateHeight initially and after a short delay to account for any animations
    updateHeight();
    const timeoutId = setTimeout(updateHeight, 100);

    // Set up event listeners
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      clearTimeout(timeoutId);
    };
  }, [updateFooterHeight, messageHasBeenSent, newMessage]);

  const handleChange = (selectedIds: string[]) => {
    setSelectedDocumentIds(selectedIds);
  };

  const handleSend = () => {
    if (!messageHasBeenSent) {
      setMessageHasBeenSent(true);
    }
    if (newMessage.trim() !== "") {
      handleSendMessage(newMessage);
      setNewMessage("");
    }
  };

  const defaultMessages = [
    "List the inventive aspects",
    "Generate a summary",
    "What is Chat&AI?",
  ];

  const handleSendDefaultMessage = (message: string) => {
    if (!messageHasBeenSent) {
      setMessageHasBeenSent(true);
    }
    handleSendMessage(message, id, documents, sessionId);
  };

  return (
    <div
      ref={footerRef}
      className="fixed bottom-0 left-24 right-0 px-4 mb-3 sm:px-8 lg:px-[100px] z-[40]"
    >
      {!messageHasBeenSent && (
        <div className="flex flex-wrap justify-center items-center mb-2 gap-2">
          {defaultMessages.map((message, index) => (
            <Button
              key={index}
              variant="outline"
              onClick={() => handleSendDefaultMessage(message)}
              disabled={disabledBool}
            >
              {message}
            </Button>
          ))}
        </div>
      )}
      <div className="flex flex-col md:flex-row justify-between items-stretch gap-2">
        <div className="w-full md:w-auto md:flex-grow md:max-w-[50%] mb-1 md:mb-0">
          <MultiDocumentSelect
            handleChange={handleChange}
            general={general}
            disabled={disabledBool}
          />
        </div>
        <div className="flex flex-row items-center gap-2 w-full md:flex-grow">
          <AutosizeTextarea
            className={cn("flex-grow", disabledBool && "opacity-50")}
            placeholder="Message &AI..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            maxHeight={100}
            disabled={disabledBool}
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  className="w-11"
                  variant="outline"
                  disabled={disabledBool}
                  onClick={isAIResponding ? handleStopStreaming : handleSend}
                  aria-label={isAIResponding ? "Stop generation" : "Send message"}
                >
                  {isAIResponding ? (
                    <SquareIcon className="h-4 w-4" />
                  ) : (
                    <PaperPlaneIcon className="h-4 w-4" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {isAIResponding ? "Stop generation" : "Send message"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-3 text-sm text-muted-foreground">
        <p>
          Chat&AI interacts with public language models. Do not share confidential
          information.
        </p>
        <p>
          Language models may not be accurate or up-to-date. Consider reviewing
          generated content.
        </p>
      </div>
    </div>
  );
}
