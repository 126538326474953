/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { ReactNode } from "react";
import { SidebarPage } from "@/components";
import AdminHeader from "./AdminHeader";
import { ScrollArea } from "@/components/ui/scroll-area";

/**
 * @description The project page component
 * @param {ReactNode} children - The children of the component
 */
interface AdminPageLayoutProps {
  children: ReactNode;
  isSuperAdmin: boolean;
}

const AdminPageLayout: React.FC<AdminPageLayoutProps> = ({
  children,
  isSuperAdmin,
}) => {
  return (
    <SidebarPage pageName={isSuperAdmin ? "Super Admin - &AI" : "Admin - &AI"}>
      <AdminHeader isSuperAdmin={isSuperAdmin} />
      <ScrollArea className="px-3 h-[calc(100vh-55px)]">{children}</ScrollArea>
    </SidebarPage>
  );
};

export default AdminPageLayout;
