/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import ImageViewer from "@/components/images/ImageViewer";

interface ReferenceImageProps {
  imageUrl: string;
  imageName: string;
}

function ReferenceImage({ imageUrl, imageName }: ReferenceImageProps) {
  const [imageOpen, setImageOpen] = useState<boolean>(false);

  return (
    <>
      <div className="h-[100px] cursor-pointer m-2">
        <img
          src={imageUrl}
          alt={`Figure: ${imageName}`}
          className="rounded-md object-contain h-full"
          onClick={() => setImageOpen(true)}
        />
      </div>
      <ImageViewer
        imageUrl={imageUrl}
        imageName={imageName}
        open={imageOpen}
        handleClose={() => setImageOpen(false)}
      />
    </>
  );
}

export default ReferenceImage;
