/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

/**
 * @description Authorize charges modal
 *
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} handleContinue - Function to continue the modal.
 */
interface DeleteProjectModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  name: string;
}

const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({
  open,
  handleClose,
  handleDelete,
  name,
}) => {
  const [confirmation, setConfirmation] = useState("");

  const handleDeleteClick = () => {
    handleDelete();
    setConfirmation("");
    handleClose();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete project</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete the project <strong>{name}</strong>? This
            action cannot be undone. All users on this project will immediately lose
            access. All project data will be deleted and cannot be recovered.
          </DialogDescription>
        </DialogHeader>
        <Input
          value={confirmation}
          onChange={(e) => setConfirmation(e.target.value)}
          placeholder={`Enter '${name}' to confirm`}
        />
        <DialogFooter>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={handleDeleteClick}
            disabled={confirmation !== name}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteProjectModal;
