/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect } from "react";
import { useAppStateStore } from "@/store";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle, Info, CheckCircle, AlertTriangle } from "lucide-react";
import { Spinner } from "../ui/spinner";

/**
 * @description Error messages component
 * Updated to stack instead of queue
 */
const AlertMessages: React.FC = () => {
  // State for the error messages
  const {
    statusMessages,
    removeStatusMessage,
    loadingMessages,
    removeLoadingMessage,
  } = useAppStateStore();

  useEffect(() => {
    // Set up timers for each message
    const timers = statusMessages.map((message) =>
      setTimeout(() => removeStatusMessage(message.key), 3000)
    );

    // Clean up timers on component unmount or when messages change
    return () => timers.forEach(clearTimeout);
  }, [statusMessages, removeStatusMessage]);

  const getIcon = (severity: string) => {
    switch (severity) {
      case "error":
        return <AlertCircle className="h-4 w-4" />;
      case "info":
        return <Info className="h-4 w-4" />;
      case "success":
        return <CheckCircle className="h-4 w-4" />;
      case "warning":
        return <AlertTriangle className="h-4 w-4" />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed bottom-4 right-4 space-y-2 z-50">
      {loadingMessages.map((message) => (
        <Alert
          key={message.key}
          variant="default"
          className="flex items-center pr-8 relative"
        >
          <Spinner className="mr-2 flex-shrink-0 h-4 w-4" />
          <div>
            <AlertTitle>{message.message}</AlertTitle>
          </div>
        </Alert>
      ))}
      {statusMessages.map((message) => (
        <Alert
          key={message.key}
          variant={message.severity === "error" ? "destructive" : "default"}
          className="relative pr-8"
        >
          {getIcon(message.severity)}
          <AlertTitle>
            {message.severity.charAt(0).toUpperCase() +
              message.severity.slice(1)}
          </AlertTitle>
          <AlertDescription>{message.message}</AlertDescription>
        </Alert>
      ))}
    </div>
  );
};

export default AlertMessages;
