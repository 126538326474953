/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { SidebarPage, ErrorMessage, Loader } from "@/components";
import { useCreateProjectStore } from "@/store";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { H4 } from "@/components/ui/typography";

interface CreateProjectPageLayoutProps {
  disabled: boolean;
  handleCreate: () => void;
  isPortfolio: boolean;
  title: string;
  resetState: () => void;
  isConfirmed: boolean;
  children: React.ReactNode;
}
/**
 * @description Create invalidity project flow
 */
const CreateProjectPageLayout: React.FC<CreateProjectPageLayoutProps> = ({
  disabled,
  handleCreate,
  isPortfolio,
  title,
  resetState,
  isConfirmed,
  children,
}) => {
  const { isProjectCreationInProgress, projectError, spinnerText } =
    useCreateProjectStore();
  const pageName = `${title} - &AI`;

  return (
    <SidebarPage pageName={pageName}>
      <div className="w-full ">
        {isProjectCreationInProgress ? (
          <div className="flex justify-center items-center h-full">
            <Loader
              message={spinnerText}
              aria-label="Loading"
            />
          </div>
        ) : (
          <div>
            {projectError ? (
              <ErrorMessage
                showButton={true}
                buttonLabel="Try Again"
                handleButtonClick={resetState}
              />
            ) : (
              <ScrollArea className="h-[calc(100vh-2s0px)] px-6">
                {!isConfirmed && (
                  <div className="pb-6">
                    <div className="sticky top-0 bg-background z-10 border-b py-3">
                      <div className="flex justify-between items-center">
                        <H4>{title}</H4>
                        <Button onClick={handleCreate} disabled={disabled}>
                          {isPortfolio ? "Create Portfolio" : "Create Project"}
                        </Button>
                      </div>
                    </div>
                    {children}
                  </div>
                )}
              </ScrollArea>
            )}
          </div>
        )}
      </div>
    </SidebarPage>
  );
};

export default CreateProjectPageLayout;
