/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { parseCommaSeparatedString, isLikelyPatentNumber } from "@/utils/dataUtils";
import { useCreateProject } from "@/hooks";
import { DocumentsOnCreate } from ".";
import { Loader } from "@/components";
import { useAppStateStore, useCreateProjectStore } from "@/store";

interface PatentNumbersInputCreateProps {
  isInvalidity?: boolean;
  isReference?: boolean;
  setFindClicked: (isFindClicked: boolean) => void;
}

const PatentNumbersInputCreate: React.FC<PatentNumbersInputCreateProps> = ({
  isReference,
  isInvalidity,
  setFindClicked,
}) => {
  const { getSubjectDetailsOnCreate } = useCreateProject();
  const { addErrorMessage } = useAppStateStore();
  const {
    referenceDetails,
    updateReferenceDetails,
    updateReferenceNumbers,
    subjectDetails,
    updateSubjectDetails,
    updateSubjectNumbers,
    updateFeatures,
    updateClaims,
    updateContext,
  } = useCreateProjectStore();

  const [inputText, setInputText] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hanldeFindInputNumbers = async () => {
    const patentNumbers = parseCommaSeparatedString(inputText);

    // Make sure the portfolio doesn't exceed the maximum number of patents
    if (patentNumbers.length > 100) {
      setInputError(
        "The maximum number of patents or applications that can be in a portfolio is 100."
      );
      return;
    }

    // Check if the patent numbers are valid
    const validPatentNumbers = [];
    const invalidPatentNumbers = [];
    for (const patentNumber of patentNumbers) {
      if (!isLikelyPatentNumber(patentNumber)) {
        invalidPatentNumbers.push(patentNumber);
      } else {
        validPatentNumbers.push(patentNumber);
      }
    }
    if (invalidPatentNumbers.length > 0) {
      setInputError(
        `The following patent numbers are not valid: ${invalidPatentNumbers.join(", ")}`
      );
      return;
    }
    // If there are no valid patent numbers, return
    if (validPatentNumbers.length === 0) {
      setInputError("No valid patent or application numbers input.");
      return;
    }

    if (validPatentNumbers.length > 0) {
      setIsLoading(true);
      setFindClicked(true);
      const response = await getSubjectDetailsOnCreate(
        validPatentNumbers,
        isInvalidity
      );
      if (response.success) {
        const successfulItems = response.data.filter((item) => item.status === "200");
        const failedItems = response.data.filter((item) => item.status === "404");

        if (isInvalidity) {
          updateFeatures(successfulItems[0].features);
          updateClaims(successfulItems[0].claims);
        }
        if (isReference) {
          updateReferenceDetails(successfulItems);
          updateReferenceNumbers(successfulItems.map((item) => item.number));
        } else {
          updateSubjectDetails(successfulItems);
          updateSubjectNumbers(successfulItems.map((item) => item.number));
        }

        if (failedItems.length > 0) {
          const failedNumbers = failedItems.map((item) => item.number);
          addErrorMessage(
            `The following patent numbers were not found: ${failedNumbers.join(", ")}`
          );
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader message="Finding patent(s) and extracting details..." />
      ) : (
        <>
          {((!isReference && subjectDetails.length === 0) ||
            (isReference && referenceDetails.length === 0)) && (
            <>
              {isInvalidity && !isReference ? (
                <div className="space-y-2">
                  <Label htmlFor="patent-number">
                    Published Patent or Application Number
                  </Label>
                  <Input
                    id="patent-number"
                    placeholder="e.g., US10952930B2"
                    value={inputText || ""}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  {inputError && (
                    <Alert variant="destructive">
                      <AlertDescription>{inputError}</AlertDescription>
                    </Alert>
                  )}
                </div>
              ) : (
                <div className="space-y-2">
                  <Label htmlFor="patent-numbers">
                    Published patent or application numbers
                  </Label>
                  <AutosizeTextarea
                    id="patent-numbers"
                    placeholder="Enter a comma-separated list. Example: US10952930, CN10952931B2, US20220183193A1, 10782732"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  {inputError && (
                    <Alert variant="destructive">
                      <AlertDescription>{inputError}</AlertDescription>
                    </Alert>
                  )}
                  <p className="text-sm text-muted-foreground mt-2">
                    * If no country prefix is provided, it is assumed to be a US patent.
                  </p>
                </div>
              )}
              <Button
                onClick={hanldeFindInputNumbers}
                className="mt-4 w-full"
                disabled={inputText === ""}
              >
                {isReference ? "Find patents" : "Find subject patent"}
              </Button>
            </>
          )}
          <div>
            {isReference
              ? referenceDetails.length > 0 && (
                  <DocumentsOnCreate details={referenceDetails} />
                )
              : subjectDetails.length > 0 && (
                  <DocumentsOnCreate details={subjectDetails} />
                )}
          </div>
        </>
      )}
    </div>
  );
};

export default PatentNumbersInputCreate;
