/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { ReactNode } from "react";
import { PageContainer, MainContentBox } from "@/styled";
import Sidebar from "./Sidebar";
import AlertMessages from "../alerts/AlertMessages";
import { useDocumentTitle } from "@/hooks";

/**
 * @description The sidebar page component
 * @param {ReactNode} children - The children of the component
 */
interface SidebarPageProps {
  children: ReactNode;
  pageName?: string;
}

const SidebarPage: React.FC<SidebarPageProps> = ({ children, pageName }) => {
  useDocumentTitle(pageName);
  return (
    <PageContainer>
      <Sidebar />
      <MainContentBox>{children}</MainContentBox>
      <AlertMessages />
    </PageContainer>
  );
};

export default SidebarPage;
