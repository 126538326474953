/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import {
  CreateStepGroup,
  ContextEdit,
  ClaimFeatureEditStep,
  PatentNumbersInputCreate,
} from ".";
import { useCreateProjectStore } from "@/store";
import { ElementType } from "@/types/types";
import { RadioGroup, RadioGroupWithText } from "@/components/ui/radio-group";

/**
 * @description Claim feature source on project creation
 */
const InvaliditySubjectStep: React.FC<{
  isCompleted: boolean;
  startExpanded?: boolean;
}> = ({ startExpanded }) => {
  const {
    updateSubjectDetails,
    subjectDetails,
    updateSubjectMode,
    subjectMode,
    claims,
    features,
    updateClaims,
    updateFeatures,
    updateSubjectNumbers,
    subjectNumbers,
    context,
    updateContext,
  } = useCreateProjectStore();
  const [findClicked, setFindClicked] = useState(false);

  useEffect(() => {
    setFindClicked(false);
  }, []);

  const sourceContent =
    "Published patent/application and claim language entry will yield both feature and claim charts upon project creation. Feature language entry will only yield feature charts upon project creation. You can add claims later to populate claim charts.";

  const handleReset = () => {
    updateSubjectDetails([]);
    updateSubjectNumbers([]);
    updateSubjectMode("patent");
    setFindClicked(false);
  };

  return (
    <CreateStepGroup
      stepName="Subject"
      isCompleted={
        (subjectMode === "patent" && subjectNumbers.length > 0) ||
        (subjectMode === "claim" &&
          claims.length > 0 &&
          JSON.stringify(claims[0]) !== JSON.stringify({ "1": "" })) ||
        (subjectMode === "feature" &&
          features.length > 0 &&
          JSON.stringify(features[0]) !== JSON.stringify({ "1": "" }))
      }
      showSearchAgain={subjectDetails.length > 0}
      handleSearchAgain={handleReset}
      startExpanded={startExpanded}
      infoPopoverContent={sourceContent}
    >
      {subjectDetails.length === 0 && !findClicked && (
        <RadioGroup
          defaultValue={subjectMode}
          onValueChange={(value) =>
            updateSubjectMode(value as "patent" | "claim" | "feature")
          }
          className="flex flex-wrap gap-4"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupWithText
              value="patent"
              id="patent"
              label="Extract from patent"
            />
            {/* <Label htmlFor="patent" className="whitespace-nowrap">
              Extract from patent
            </Label> */}
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupWithText value="claim" id="claim" label="Enter claim language" />
            {/* <Label htmlFor="claim" className="whitespace-nowrap">
              Enter claim language
            </Label> */}
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupWithText
              value="feature"
              id="feature"
              label="Enter feature language"
            />
            {/* <Label htmlFor="feature" className="whitespace-nowrap">
              Enter feature language
            </Label> */}
          </div>
        </RadioGroup>
      )}
      <div className="mt-4">
        {subjectMode === "patent" && (
          <PatentNumbersInputCreate
            isReference={false}
            isInvalidity={true}
            setFindClicked={setFindClicked}
          />
        )}

        {(subjectMode === "feature" ||
          (subjectMode === "patent" &&
            subjectDetails.length > 0 &&
            subjectDetails[0].features?.length > 0)) && (
          <ClaimFeatureEditStep
            itemType={ElementType.FEATURE}
            setItems={updateFeatures}
            startInEditMode={subjectMode !== "patent"}
            initialItems={
              subjectMode === "feature" ? features : subjectDetails[0].features
            }
            isUpdateMode={false}
            showCollapse={subjectMode === "patent"}
          />
        )}

        {(subjectMode === "claim" ||
          (subjectMode === "patent" &&
            subjectDetails.length > 0 &&
            subjectDetails[0].claims?.length > 0)) && (
          <ClaimFeatureEditStep
            itemType={ElementType.CLAIM}
            setItems={updateClaims}
            startInEditMode={subjectMode !== "patent"}
            initialItems={subjectMode === "claim" ? claims : subjectDetails[0].claims}
            isUpdateMode={false}
            showCollapse={subjectMode === "patent"}
          />
        )}
      </div>
    </CreateStepGroup>
  );
};

export default InvaliditySubjectStep;
