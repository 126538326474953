/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore } from "../store";
import useApi from "./useApi";
import { ApiResponse, ProjectStatus } from "../types/types";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProject = () => {
  const { postRequest, getRequest, handleError } = useApi();
  const { updateProjectsList, updateArchivedProjectsList } = useProjectStore();

  /**
   * @description Fetches all projects for a user
   */
  const getUserProjects = async (): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_project_list");
      const archivedProjectsList = response.data.filter(
        (project: any) => project.status === ProjectStatus.ARCHIVED
      );
      const activeProjectsList = response.data.filter(
        (project: any) => project.status !== ProjectStatus.ARCHIVED
      );
      updateProjectsList(activeProjectsList);
      updateArchivedProjectsList(archivedProjectsList);
      return { success: true, status: response.status };
    } catch (error) {
      return handleError(error, "Error fetching user projects");
    }
  };

  /**
   * @description Adds a user to a project
   * @param {string} projectId - The id of the project to add the user to
   * @param {string} userEmail - The email of the user to add to the project
   */
  const addUserToProject = async (
    projectId: string,
    userId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_add_user_to_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Error adding users to the project`);
    }
  };

  /**
   * @description Removes a user from a project
   * @param {string} projectId - The id of the project to remove the user from
   * @param {string} userEmail - The email of the user to remove from the project
   */
  const removeUserFromProject = async (
    projectId: string,
    userId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_user_from_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Failed to remove users from the project`);
    }
  };

  /**
   * @description Fetches all users for a project
   * @param {string} projectId - The id of the project to fetch users for
   */
  const getUsersForProject = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_users_for_project", {
        project_id: projectId,
      });
      return {
        success: true,
        data: {
          users_on_project: response.data.users_on_project,
          shareable_users: response.data.shareable_users,
        },
      };
    } catch (error) {
      return handleError(error, "Error fetching users for project");
    }
  };

  /**
   * @description Checks if a user has access to a project
   * @param {string} projectId - The id of the project to check access for
   */
  const getUserProjectAccessCheck = async (
    projectId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_user_project_access_check", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching user project access check");
    }
  };

  /**
   * @description Fetches the owner of a project
   * @param {string} projectId - The id of the project to fetch the owner for
   */
  const getProjectOwnerData = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_project_owner_data", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching project owner data");
    }
  };

  /**
   * @description Requests access to a project
   * @param {string} projectId - The id of the project to request access to
   * @param {string} userEmail - The email of the user to request access to
   * @param {string} projectNickname - The nickname of the project
   */
  const requestProjectAccess = async (
    projectId: string,
    userEmail: string,
    projectNickname: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_request_project_access_email", {
        project_nickname: projectNickname,
        owner_email: userEmail,
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error requesting access to project");
    }
  };

  /**
   * @description Sends an email to grant access to a project
   * @param {string} email - The email of the user to grant access to
   * @param {string} projectName - The name of the project
   * @param {string} projectId - The id of the project
   */
  const sendProjectAccessGrantedEmail = async (
    email: string,
    projectName: string,
    projectId: string
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_granted_project_access_email", {
        granted_email: email,
        project_nickname: projectName,
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting granted project access email");
    }
  };

  return {
    getUserProjects,
    addUserToProject,
    removeUserFromProject,
    getUsersForProject,
    getUserProjectAccessCheck,
    getProjectOwnerData,
    requestProjectAccess,
    sendProjectAccessGrantedEmail,
  };
};

export default useProject;
