/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useCallback, useEffect } from "react";
import { useViz, useDocumentTitle } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { SidebarPage } from "@/components";
import {
  AddReferenceByNumberModal,
  DocumentsTable,
  UploadFileModal,
} from "@/features/project/documents/components";
import ExploreHeader from "./components/ExploreHeader";
import { Patent } from "@/types/types";
import { Button } from "@/components/ui/button";
import { H4 } from "@/components/ui/typography";

/**
 * @description Explore documents page with search and chat tabs
 */
const ExplorePage: React.FC = () => {
  const { fetchProjectData, deleteReferenceFromProject } = useViz();

  // Global state from store
  const { currentProjectDetails, currentProjectId, updateCurrentProjectDetails } =
    useProjectStore();
  const { addErrorMessage, searchChatProjectId } = useAppStateStore();
  const referenceDetails = useProjectStore(
    (state) => state.currentProjectDetails.referenceDetails
  );

  // Set page title
  useDocumentTitle("Explore - &AI");

  // Local state
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [isReferenceLoading, setIsReferenceLoading] = useState<string[]>([]);

  // State for reference search
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddPublishedPatents = () => {
    // Logic to add published patents or applications
    setShowAddModal(true);
    handleCloseMenu();
  };

  const handleAddOtherDocuments = () => {
    setShowUploadModal(true);
    handleCloseMenu();
  };

  useEffect(() => {
    setIsReferenceLoading([]);
  }, [currentProjectId]);

  //  Removes a reference from the explore project
  const handleRemoveReferenceFromProject = useCallback(
    async (reference: Patent) => {
      const refId = reference.id || reference.referenceId;
      if (refId && searchChatProjectId) {
        const response = await deleteReferenceFromProject(searchChatProjectId, refId);
        if (!response.success) {
          addErrorMessage("Error deleting reference");
        }
      }
    },
    [currentProjectId, referenceDetails, isReferenceLoading]
  );

  // Fetch project data on mount
  useEffect(() => {
    fetchProjectData(searchChatProjectId);
  }, [searchChatProjectId]);

  // Reset the explore state and clear references
  const handleReset = async () => {
    const refIds = currentProjectDetails.referenceIds;

    for (const refId of refIds) {
      const response = await deleteReferenceFromProject(searchChatProjectId, refId);
      if (!response.success) {
        addErrorMessage("Error deleting references from the project");
        return;
      }
    }
    updateCurrentProjectDetails({
      id: searchChatProjectId,
      name: "",
 
      subjectId: "",
      referenceIds: [],
      referenceDetails: [],
      documentIds: [],
      keywords: [],
    });
  };

  return (
    <SidebarPage>
      <ExploreHeader />
      <div className="p-2">
        {referenceDetails?.length > 0 || isReferenceLoading?.length > 0 ? (
          <DocumentsTable
            referenceDetails={referenceDetails}
            handleDelete={handleRemoveReferenceFromProject}
            searchResultsBoolean={false}
            showHead={true}
            showNotes={false}
            isExplorePage={true}
            onClearAll={handleReset}
            onAddSources={handleAddPublishedPatents}
            onUploadOtherDocuments={handleAddOtherDocuments}
          />
        ) : (
          <div className="flex justify-center h-[calc(100vh-200px)] mt-10">
            <div className="text-center">
              <H4>Add sources to explore.</H4>
              <div className="flex gap-2 justify-center mt-3">
                <Button variant="outline" onClick={() => setShowAddModal(true)}>
                  Add by Number
                </Button>
                <Button variant="outline" onClick={() => setShowUploadModal(true)}>
                  Upload Files
                </Button>
              </div>
            </div>
          </div>
        )}

        <AddReferenceByNumberModal
          open={showAddModal}
          handleClose={() => setShowAddModal(false)}
          general={true}
        />
        <UploadFileModal
          open={showUploadModal}
          handleClose={() => setShowUploadModal(false)}
        />
      </div>
    </SidebarPage>
  );
};

export default ExplorePage;
