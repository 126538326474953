/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useEffect, useState, useCallback, ChangeEvent } from "react";
import { Organization } from "@/types/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FloatingLabelInput } from "@/components/ui/floating-input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { Loader } from "@/components";

interface EditOrganizationModalProps {
  open: boolean;
  onClose: () => void;
  org: Organization;
  updateOrg: (origOrg: Organization, newOrg: Organization) => Promise<void>;
}

function EditOrganizationModal({
  open,
  onClose,
  org,
  updateOrg,
}: EditOrganizationModalProps) {
  const [newOrg, setNewOrg] = useState<Organization>(org);
  const [origOrg, setOrigOrg] = useState<Organization>(org);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && org) {
      setOrigOrg(org);
      setNewOrg(org);
    }
  }, [open, org]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const intFields = [
      "organizationName",
      "monthlyLicensePrice",
      "yearlyLicensePrice",
      "monthlySeatPrice",
      "yearlySeatPrice",
      "monthlyProjectPrice",
      "invProjectPrice",
      "ftoProjectPrice",
      "sepProjectPrice",
      "roaProjectPrice",
      "appProjectPrice",
      "invIncludedProjects",
      "ftoIncludedProjects",
      "sepIncludedProjects",
      "roaIncludedProjects",
      "appIncludedProjects",
      "maxSeats",
    ];

    const parsedValue = intFields.includes(name) ? parseInt(value, 10) : value;

    if (name.startsWith("address.")) {
      const addressField = name.split(".")[1];
      setNewOrg((prev) => ({
        ...prev,
       
      }));
    } else {
      setNewOrg((prev) => ({ ...prev, [name]: parsedValue }));
    }
  }, []);

  const handleSubmit = async () => {
    if (origOrg && newOrg) {
      setIsLoading(true);
      await updateOrg(origOrg, newOrg);
      setIsLoading(false);
    }
    onClose();
  };

  if (!newOrg) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Edit Organization</DialogTitle>
          </DialogHeader>

          {isLoading ? (
            <Loader />
          ) : (
            <ScrollArea className="max-h-[calc(100vh-200px)] px-2">
              <FloatingLabelInput
                id="organization_name"
                label="Organization Name"
                name="organizationName"
                value={newOrg.organizationName || ""}
                onChange={handleChange}
                className="mb-4"
              />

              <Select
                name="invoiceType"
                value={newOrg.invoiceType || "NONE"}
                onValueChange={(value) =>
                  handleChange({ target: { name: "invoice_type", value } } as any)
                }
              >
                <SelectTrigger className="mb-3">
                  <SelectValue placeholder="Select Invoice Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="NONE">None</SelectItem>
                  <SelectItem value="MONTHLY">Monthly</SelectItem>
                  <SelectItem value="YEARLY">Yearly</SelectItem>
                </SelectContent>
              </Select>

              {newOrg.invoiceType === "MONTHLY" && (
                <div className="flex gap-4 mb-3">
                  <FloatingLabelInput
                    id="monthly_license_price"
                    label="Monthly License Price"
                    name="monthlyLicensePrice"
                    value={newOrg.monthlyLicensePrice?.toString() || ""}
                    onChange={handleChange}
                  />
                  <FloatingLabelInput
                    id="monthly_seat_price"
                    label="Monthly Seat Price"
                    name="monthlySeatPrice"
                    value={newOrg.monthlySeatPrice?.toString() || ""}
                    onChange={handleChange}
                  />
                </div>
              )}

              {newOrg.invoiceType === "YEARLY" && (
                <div className="flex gap-4 mb-3">
                  <FloatingLabelInput
                    id="yearly_license_price"
                    label="Yearly License Price"
                    name="yearlyLicensePrice"
                    value={newOrg.yearlyLicensePrice?.toString() || ""}
                    onChange={handleChange}
                  />
                  <FloatingLabelInput
                    id="yearly_seat_price"
                    label="Yearly Seat Price"
                    name="yearlySeatPrice"
                    value={newOrg.yearlySeatPrice?.toString() || ""}
                    onChange={handleChange}
                  />
                </div>
              )}

              <FloatingLabelInput
                id="document_price"
                label="Document Price"
                name="documentPrice"
                value={newOrg.documentPrice?.toString() || ""}
                onChange={handleChange}
                className="mb-3"
              />

              <FloatingLabelInput
                id="documents_added_price"
                label="Documents Added Price"
                name="documentsAddedPrice"
                value={newOrg.documentsAddedPrice?.toString() || ""}
                onChange={handleChange}
                className="mb-3"
              />

              <FloatingLabelInput
                id="documents_exported_price"
                label="Documents Exported Price"
                name="documentsExportedPrice"
                value={newOrg.documentsExportedPrice?.toString() || ""}
                onChange={handleChange}
                className="mb-3"
              />

              <CheckboxWithText
                id="unique_documents_pricing"
                name="unique_documents_pricing"
                checked={newOrg.uniqueDocumentsPricing || false}
                onCheckedChange={(checked: boolean) =>
                  setNewOrg((prev) => ({ ...prev, uniqueDocumentsPricing: checked }))
                }
                label="Unique Documents Pricing"
                className=""
              />

              <div className="flex gap-4 mb-3 mt-3">
                <FloatingLabelInput
                  id="inv_project_price"
                  label="INV Project Price"
                  name="invProjectPrice"
                  value={newOrg.invProjectPrice?.toString() || ""}
                  onChange={handleChange}
                />
                <FloatingLabelInput
                  id="pfa_project_price"
                  label="PFA Project Price"
                  name="pfaProjectPrice"
                  value={newOrg.pfaProjectPrice?.toString() || ""}
                  onChange={handleChange}
                />
                <FloatingLabelInput
                  id="app_project_price"
                  label="APP Project Price"
                  name="appProjectPrice"
                  value={newOrg.appProjectPrice?.toString() || ""}
                  onChange={handleChange}
                />
              </div>
            </ScrollArea>
          )}

          <DialogFooter>
            <Button variant="outline" disabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading} onClick={handleSubmit}>
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </form>
    </Dialog>
  );
}

export default EditOrganizationModal;
