/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useCallback, ChangeEvent } from "react";
import { Dayjs } from "dayjs";
import { Organization } from "@/types/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FloatingLabelInput } from "@/components/ui/floating-input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CheckboxWithText } from "@/components/ui/checkbox";

interface AddOrganizationModalProps {
  open: boolean;
  onClose: () => void;
  onAddOrg: (newOrg: Organization) => Promise<void>;
}

interface Errors {
  organization_name?: string;
  email?: string;
  address_line1?: string;
  address_city?: string;
  address_state?: string;
  address_zip?: string;
  address_country?: string;
}

const initialState: Organization = {
  organizationName: "",
  isTesting: false,
  passthroughBilling: false,
  clientNumbers: [],
  invoiceType: "NONE",
  contractStart: null,
  contractEnd: null,
  monthlyLicensePrice: null,
  yearlyLicensePrice: null,
  monthlySeatPrice: null,
  monthlyProjectPrice: null,
  yearlySeatPrice: null,
  invProjectPrice: null,
  appProjectPrice: null,
  pfaProjectPrice: null,
  documentPrice: null,
  documentsAddedPrice: null,
  documentsExportedPrice: null,
  uniqueDocumentsPricing: false,
};

function AddOrganizationModal({ open, onClose, onAddOrg }: AddOrganizationModalProps) {
  const [newOrg, setNewOrg] = useState<Organization>(initialState);
  const [errors, setErrors] = useState<Errors>({});

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const intFields = [
      "monthlyLicensePrice",
      "yearlyLicensePrice",
      "monthlySeatPrice",
      "monthlyProjectPrice",
      "yearlySeatPrice",
      "invProjectPrice",
      "pfaProjectPrice",
      "appProjectPrice",
      "documentPrice",
      "documentsAddedPrice",
      "documentsExportedPrice",
      "uniqueDocumentsPricing",
    ];

    const parsedValue = intFields.includes(name) ? parseInt(value, 10) : value;

   
      setNewOrg((prev) => ({ ...prev, [name]: parsedValue }));
    
  }, []);

  const handleDateChange = useCallback(
    (date: Dayjs | null, dateType: keyof Organization) => {
      const formattedDate = date ? date.format("YYYY-MM-DD") : null;
      setNewOrg((prev) => ({ ...prev, [dateType]: formattedDate }));
    },
    []
  );

  const validateForm = () => {
    const newErrors: Errors = {};
    // if (!newOrg.organizationName)
    //   newErrors.organizationName = "Organization name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    await Promise.resolve(onAddOrg(newOrg));
    setNewOrg(initialState);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add Organization</DialogTitle>
          </DialogHeader>

          <ScrollArea className="max-h-[calc(100vh-200px)] mx-2">
            <FloatingLabelInput
              id="organizationName"
              label="Organization Name"
              name="organizationName"
              value={newOrg.organizationName}
              onChange={handleChange}
              // error={!!errors.organization_name}
              className="mt-2 mb-2"
            />

            <Select
              name="invoiceType"
              value={newOrg.invoiceType}
              onValueChange={(value) =>
                handleChange({ target: { name: "invoiceType", value } } as any)
              }
            >
              <SelectTrigger className="mb-2">
                <SelectValue placeholder="Select Invoice Type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="NONE">None</SelectItem>
                <SelectItem value="MONTHLY">Monthly</SelectItem>
                <SelectItem value="YEARLY">Yearly</SelectItem>
              </SelectContent>
            </Select>

            {newOrg.invoiceType === "MONTHLY" && (
              <div className="flex gap-2 mb-2 w-full">
                <FloatingLabelInput
                  id="monthlyLicensePrice"
                  label="Monthly License Price"
                  name="monthlyLicensePrice"
                  className="w-full"
                  value={newOrg.monthlyLicensePrice?.toString() || ""}
                  onChange={handleChange}
                />
                <FloatingLabelInput
                  id="monthlySeatPrice"
                  label="Monthly Seat Price"
                  name="monthlySeatPrice"
                  className="w-full"
                  value={newOrg.monthlySeatPrice?.toString() || ""}
                  onChange={handleChange}
                />
              </div>
            )}

            {newOrg.invoiceType === "YEARLY" && (
              <div className="flex gap-2 mb-2 w-full">
                <FloatingLabelInput
                  id="yearlyLicensePrice"
                  label="Yearly License Price"
                  name="yearlyLicensePrice"
                  value={newOrg.yearlyLicensePrice?.toString() || ""}
                  onChange={handleChange}
                  className="w-full"
                />
                <FloatingLabelInput
                  id="yearlySeatPrice"
                  label="Yearly Seat Price"
                  name="yearlySeatPrice"
                  value={newOrg.yearlySeatPrice?.toString() || ""}
                  onChange={handleChange}
                  className="w-full"
                />
              </div>
            )}

            <FloatingLabelInput
              id="documentPrice"
              label="Document Price"
              name="documentPrice"
              value={newOrg.documentPrice?.toString() || ""}
              onChange={handleChange}
              className="mb-2"
            />

            <FloatingLabelInput
              id="documentsAddedPrice"
              label="Documents Added Price"
              name="documentsAddedPrice"
              value={newOrg.documentsAddedPrice?.toString() || ""}
              onChange={handleChange}
              className="mb-2"
            />
            <FloatingLabelInput
              id="documentsExportedPrice"
              label="Documents Exported Price"
              name="documentsExportedPrice"
              value={newOrg.documentsExportedPrice?.toString() || ""}
              onChange={handleChange}
              className="mb-2"
            />

            <CheckboxWithText
              id="uniqueDocumentsPricing"
              name="uniqueDocumentsPricing"
              checked={newOrg.uniqueDocumentsPricing}
              onCheckedChange={(checked: boolean) =>
                setNewOrg((prev) => ({ ...prev, uniqueDocumentsPricing: checked }))
              }
              label="Unique Documents Pricing"
              className="mb-2"
            />

            <div className="flex gap-2 mb-2 w-full">
              <FloatingLabelInput
                id="invProjectPrice"
                label="INV Project Price"
                name="invProjectPrice"
                value={newOrg.invProjectPrice?.toString() || ""}
                onChange={handleChange}
              />

              <FloatingLabelInput
                id="ftoProjectPrice"
                label="FTO Project Price"
                name="ftoProjectPrice"
                value={newOrg.ftoProjectPrice?.toString() || ""}
                onChange={handleChange}
              />

              <FloatingLabelInput
                id="app_project_price"
                label="APP Project Price"
                  name="appProjectPrice"
                value={newOrg.appProjectPrice?.toString() || ""}
                onChange={handleChange}
              />
            </div>
          </ScrollArea>
          <DialogFooter>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </form>
    </Dialog>
  );
}

export default AddOrganizationModal;
