/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Organization } from "@/types/types";

interface ConfirmRemoveOrgModalProps {
  open: boolean;
  onClose: () => void;
  removeOrg: (confirmOrgName: string) => void;
  org: Organization;
}

function ConfirmRemoveOrgModal({
  open,
  onClose,
  removeOrg,
  org,
}: ConfirmRemoveOrgModalProps) {
  const [confirmOrgName, setConfirmOrgName] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");

  useEffect(() => {
    if (open && org) {
      setOrgName(org.name);
      setConfirmOrgName("");
    }
  }, [open, org]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete organization {orgName}?</DialogTitle>
          <DialogDescription>
            All users and projects will be deleted from the database. You cannot undo
            this action. Type "{orgName}" to confirm.
          </DialogDescription>
        </DialogHeader>
        <Input
          placeholder="Confirm Organization Name"
          onChange={(e) => setConfirmOrgName(e.target.value)}
          value={confirmOrgName}
        />
        <DialogFooter className="sm:justify-end">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={() => removeOrg(confirmOrgName)}
            disabled={confirmOrgName !== orgName}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmRemoveOrgModal;
