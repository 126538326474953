/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { ClaimFeatureEditStep, CreateStepGroup } from ".";
import { useCreateProjectStore } from "@/store";
import { ElementType } from "@/types/types";
import { RadioGroup, RadioGroupWithText } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { DragAndDropFileArea } from "@/components";

/**
 * @description Claim feature source on project creation
 */
const ApplicationSubjectStep: React.FC<{
  startExpanded: boolean;
  isCompleted: boolean;
}> = ({ startExpanded, isCompleted }) => {
  const {
    updateSubjectMode,
    subjectMode,
    claims,
    features,
    abstract,
    disclosure,
    updateAbstract,
    updateClaims,
    updateFeatures,
    updateDisclosure,
    disclosureMode,
    updateDisclosureMode,
    addFile,
  } = useCreateProjectStore();

  const infoPopoverContent =
    "If claims are provided, features will be generated automatically. If features are provided, claims can be added manually after project creation.";


  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      for (let selectedFile of selectedFiles) {
      

      
          addFile(selectedFile);
       
      }
    }
  };

  return (
    <CreateStepGroup
      stepName="Application Language"
      isCompleted={isCompleted}
      startExpanded={startExpanded}
      infoPopoverContent={infoPopoverContent}
    >
      <div className="space-y-4">
      
        <RadioGroup
          defaultValue={subjectMode}
          onValueChange={(value) => updateSubjectMode(value as "claim" | "feature")}
          className="flex flex-wrap gap-4"
        >
            {/* <RadioGroupWithText value="disclosure" id="disclosure" label="Extract from invention disclosure" /> */}
            <RadioGroupWithText value="claim" id="claim" label="Enter claim language" />
            <RadioGroupWithText
              value="feature"
              id="feature"
              label="Enter feature language"
            />
        </RadioGroup>

       


{subjectMode === "disclosure" && (
           <div>
           <Label htmlFor="disclosure">Invention Disclosure</Label>
           <RadioGroup
             defaultValue={disclosureMode}
             onValueChange={(value) => updateDisclosureMode(value as 'text' | 'file')}
             className="flex gap-4 mb-2"
           >
             <div className="flex items-center space-x-2">
               <RadioGroupWithText value="text" id="text-input" label="Enter text" />
             </div>
             <div className="flex items-center space-x-2">
               <RadioGroupWithText value="file" id="file-input" label="Upload file" />
             </div>
           </RadioGroup>
            {disclosureMode === 'text' ? (
              <AutosizeTextarea
                id="disclosure"
                value={disclosure || ""}
                onChange={(e) => updateDisclosure(e.target.value)}
                placeholder="Enter your invention disclosure here"
                className="w-full mt-1"
                rows={4}
              />
            ) : (
              <DragAndDropFileArea
                  handleFiles={handleFileChange}
                  supportedFileTypes={[".pdf", ".txt", ".pptx", ".docx"]}
                />
            )}
          </div>
        )}
          
          {subjectMode === "feature" && (
          <ClaimFeatureEditStep
            itemType={ElementType.FEATURE}
            setItems={updateFeatures}
            startInEditMode={true}
            initialItems={features}
            isUpdateMode={false}
            showCollapse={false}
          />
        )}

        {subjectMode === "claim" && (
          <ClaimFeatureEditStep
            itemType={ElementType.CLAIM}
            setItems={updateClaims}
            startInEditMode={true}
            initialItems={claims}
            isUpdateMode={false}
            showCollapse={false}
          />
        )}
      </div>
    </CreateStepGroup>
  );
};

export default ApplicationSubjectStep;
