import React, { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { InfoPopover, UploadFileModal } from "@/components";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { PlusIcon, TrashIcon, Cross1Icon, MagnifyingGlassIcon} from "@radix-ui/react-icons";
import { ListPlusIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Paragraph } from "@/components/ui/typography";
import { Table, TableBody, TableCellCondensed, TableRow } from "@/components/ui/table";
import {
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
} from "@/components/ui/tooltip";

export const ExpertKnowledge: React.FC<{
  value: string;
  onChange: (value: string) => void;
  isEditing: boolean;
  
}> = ({ value, onChange, isEditing }) => {
  const infoContent =
    "The level of skill that a person of ordinary skill in the art (POSITA) would have in the relevant field.";

  return (
    <div className="space-y-2 border border-gray-200 rounded-md px-4 py-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Label className="mb-0">Expert Knowledge</Label>
          <InfoPopover content={infoContent} />
        </div>
      </div>
      {isEditing ? (
        <AutosizeTextarea
          id="expert-knowledge"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Define a person of ordinary skill in the art..."
          className="h-32"
        />
      ) : (
        <>
          {value ? (
            <Paragraph className="text-sm whitespace-pre-wrap">{value}</Paragraph>
          ) : (
            <Paragraph className="text-sm text-muted-foreground">No expert knowledge added.</Paragraph>
          )}
        </>
      )}
    </div>
  );
};

export const TermsAndDefinitions: React.FC<{
  terms: Record<string, string>;
  onChange: (terms: Record<string, string>) => void;
  isEditing: boolean;
  onBulkAdd: () => void;
  searchTerm: (query: string) => void;
}> = ({ terms, onChange, isEditing, onBulkAdd, searchTerm }) => {
  const infoContent =
    "Important terms and their definitions relevant to the subject matter.";
  const handleTermChange = (index: number, newTerm: string) => {
    const newTerms = { ...terms };
    const oldTerm = Object.keys(newTerms)[index];
    const definition = newTerms[oldTerm];
    delete newTerms[oldTerm];
    newTerms[newTerm] = definition;
    onChange(newTerms);
  };

  const handleDefinitionChange = (term: string, definition: string) => {
    onChange({ ...terms, [term]: definition });
  };

  const addNewTerm = () => {
    const newKey = `New Term ${Object.keys(terms).length + 1}`;
    onChange({ ...terms, [newKey]: "" });
  };

  const deleteTerm = (termToDelete: string) => {
    const newTerms = { ...terms };
    delete newTerms[termToDelete];
    onChange(newTerms);
  };

  return (
    <div className="space-y-2 border border-gray-200 rounded-md px-4 py-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Label className="mb-0">Terms and Definitions</Label>
          <InfoPopover content={infoContent} />
        </div>

        {isEditing && (
           <div className="flex items-center gap-2">
           <Button variant="outline" onClick={onBulkAdd}>
             Bulk Add
           </Button>
           {Object.keys(terms).length === 0 && (
              <Button size="icon" onClick={addNewTerm}>
                <PlusIcon />
              </Button>
            )}
         </div>
        )}
      </div>

      {Object.keys(terms).length === 0 && !isEditing && (
        <Paragraph className="text-sm text-muted-foreground">No terms or definitions added.</Paragraph>
      )}

      {Object.keys(terms).length > 0 && (
        <Table className="mt-2">
          <TableBody>
            {Object.entries(terms).map(([term, definition], index) => (
              <TableRow key={index}>
                <TableCellCondensed className="w-[15%] min-w-[150px]">
                  {isEditing ? (
                    <Input
                      value={term}
                      onChange={(e) => handleTermChange(index, e.target.value)}
                      placeholder="Term"
                    />
                  ) : (
                    term
                  )}
                </TableCellCondensed>
                <TableCellCondensed className="w-[80%]">
                  {isEditing ? (
                    <Input
                      value={definition}
                      onChange={(e) => handleDefinitionChange(term, e.target.value)}
                      placeholder="Definition"
                    />
                  ) : (
                    definition
                  )}
                </TableCellCondensed>
                <TableCellCondensed className="w-[5%]">
                  {isEditing ? (
                    <Button
                      size="icon"
                      variant="outline"
                      onClick={() => deleteTerm(term)}
                    >
                      <TrashIcon />
                    </Button>
                  ) : (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            size="icon"
                            variant="ghost"
                            onClick={() => searchTerm(`${term}: ${definition}`)}
                          >
                            <MagnifyingGlassIcon />
                          </Button>
                        </TooltipTrigger>

                        <TooltipContent>Search in subject</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </TableCellCondensed>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {Object.keys(terms).length > 0 && isEditing && (
        <div className="flex justify-center">
          <Button size="icon" onClick={addNewTerm}>
            <PlusIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export const ImportantFacts: React.FC<{
  facts: string[];
  onChange: (facts: string[]) => void;
  isEditing: boolean;
  onBulkAdd: () => void;
  searchTerm: (query: string) => void;
}> = ({ facts, onChange, isEditing, onBulkAdd, searchTerm }) => {
  const infoContent = "Key facts or points related to the subject matter.";
  return (
    <div className="space-y-2 border border-gray-200 rounded-md px-4 py-3">
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-2">
          <Label className="mb-0">Key Facts</Label>
          <InfoPopover content={infoContent} />
        </div>

        {isEditing && (
           <div className="flex items-center gap-2">
           <Button variant="outline" onClick={onBulkAdd}>
             Bulk Add
           </Button>
           {facts.length === 0 && (
               <Button size="icon" onClick={() => onChange([...facts, ""])}>
               <PlusIcon />
             </Button>
            )}
         </div>
        )}

       
      </div>
      {facts.length === 0 && !isEditing && (
        <Paragraph className="text-sm text-muted-foreground">No key facts added.</Paragraph>
      )}

      {facts.length > 0 && (
        <Table className="mt-2">
          <TableBody>
            {facts.map((fact, index) => (
              <TableRow key={index}>
                <TableCellCondensed className="w-[95%]">
                  {isEditing ? (
                    <Input
                      value={fact}
                      onChange={(e) => {
                        const newFacts = [...facts];
                        newFacts[index] = e.target.value;
                        onChange(newFacts);
                      }}
                      placeholder="Fact"
                    />
                  ) : (
                    fact
                  )}
                </TableCellCondensed>
                <TableCellCondensed className="w-[5%]">
                  {isEditing ? (
                    <Button
                      size="icon"
                      variant="outline"
                      onClick={() => onChange(facts.filter((_, i) => i !== index))}
                    >
                      <TrashIcon />
                    </Button>
                  ) : (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            size="icon"
                            variant="ghost"
                            onClick={() => searchTerm(fact)}
                          >
                            <MagnifyingGlassIcon />
                          </Button>
                        </TooltipTrigger>

                        <TooltipContent>Search in subject</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </TableCellCondensed>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {facts.length > 0 && isEditing && (
        <div className="flex justify-center">
          <Button size="icon" onClick={() => onChange([...facts, ""])}>
            <PlusIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export const PriorArtSearchInstructions: React.FC<{
  instructions: string;
  onChange: (instructions: string) => void;
  isEditing: boolean;
}> = ({ instructions, onChange, isEditing }) => {
  const infoContent = "Instructions for conducting a prior art search.";
  const [selected, setSelected] = useState(instructions);

  useEffect(() => {
    if (selected === "all-claims" || selected === "independent-claims") {
      onChange(selected);
    }
  }, [selected]);

  const handleSelectChange = (value: string) => {
    setSelected(value);
    onChange(value);
  };
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 mb-2">
        <Label className="mb-0" htmlFor="search-instructions">
          Prior Art Search Instructions
        </Label>
        <InfoPopover content={infoContent} />
      </div>
      {isEditing ? (
        <>
          <Select onValueChange={handleSelectChange} value={selected}>
            <SelectTrigger>
              <SelectValue placeholder="Select search instructions" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all-claims">Search all claims</SelectItem>
              <SelectItem value="independent-claims">
                Prioritize independent claims
              </SelectItem>
              <SelectItem value="custom">Custom instructions</SelectItem>
            </SelectContent>
          </Select>
          {selected === "custom" && (
            <AutosizeTextarea
              id="search-instructions"
              value={instructions}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Enter custom search instructions..."
              className="h-32"
            />
          )}
        </>
      ) : (
        <>
        {instructions ? (
        <Paragraph className="text-sm mt-2 whitespace-pre-wrap">
          {instructions == "all-claims"
            ? "All Claims"
            : instructions == "independent-claims"
              ? "Independent Claims"
              : instructions}
        </Paragraph>
      ) : (
        <Paragraph className="text-sm text-muted-foreground">No search instructions added.</Paragraph>
      )}
      </>
      )}
    </div>
  );
};

export const PriorArtFilterInstructions: React.FC<{
  instructions: string;
  onChange: (instructions: string) => void;
  isEditing: boolean;
}> = ({ instructions, onChange, isEditing }) => {
  const infoContent = "Instructions for filtering and ranking prior art results.";
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 mb-2">
        <Label className="mb-0" htmlFor="filter-instructions">
          Prior Art Filter Instructions
        </Label>
        <InfoPopover content={infoContent} />
      </div>
      {isEditing ? (
        <AutosizeTextarea
          id="filter-instructions"
          value={instructions}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Enter instructions for filtering and reranking prior art..."
        />
      ) : (
        <>
        {instructions ? (
        <Paragraph className="text-sm">
          {instructions}
        </Paragraph>
      ) : (
        <Paragraph className="text-sm text-muted-foreground">No filter instructions added.</Paragraph>
      )}
      </>
      )}
    </div>
  );
};

export const RelevantDocuments: React.FC<{
  documents: string[];
  onChange: (documents: string[]) => void;
  isEditing: boolean;
}> = ({ documents, onChange, isEditing }) => {
  const [open, setOpen] = useState(false);
  const infoContent = "Relevant documents or URLs for reference (max 3)";
  return (
    <div className="space-y-2 border border-gray-200 rounded-md px-4 py-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Label className="mb-0">Relevant Documents </Label>
          <InfoPopover content={infoContent} />
        </div>
        {documents.length < 3 && isEditing && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" onClick={() => onChange([...documents, ""])}>
                <PlusIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => setOpen(true)}>
                Upload file
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onChange([...documents, ""])}>
                {" "}
                Add URL
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      {documents.length === 0 && !isEditing && (
        <Paragraph className="text-sm text-muted-foreground">No relevant documents added.</Paragraph>
      )}

      {documents.length > 0 && (
        <Table className="mt-2">
          <TableBody>
            {documents.map((doc, index) => (
              <TableRow key={index}>
                <TableCellCondensed className="w-[95%]">
                  {isEditing ? (
                    <Input
                      value={doc}
                      onChange={(e) => {
                        const newDocs = [...documents];
                        newDocs[index] = e.target.value;
                        onChange(newDocs);
                      }}
                      placeholder="URL"
                    />
                  ) : (
                    doc
                  )}
                </TableCellCondensed>
                {isEditing && (
                  <TableCellCondensed className="w-[5%]">
                    <Button
                      size="icon"
                      variant="outline"
                      onClick={() => onChange(documents.filter((_, i) => i !== index))}
                    >
                      <TrashIcon />
                    </Button>
                  </TableCellCondensed>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <UploadFileModal
        open={open}
        handleClose={() => setOpen(false)}
        inContext={true}
        handleContextAddClick={(files) => {
          onChange([...documents, ...files.map((file) => file.name)]);
          setOpen(false);
        }}
      />
    </div>
  );
};
