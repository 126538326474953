/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, ChangeEvent } from "react";
import { useAppStateStore } from "@/store";
import { useOrgs } from "@/hooks";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { FloatingLabelInput } from "@/components/ui/floating-input";

// Initial form state
const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  role: "User",
  status: "PassChange",
  id: "",
};

interface AddUserModalProps {
  open: boolean;
  onClose: () => void; // Added onClose prop
  users: { email: string }[];
  orgName: string;
  orgId: string;
}

/**
 * @description Add user to organization modal
 */
function AddUserModal({
  open,
  onClose,
  users,
  orgName,
  orgId,
}: AddUserModalProps) {
  const { addNewUser } = useOrgs();
  const [newUser, setNewUser] =
    useState<typeof initialFormState>(initialFormState);
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();
  const { getAdminDetailsForOrg } = useOrgs();
  // Handles form change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setNewUser({ ...newUser, [name]: checked ? "Admin" : "User" });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
  };

  // Resets form to initial state
  const resetForm = () => {
    setNewUser(initialFormState);
  };

  // Adds new user to organization
  const handleSubmit = async () => {
    if (newUser && !users.some((user) => user.email === newUser.email)) {
      const response = await addNewUser(newUser, orgName, orgId);
      if (response.success) {
        addSuccessMessage(`User ${newUser.email} added successfully`);
        getAdminDetailsForOrg(orgId);
      } else {
        addErrorMessage("Error adding user: " + newUser.email);
      }
    }
    resetForm();
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Add a new user</DialogTitle>
          <DialogDescription>
            The user will receive an email with instructions to log in.
          </DialogDescription>
        </DialogHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="grid gap-4 pb-4">
            <div className="grid gap-3">
              <FloatingLabelInput
                id="firstName"
                name="firstName"
                value={newUser.firstName}
                onChange={handleChange}
                className="col-span-3"
                label="First Name"
              />
            </div>
            <div className="grid gap-3">
              <FloatingLabelInput
                id="lastName"
                name="lastName"
                value={newUser.lastName}
                onChange={handleChange}
                className="col-span-3"
                label="Last Name"
              />
            </div>

            <div className="grid gap-3">
              <FloatingLabelInput
                id="email"
                name="email"
                value={newUser.email}
                onChange={handleChange}
                className="col-span-3"
                label="Email"
              />
            </div>
            <div className="flex items-start space-x-3">
              <div className="flex flex-col">
                <CheckboxWithText
                  id="admin"
                  checked={newUser.role === "Admin"}
                  onCheckedChange={(checked) =>
                    setNewUser({ ...newUser, role: checked ? "Admin" : "User" })
                  }
                  label="Make this user an admin"
                />
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Add User</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddUserModal;
