/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  useAppStateStore,
  useProjectStore,
  useCreateProjectStore,
} from "../store";
import { useNavigate } from "react-router-dom";
import { useVector, useLlm, useViz, useApi, useProject } from ".";
import { Patent } from "../types/types";
import { ApiResponse } from "../types/types";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useCreateProject = () => {
  const { postRequest, handleError } = useApi();
  const {
    generateFeatures,
    generatePatentFromDisclosure,
    generateFeaturesFromClaims,
  } = useLlm();
  const { addErrorMessage } = useAppStateStore();
  const { uploadToVDB, rerankReferences } = useVector();
  const { searchPriorArt, pruneReferences, generateKeywords } = useLlm();
  const {
    addReferencesToProject,
    updateProjectDetails,
    getProjectMetadata,
    addPDFToProject,
    addToDocumentsAdded,
  } = useViz();
  const { getUserProjects } = useProject();
  const {
    updateCurrentProjectId,
    updateCurrentProjectDetails,
    currentProjectDetails,
    subjectDetails,
    updateSubjectDetails,
  } = useProjectStore();

  const {
    subjectNumbers,
    referenceNumbers,
    projectName,
    clientNumber,
    toDate,
    fromDate,
    cpcCodes,
    types,
    statuses,
    countryCodes,
    assignees,
    features,
    claims,
    referenceOption,
    maxResults,
    abstract,
    subjectMode,
    files,
    disclosure,
    updateAbstract,
    updateProjectError,
    updateFeatures,
    updateClaims,
    updateSpinnerText,
  } = useCreateProjectStore();
  const navigate = useNavigate();

  /**
   * Chunks an array into smaller arrays, helper function for processReferences
   * @param {string[]} array - The array to chunk
   * @param {number} chunkSize - The size of the chunk to process
   * @returns An array of chunks
   */
  async function chunkArray(
    array: string[],
    chunkSize: number
  ): Promise<string[][]> {
    const chunks: string[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  /**
   * Process references for a project
   * @param {string[]} rCitations - The citations to process
   * @param {number} chunkSize - The size of the chunk to process
   * @param {string} rProjectId - The id of the project to process the references for
   * @param {boolean} is_citation - Whether the references are citations
   */
  async function processReferences(
    rCitations: string[],
    chunkSize: number,
    rProjectId: string,
    is_citation: boolean,
    rerank: boolean
  ) {
    // Step 0: trim rCitations to 25
    if (is_citation) {
      rCitations = rCitations.slice(0, 25);
    } else {
      rCitations = rCitations.slice(0, 100);
    }

    // Step 1: Chunk the citations at the very beginning
    const citationChunks = await chunkArray(rCitations, chunkSize);
    let allReferenceIds: string[] = [];

    // Step 2: Process each chunk in parallel
    const processChunk = async (chunk: string[]) => {
      // Sequential actions for a single chunk
      const rReferenceIds = await addReferencesToProject(
        rProjectId,
        chunk,
        is_citation,
        true // set to true so doesnt add what may be pruned to invoice
      );
      if (!rReferenceIds.success) {
        addErrorMessage(
          rReferenceIds.message ||
            "An error occurred while creating this project. Try again later."
        );
        return; // TODO: confirm abort
      }
      const data = rReferenceIds.data.map((source: any) => source.reference_id);
      allReferenceIds = allReferenceIds.concat(data);
      const uploadResponse = await uploadToVDB(rProjectId, data, false, rerank);
      if (!uploadResponse.success) {
        addErrorMessage(
          uploadResponse.message ||
            "An error occurred while creating this project. Try again later."
        );
        return; // TODO: confirm abort
      }
    };

    // Step 3: Create a promise for each chunk to be processed and run them in parallel
    const chunkPromises = citationChunks.map((chunk: string[]) =>
      processChunk(chunk).catch((error: any) => {
        console.error("An error occurred during processing the chunk:", error);
      })
    );

    // Wait for all chunks to be processed
    await Promise.all(chunkPromises);

    return;
  }

  const generateKeywordsAndReferences = async (
    projectId: string,
    subjectId: string,
    rFeatures: { [key: string]: string }[],
    citations?: string[]
  ) => {
    let rKeywords;
    let rReferences: Patent[] = [];
    const keywordsResponse = await generateKeywords(
      projectId,
      subjectId,
      rFeatures,
      "True"
    );

    rKeywords = keywordsResponse.data;

    let rMaxResults;
    if (maxResults) {
      rMaxResults = maxResults || 20;
    } else {
      rMaxResults = 20;
    }
    let rerank = false;

    const chunkSize = 10;
    let refNums: string[] = [];
    if (referenceOption === "cited") {
      updateSpinnerText("Processing citations...");

      const rCitations = citations || [];
      // rerank if there are less than maxResults citation
      if (rCitations.length < rMaxResults) {
        rerank = true;
      }

      await processReferences(rCitations, chunkSize, projectId, true, rerank);
    } else if (referenceOption === "find") {
      updateSpinnerText("Finding and analyzing references...");

      const payload: {
        max_results: number;
        to_date?: string;
        from_date?: string;
        types?: string[];
        statuses?: string[];
        cpc_codes?: string[];
        country_codes?: string[];
        assignees?: string[];
      } = {
        max_results: maxResults || 100,
      };

      if (toDate) payload.to_date = toDate.toISOString().split("T")[0];
      if (fromDate) payload.from_date = fromDate.toISOString().split("T")[0];
      if (types && types.length > 0) payload.types = types;
      if (statuses && statuses.length > 0) payload.statuses = statuses;
      if (countryCodes && countryCodes.length > 0)
        payload.country_codes = countryCodes;
      if (assignees && assignees.length > 0) payload.assignees = assignees;
      if (cpcCodes && cpcCodes.length > 0) payload.cpc_codes = cpcCodes;

      const emptyKeywords: string[] = [];
      const priorArtResponse = await searchPriorArt(
        projectId,
        emptyKeywords,
        payload
      );
      if (priorArtResponse && priorArtResponse.success) {
        rReferences = Array.isArray(priorArtResponse.data)
          ? (priorArtResponse.data as Patent[])
          : [];
      } else {
        rReferences = [];
        addErrorMessage(
          "Error searching for prior art. Add references manually."
        );
      }

      if (Array.isArray(rReferences) && rReferences.length !== 0) {
        refNums = rReferences.map((ref: Patent) => ref.number);
      }

      // rerank if there are less than maxResults references
      if (rReferences.length < rMaxResults) {
        rerank = true;
      }
      await processReferences(refNums, chunkSize, projectId, true, rerank);
    } else if (referenceOption === "input") {
      refNums = referenceNumbers || [];

      // rerank if there are less than maxResults references
      if (refNums.length < rMaxResults) {
        rerank = true;
      }
      await processReferences(refNums, chunkSize, projectId, true, rerank);
    } else if (referenceOption === "upload") {
      const promises = files.map((file) => addPDFToProject(projectId, file));
      const results: ApiResponse<any>[] = await Promise.all(promises); // Await the Promise.all call

      const filesReferenceIds: string[] = [];
      results.forEach((result, index) => {
        if (result.success) {
          filesReferenceIds.push(result.data[0].reference_id); // TODO: check
        } else {
          addErrorMessage(`Error adding file: ${files[index].name}`);
        }
      });
      // always rerank if uploading files (takes a while)
      rerank = true;
      await uploadToVDB(projectId, filesReferenceIds, false, rerank);
    }
    updateSpinnerText("Processing references...");

    if (rReferences.length > rMaxResults) {
      const response = await pruneReferences(projectId, rMaxResults, true);
      const sourcesToRemove = response.data.map((source: any) => source.id);
      await updateProjectDetails(projectId, {
        old_references: sourcesToRemove,
      });
    }
    if (!rerank) {
      await rerankReferences(projectId);
    }
  };

  /**
   * Fetches and navigates to a project at the end of creation
   * @param {string} projectId - The id of the project to fetch
   */
  const fetchAndNavigateToProject = async (projectId: string) => {
    updateCurrentProjectId(projectId);
    const response = await getProjectMetadata(projectId);
    const finalIds = response.data.reference.map((ref: any) => ref.id);
    finalIds.push(response.data.subject.id);
    await addToDocumentsAdded(projectId, finalIds, false);
    if (!response.success) {
      addErrorMessage(
        "Error fetching project data. Please navigate to project from home."
      );
      navigate("/home");
      return;
    }
    navigate(`/project/${projectId}/subject`);
  };

  /**
   * @description Creates a project from element language
   * @param {string} projectType - The type of the project
   */
  const createProjectFromLanguage = async (
    projectType: string
  ): Promise<ApiResponse> => {
    try {
      updateSpinnerText("Creating project...");

      // Generate features if not provided
      let featuresToSend: { [key: string]: string }[] = [];
      if (subjectMode === "disclosure") {
        const disclosureResponse =
          await generatePatentFromDisclosure(disclosure);
        if (disclosureResponse.success) {
          updateClaims(disclosureResponse.data.claims);
          updateAbstract(disclosureResponse.data.abstract);
        }
      }

      if (
        (subjectMode === "claim" || subjectMode === "disclosure") &&
        claims.length > 0
      ) {
        const featuresResponse = await generateFeaturesFromClaims(claims);
        if (featuresResponse.success) {
          featuresToSend =
            (featuresResponse.data as unknown as { [key: string]: string }[]) ||
            [];
        }
      } else {
        featuresToSend = [];
      }

      // Create project and update state
      const res = await postRequest("post_create_project_from_language", {
        name: projectName,
        claims: subjectMode === "feature" ? [] : claims,
        features: featuresToSend,
        client_number: clientNumber,
        type: projectType,
        abstract: abstract || "",
        // cpc_codes: cpcCodes,
      });

      const response = res.data;
      const rSubjectId = response.subject_id;
      const rProjectId = response.project_id;
      const rFeatures = featuresToSend;

      updateCurrentProjectId(rProjectId);
      updateCurrentProjectDetails({
        ...currentProjectDetails,
        name: response.project_name,
        subjectId: rSubjectId,
        features: rFeatures as { [key: string]: string }[],
        type: response.project_type,
        claims: claims,
      });
      updateSubjectDetails({
        ...subjectDetails,
        id: response.subject_id,
        number: response.subject_number,
        name: response.subject_name,
        nickname: response.nickname,
        note: response.notes,
      });

      await getUserProjects();

      // Fetch and navigate to project
      await fetchAndNavigateToProject(rProjectId);

      return { success: true, data: { rSubjectId, rProjectId, rFeatures } };
    } catch (error) {
      return handleError(error, "Error creating project from language");
    }
  };

  /**
   * @description Creates a project from a patent or application number
   * @param {string} project_type - The type of the project
   * @returns Result object with success status and data or error message.
   */
  const createProjectFromNumber = async (
    projectType: string
  ): Promise<ApiResponse> => {
    try {
      const res = await postRequest("post_create_project", {
        subject_number: subjectNumbers[0],
        name: projectName,
        type: projectType,
        client_number: clientNumber,
        cpc_codes: cpcCodes,
        claims: claims,
        features: features,
      });
      const response = res.data;

      const rSubjectId = response.subject_id;
      const rProjectId = response.project_id;

      // Update current project
      updateCurrentProjectId(rProjectId);
      updateCurrentProjectDetails({
        ...currentProjectDetails,
        name: response.project_name || response.name,
        subjectId: response.subject_id,
      });
      updateSubjectDetails({
        id: rSubjectId,
        number: response.subject_number,
        name: response.subject_name,
        publicationDate: response.publication_date,
        filingDate: response.filing_date,
        inventors: response.inventors,
        assignee: response.assignee,
        claims: response.claims,
        features: features,
        nickname: response.nickname,
        note: response.notes,
        fullBody: response.full_body,
      });

      const documents = [rSubjectId];
      const uploadResponse = await uploadToVDB(
        rProjectId,
        documents,
        false,
        false
      );
      if (!uploadResponse.success) {
        // updateCreateProjectError(true);
        addErrorMessage(
          uploadResponse.message ||
            "An error occurred while creating this project. Try again later."
        );
        // return;
      }

      await fetchAndNavigateToProject(rProjectId);

      await getUserProjects();

      return {
        success: true,
      };
    } catch (error) {
      updateProjectError(true);
      return handleError(error, "Error creating project from number");
    }
  };

  /**
   * @description Creates a project from a patent or application number
   * @param {string} subject_number - The number of the subject to create the project for
   * @param {string} project_name - The name of the project
   * @param {string} project_type - The type of the project
   * @param {string} clientNumber - The client number of the project
   * @returns Result object with success status and data or error message.
   */
  const getSubjectDetailsOnCreate = async (
    subjectNumbers: string[],
    getFeatures: boolean
  ): Promise<any> => {
    try {
      // Create project in db
      const res = await postRequest("post_subject_details_on_create", {
        subject_numbers: subjectNumbers,
      });
      const response = res.data;

      if (getFeatures) {
        const firstResponse = response[0];
        const featuresResponse = await generateFeatures(firstResponse.id);
        let features: { [key: string]: string }[] = [];
        if (featuresResponse.success) {
          features =
            (featuresResponse.data as unknown as { [key: string]: string }[]) ||
            [];
        }
        updateFeatures(features);

        return {
          success: true,
          data: [
            {
              ...response[0],
              features: features,
            },
          ],
        };
      } else {
        return { success: true, data: response };
      }
    } catch (error) {
      updateProjectError(true);
      return handleError(error, "Error creating project from number");
    }
  };

  return {
    fetchAndNavigateToProject,
    createProjectFromLanguage,
    createProjectFromNumber,
    getSubjectDetailsOnCreate,
  };
};

export default useCreateProject;
