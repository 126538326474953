import { DateTime } from "luxon";
import { Dayjs } from "dayjs";

export const PATENT_TYPES = ["Application", "Issued Asset", "International"];
export interface Project {
  id: string;
  project_id?: string;
  name: string;
  subjectId?: string;
  referenceIds?: string[];
  referenceDetails?: any[];
  documentIds?: string[];
  keywords?: string[];
  features?: any[];
  context?: Context;
  fullBody?: any[];
  claims?: any[];
  inventors?: any[];
  primaryExaminer?: any[];
  assignee?: any[];
  publicationDate?: any[];
  filingDate?: any;
  notes?: any[];
  number?: string;
  type?: string;
  owner?: string;
  createdBy?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  clientNumber?: string;
  documentsToNumbers?: { [key: string]: string };
  documentsToNicknames?: { [key: string]: string };
  isPortfolio?: boolean;
  projects?: any[];
  introductionLanguage?: string;
  prefix?: string;
  priorityDate?: DateTime;

  subject?: {
    name: string;
    id: string;
    number: string;
    nickname: string;
    abstract: string;
    publicationDate: string;
    filingDate: string;
    inventors: string;
    assignee: string;
    primaryExaminer: string;
    prefix?: string;
  };
}

export interface Context {
  expertKnowledge: string;
  termsAndDefinitions: Record<string, string>;
  importantFacts: string[];
  priorArtSearchInstructions: string;
  priorArtSearchFilterInstructions: string;
  relevantDocumentIds: string[];
}

export interface SearchResult {
  referenceId: string;
  reference_id?: string;
  paragraph: string;
  text: string;
  color?: string;
  figureUrls?: string[];
  figure_urls?: string[];
  prefix?: string;
}

export interface Message {
  text?: string;
  sender: string;
  loading?: boolean;
}

export interface InvalidityCitation {
  id: string;
  text: string;
  paragraph: string;
  color?: string;
  figureUrls?: string[];
  figure_urls?: string[];
  score?: number;
  figureRefs?: string[];
  figure_refs?: string[];
  invalidityId?: string;
  invalidity_id?: string;
  invalidity?: string;
  organizationId?: string;
  organization_id?: string;
}

export interface AlertMessage {
  key: number;
  message: string;
  severity: "error" | "warning" | "info" | "success";
}

export interface LoadingMessage {
  key: string;
  message: string;
}

export interface Organization {
  organizationId?: string;
  organizationName?: string | null;
  name?: string | null;
  isTesting?: boolean | null;
  passthroughBilling?: boolean | null;
  clientNumbers?: string[] | null;
  invoiceType?: "MONTHLY" | "YEARLY" | "NONE" | null;
  contractStart?: Dayjs | null;
  contractEnd?: Dayjs | null;
  monthlyLicensePrice?: string | null;
  monthlyProjectPrice?: string | null;
  yearlyLicensePrice?: string | null;
  monthlySeatPrice?: string | null;
  yearlySeatPrice?: string | null;
  invProjectPrice?: string | null;
  ftoProjectPrice?: string | null;
  sepProjectPrice?: string | null;
  roaProjectPrice?: string | null;
  appProjectPrice?: string | null;
  pfaProjectPrice?: string | null;
  invIncludedProjects?: number | null;
  ftoIncludedProjects?: number | null;
  sepIncludedProjects?: number | null;
  roaIncludedProjects?: number | null;
  appIncludedProjects?: number | null;
  maxSeats?: number | null;
  seats?: number | null;
  totalPrice?: string | null;
  documentPrice?: string | null;
  documentsAddedPrice?: string | null;
  documentsExportedPrice?: string | null;
  uniqueDocumentsPricing?: boolean | null;
  projects?: number | null;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role?: string;
  status?: string;
  enabled?: boolean;
  confirmed?: boolean;
  createdAt?: string;
  lastActiveAt?: string;

  // attributes
  files?: { [key: string]: string };
  chatSessions?: ChatSession[];
  organizationId?: string;
  organization?: Organization;
  projects?: ProjectUser[];
}

export interface ApiResponse<T = any> {
  success: boolean;
  data?: T;
  message?: string;
  error?: any;
  status?: number;
}

export interface Element {
  color: string;
  label: string;
  language: string;
}

export interface Patent {
  // reference and subject details
  id: string;
  name?: string;
  number?: string;
  nickname?: string;
  abstract?: string;
  claims?: any[];
  features?: any[];
  body?: { text: string }[];
  fullBody?: any[];
  publicationDate?: string;

  filingDate?: string;

  inventors?: string;
  assignee?: string;
  primaryExaminer?: string;
  note?: string;
  notes?: string;
  figureUrls?: string[];
  referenceId?: string; // for priorArt search results
  isPatent?: boolean;
  referenceNumber?: string;
  referenceName?: string;
  referenceNickname?: string;
  priorityDate?: string;
  title?: string;
  cpcCodes?: string[];
  cpcMain?: string[];
  cpcAdditional?: string[];
  prefix?: string;
  filing_date?: string;
  publication_date?: string;
}

export interface PatentContext {
  expertKnowledge: string | null;
  termsAndDefinitions: Record<string, string> | null;
  importantFacts: string[] | null;
  priorArtSearchInstructions: string | null;
  priorArtSearchFilterInstructions: string | null;
  relevantDocumentIds: string[] | null;
}

export interface Invalidity {
  id: string;
  documentId: string;
  claimNumber: string;
  isFeature: boolean;
  projectId: string;
  project: string;
  organizationId: string;
  organization: string;
  citations: InvalidityCitation[];
}

export interface ChatSession {
  id: string;
  userId: string;
  user: User;
  messages: Message[];
  createdAt: DateTime;
  projectId: string;
  project: Project;
  organizationId: string;
  organization: Organization;
}

export interface ProjectUser {
  userId: string;
  user: User;
  projectId: string;
  project: Project;
}

export interface SummaryChartData {
  claimNumber: string;
  [key: string]: any;
}

export enum ParentType {
  PROJ = "PROJECT",
  PORT = "PORTFOLIO",
}

export enum ProjectType {
  PFA = "PFA",
  PFP = "PFP",
  PFC = "PFC",
  INV = "INV",
  FTO = "FTO",
  SEP = "SEP",
  ROA = "ROA",
  APP = "APP",
}

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum ElementType {
  CLAIM = "claim",
  FEATURE = "feature",
}

export enum RoleType {
  ADMIN = "Admin",
  OWNER = "Owner",
  MEMBER = "Member",
}

export enum EnvironmentTypes {
  PROD = "PROD",
  TEST = "TEST",
  DEV = "DEV",
}

export enum ChartColors {
  GREEN = "#C1E1A6",
  YELLOW = "#FFDE7D",
  RED = "#F5A19B",
  GRAY = "#D3D3D3",
  BLUE = "#A6C6E0",
}

export interface Invoice {
  id: string;
  description: string;
  amountDue: number;
  created: string;
  status: string;
  currency: string;
  customerName: string;
  clientMatter: string;
  hostedInvoiceUrl: string;
  lines: InvoiceLine[];
}
export interface InvoiceLine {
  amount: number;
  description: string;
  quantity: number;
  unitAmount: number;
}

export interface AdminProject {
  id: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  name: string;
  numDocumentsAdded: number;
  numDocumentsExported: number;
  numUsers: number;
  portfolioId?: string;
  children?: AdminProject[];
}
