/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from "react";
import he from "he";
import { useProjectStore, useAppStateStore } from "@/store";
import { useViz } from "@/hooks";
import { MoreVertical } from "lucide-react";
import { Patent } from "@/types/types";
import { SourceNumberButton, PatentViewer } from "@/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableCellCondensed,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface DocumentsTableProps {
  referenceDetails: Patent[];
  handleDelete?: (reference: Patent) => void;
  setSelectedReferences?: (references: string[]) => void;
  selectedReferences?: string[];
  searchResultsBoolean?: boolean;
  showHead?: boolean;
  showNotes?: boolean;
  isPruneTable?: boolean;
  isExplorePage?: boolean;
  onClearAll?: () => void;
  onAddSources?: () => void;
  onUploadOtherDocuments?: () => void;
  bodyHeight?: string;
}

function useViewportHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return height;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  referenceDetails,
  handleDelete,
  setSelectedReferences,
  selectedReferences,
  searchResultsBoolean,
  showHead,
  showNotes,
  isPruneTable,
  isExplorePage, // Add this prop
  onClearAll, // Add this prop
  onAddSources, // Add this prop
  onUploadOtherDocuments, // Add this prop
  bodyHeight,
}) => {
  const {
    updateProjectReferenceNote,
    getFullDocument,
    updateProjectReferenceNickname,
  } = useViz();

  // Global state from store
  const { currentProjectId, currentProjectDetails, documentsToNicknames } =
    useProjectStore((state) => ({
      currentProjectId: state.currentProjectId,
      currentProjectDetails: state.currentProjectDetails,
      documentsToNicknames: state.currentProjectDetails.documentsToNicknames,
    }));
  const { addErrorMessage } = useAppStateStore();

  // Local state setup
  const [editingNoteRefId, setEditingNoteRefId] = useState<string>("");
  const [editingNameRefId, setEditingNameRefId] = useState<string>("");
  const [noteContent, setNoteContent] = useState<string>("");
  const [newName, setNewName] = useState<string>("");
  const [selectedReference, setSelectedReference] = useState<Patent | null>(null);
  const [patentDetails, setPatentDetails] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const handleMenuClose = () => {
    setSelectedReference(null);
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedReferences?.(referenceDetails.map((ref) => ref.id));
    } else {
      setSelectedReferences?.([]);
    }
  };

  const handleSelect = (event: ChangeEvent<HTMLInputElement>, ref: Patent) => {
    let newItem;
    if (isPruneTable) {
      newItem = ref.id;
    } else {
      newItem = ref.number;
    }
    if (event.target.checked) {
      const newSelected = [...(selectedReferences || []), newItem];
      setSelectedReferences?.(newSelected);
    } else {
      const newSelected = (selectedReferences || []).filter((item) => item !== newItem);
      setSelectedReferences?.(newSelected);
    }
  };

  const handleCellClick = (reference: Patent) => {
    setSelectedReference(reference);
  };

  const handleRemoveFromProject = (reference: Patent) => {
    if (reference && handleDelete) {
      handleDelete(reference);
      handleMenuClose();
    }
  };

  const handleEditNotes = () => {
    if (selectedReference) {
      setEditingNoteRefId(selectedReference.id);
      setNoteContent(selectedReference.note || "");
      handleMenuClose();
    }
  };

  const handleEditName = () => {
    if (selectedReference) {
      setEditingNameRefId(selectedReference.id);
      setNewName(
        currentProjectDetails.documentsToNicknames[selectedReference.id] || ""
      );
      handleMenuClose();
    }
  };

  const handleSaveName = async () => {
    const response = await updateProjectReferenceNickname(
      currentProjectId,
      editingNameRefId,
      newName
    );
    if (!response.success) {
      addErrorMessage(response.message || "An error occurred while saving the name.");
    }
    setEditingNameRefId("");
    setNewName("");
  };

  const handleSaveNote = async () => {
    const response = await updateProjectReferenceNote(
      currentProjectId,
      editingNoteRefId,
      noteContent
    );
    if (!response.success) {
      addErrorMessage(response.message || "An error occurred while saving the note.");
    }
    setEditingNoteRefId("");
    setNoteContent("");
  };

  const handleViewDetails = async (reference: Patent) => {
    const response = await getFullDocument(currentProjectId, reference.id);
    if (!response.success) {
      addErrorMessage(
        response.message || "An error occurred while fetching the document details."
      );
    } else {
      setPatentDetails(response.data);
    }
    setShowPreview(true);
    handleMenuClose();
  };

  const handleClosePreview = () => {
    setPatentDetails(null);
    setShowPreview(false);
  };

  return (
    <div className="relative px-2">
      <Table>
        <TooltipProvider>
          {showHead && (
            <TableHeader className="sticky top-0 bg-background z-10">
              <TableRow>
                {!searchResultsBoolean && (
                  <TableHead className="w-[12%]">Name</TableHead>
                )}
                <TableHead className="w-[12%]">Number</TableHead>
                <TableHead className="w-[40%]">Title</TableHead>
                <TableHead className="whitespace-nowrap w-[14%]">
                  Priority Date
                </TableHead>
                {showNotes && <TableHead className="w-[14%]">Notes</TableHead>}
                <TableHead className="text-right w-[8%]">
                  {isExplorePage && (
                    <div className="flex justify-end items-center space-x-2">
                      <Button variant="outline" onClick={onClearAll}>
                        Clear
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button>Add</Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem onClick={onAddSources}>
                            Add published patents/applications
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={onUploadOtherDocuments}>
                            Upload other documents
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  )}
                  {/* {isPruneTable && (
                      <div className="flex justify-end">
                        <Checkbox
                          checked={
                            selectedReferences?.length === referenceDetails.length
                          }
                          onCheckedChange={handleSelectAll}
                        />
                      </div>
                    )} */}
                </TableHead>
              </TableRow>
            </TableHeader>
          )}
          <TableCell className="p-0 m-0 w-[100%]" colSpan={6}>
            <div className={cn("flex flex-col", bodyHeight)}>
              <ScrollArea className="flex-grow">
                {/* <TooltipProvider> */}
                <TableBody>
                  {referenceDetails.map((reference) => (
                    <TableRow key={reference.number}>
                      {!searchResultsBoolean && (
                        <TableCellCondensed className="w-[12%]">
                          {editingNameRefId === reference.id ? (
                            <Input
                              value={newName}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setNewName(e.target.value)
                              }
                              onBlur={handleSaveName}
                              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === "Enter") handleSaveName();
                              }}
                              className="w-full"
                              autoFocus
                            />
                          ) : (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <span
                                  onClick={() => {
                                    handleCellClick(reference);
                                    handleEditName();
                                  }}
                                >
                                  {documentsToNicknames[reference.id] &&
                                  documentsToNicknames[reference.id] !== ""
                                    ? documentsToNicknames[reference.id]
                                    : "—"}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Click to edit</p>
                              </TooltipContent>
                            </Tooltip>
                          )}
                        </TableCellCondensed>
                      )}

                      <TableCellCondensed className="w-[12%]">
                        {reference.number && reference.number.length < 20 ? (
                          <SourceNumberButton
                            sourceNumber={reference.number}
                            prefix={reference.prefix || ""}
                          />
                        ) : (
                          "-"
                        )}
                      </TableCellCondensed>
                      {reference.name && (
                        <TableCellCondensed className="w-[40%]">
                          {he.decode(reference.name || "-")}
                        </TableCellCondensed>
                      )}
                      <TableCellCondensed className="w-[14%]">
                        {reference.filingDate || reference.priorityDate
                          ? new Date(
                              reference.filingDate || reference.priorityDate
                            ).toLocaleDateString()
                          : "—"}
                      </TableCellCondensed>
                      {showNotes && (
                        <TableCellCondensed
                          className="w-[14%]"
                          onClick={() => {
                            handleCellClick(reference);
                            handleEditNotes();
                          }}
                        >
                          {editingNoteRefId === reference.id ? (
                            <Input
                              value={noteContent}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setNoteContent(e.target.value)
                              }
                              onBlur={handleSaveNote}
                              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === "Enter") handleSaveNote();
                              }}
                              className="w-full"
                              autoFocus
                            />
                          ) : (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <span>{reference.note || "-"}</span>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Click to edit</p>
                              </TooltipContent>
                            </Tooltip>
                          )}
                        </TableCellCondensed>
                      )}
                      <TableCellCondensed className="text-right w-[8%]">
                        {!searchResultsBoolean ? (
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost" className="h-8 w-8 p-0">
                                <MoreVertical className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <DropdownMenuItem
                                onClick={() => handleViewDetails(reference)}
                              >
                                View Details
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => handleRemoveFromProject(reference)}
                              >
                                Remove from Project
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        ) : (
                          <Checkbox
                            checked={
                              isPruneTable
                                ? selectedReferences?.includes(reference.id) || false
                                : (selectedReferences || []).includes(reference.number)
                            }
                            onCheckedChange={(checked) =>
                              handleSelect(
                                {
                                  target: { checked },
                                } as ChangeEvent<HTMLInputElement>,
                                reference
                              )
                            }
                          />
                        )}
                      </TableCellCondensed>
                    </TableRow>
                  ))}
                </TableBody>
              </ScrollArea>
            </div>
          </TableCell>
        </TooltipProvider>
      </Table>

      {showPreview && (
        <PatentViewer
          open={showPreview}
          handleClose={handleClosePreview}
          patent={patentDetails}
          citationText={""}
        />
      )}
    </div>
  );
};

export default DocumentsTable;
