/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { usePortfolio } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { UploadedFileDisplay, DragAndDropFileArea } from "@/components";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { nanoid } from "nanoid";
import { ScrollArea } from "@/components/ui/scroll-area";

interface UploadFilesToPortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

const UploadFilesToPortfolioModal: React.FC<
  UploadFilesToPortfolioModalProps
> = ({ open, handleClose }) => {
  const { uploadFilesToPortfolio } = usePortfolio();
  const { addLoadingMessage, removeLoadingMessage } = useAppStateStore();

  // Modal state
  const [files, setFiles] = useState<File[]>([]);
  const [fileTypeError, setFileTypeError] = useState<string | null>(null);

  // Handle the add to project button click
  const handleAddClick = async () => {
    const key = nanoid();
    addLoadingMessage("Adding files to portfolio...", key);
    handleClose();
    await uploadFilesToPortfolio(files);
    removeLoadingMessage(key);
    setFiles([]);
  };

  // Handle file change
  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newValidFiles: File[] = [];
      let errorMessage = "";

      for (let selectedFile of selectedFiles) {
        const isFileTypeValid =
          selectedFile.type === "application/pdf" ||
          selectedFile.type === "text/plain";

        if (isFileTypeValid) {
          newValidFiles.push(selectedFile);
        } else {
          if (!isFileTypeValid) {
            errorMessage += `${selectedFile.name}: File type is not supported. Please upload a PDF or text file. `;
          }
        }
      }

      setFiles((prevFiles) => [...prevFiles, ...newValidFiles]);
      setFileTypeError(errorMessage || null);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center">
            Add references to all subjects
          </DialogTitle>
        </DialogHeader>
        <div className="flex-grow flex flex-col space-y-4 overflow-hidden">
          <DragAndDropFileArea
            handleFiles={handleFileChange}
            supportedFileTypes={[".pdf", ".txt"]}
          />

          {files.length > 0 && (
            <ScrollArea className="flex-grow max-h-[200px]">
              <div className="space-y-2">
                {files.map((file, index) => (
                  <UploadedFileDisplay
                    file={file}
                    key={index}
                    onRemove={() => handleRemoveFile(index)}
                    fullWidth={true}
                  />
                ))}
              </div>
            </ScrollArea>
          )}

          {fileTypeError && (
            <Alert variant="destructive">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertDescription>{fileTypeError}</AlertDescription>
            </Alert>
          )}
        </div>
        <DialogFooter className="mt-4">
          <Button onClick={handleAddClick} disabled={files.length === 0}>
            Add References
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFilesToPortfolioModal;
