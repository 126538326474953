/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { useProjectStore } from "@/store";
import { useApi, useAtBottom, useDocumentTitle } from "@/hooks";
import { UserChatMessage, ChatFooter } from ".";
import { Message } from "@/types/types";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { ArrowDownIcon } from "@radix-ui/react-icons";

interface BaseChatProps {
  generalBool: boolean;
  projectId: string;
  documents: string[];
  disabledBool: boolean;
}

/**
 * @description Base chat component used in project and general chat
 */
const BaseChat: React.FC<BaseChatProps> = ({
  generalBool,
  projectId,
  documents,
  disabledBool,
}) => {
  const { postRequest, postStreamRequest } = useApi();

  // Set page title
  const currentProjectDetails = useProjectStore((state) => state.currentProjectDetails);
  const defaultProjectName = "Chat&AI";
  const projectName = `${currentProjectDetails.name} - Chat&AI` || defaultProjectName;
  useDocumentTitle(projectName);

  // Local state
  const listRef = useRef<HTMLDivElement>(null);
  const isAtBottom = useAtBottom(listRef, 20); // 20 is the offset and can be adjusted
  const [messages, setMessages] = useState<Message[]>([]);
  const [isAIResponding, setIsAIResponding] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    // when selectedDocumentIds change, clear the messages and session id
    setMessages([]);
    setSessionId(null);
  }, [selectedDocumentIds]);

  // Handle stop streaming
  const handleStopStreaming = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsAIResponding(false);
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage.sender === "&AI" && lastMessage.loading) {
          return [
            ...prevMessages.slice(0, -1),
            { ...lastMessage, loading: false, text: lastMessage.text + " [Stopped]" },
          ];
        }
        return prevMessages;
      });
    }
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100);
    return () => clearTimeout(timer);
  }, [messages]);

  // Handle send message
  const handleSendMessage = async (newMessage: string) => {
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    if (newMessage.trim() !== "") {
      setMessages([...messages, { text: newMessage, sender: "user", loading: false }]);

      // Add temporary &AI message with loading state
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "&AI", loading: true, text: "" },
      ]);
      setIsAIResponding(true); // Show loading indicator
      await fetchResponse(newMessage, signal);
    }
  };

  // Handle streaming response
  const handleStreamingResponse = async (response: Response, signal: AbortSignal) => {
    if (response.body) {
      const reader = response.body.getReader();
      let sessionIdReceived = false;
      let tempSessionId: string | null = null;
      let accumulatedText = "";
      let currentStreamingMessage = "";
      while (true) {
        const { done, value } = await reader.read();
        if (signal.aborted) {
          reader.cancel();
          break;
        }
        const textChunk = new TextDecoder().decode(value);
        if (done) {
          // Update the temporary state to reflect the current part of the message
          setIsAIResponding(false); // Hide loading indicator
          setMessages((prevMessages) => {
            // Remove the last message if it was a streaming message
            if (
              prevMessages.length > 0 &&
              prevMessages[prevMessages.length - 1].sender === "&AI"
            ) {
              return [
                ...prevMessages.slice(0, -1),
                {
                  text: currentStreamingMessage,
                  sender: "&AI",
                  loading: false,
                },
              ];
            } else {
              return [
                ...prevMessages,
                {
                  text: currentStreamingMessage,
                  sender: "&AI",
                  loading: false,
                },
              ];
            }
          });

          await commitResponseToSession(currentStreamingMessage, tempSessionId);
          break;
        }

        if (!sessionIdReceived) {
          // Extract session id from the first chunk
          const firstSpaceIndex = textChunk.indexOf(" ");
          if (firstSpaceIndex !== -1) {
            tempSessionId = textChunk.slice(0, firstSpaceIndex);
            setSessionId(tempSessionId);
            accumulatedText = textChunk.slice(firstSpaceIndex + 1);
            sessionIdReceived = true;
          }
        } else {
          accumulatedText += textChunk;
          currentStreamingMessage = accumulatedText;

          // Update the temporary state to reflect the current part of the message
          setMessages((prevMessages) => {
            // Remove the last message if it was a streaming message
            if (
              prevMessages.length > 0 &&
              prevMessages[prevMessages.length - 1].sender === "&AI"
            ) {
              return [
                ...prevMessages.slice(0, -1),
                {
                  text: currentStreamingMessage,
                  sender: "&AI",
                  loading: false,
                },
              ];
            } else {
              return [
                ...prevMessages,
                {
                  text: currentStreamingMessage,
                  sender: "&AI",
                  loading: false,
                },
              ];
            }
          });
        }
      }
    }
  };

  // Fetch response from API
  const fetchResponse = async (message: string, signal: AbortSignal) => {
    try {
      let payload = {};
      if (sessionId) {
        payload = {
          query: message,
          project_id: projectId,
          document_ids: selectedDocumentIds,
          session_id: sessionId,
        };
      } else {
        payload = {
          query: message,
          project_id: projectId,
          document_ids: selectedDocumentIds,
        };
      }

      const response = await postStreamRequest("post_query_chatbot", payload);

      await handleStreamingResponse(response, signal);
    } catch (error: any) {
      if (error.name !== "AbortError") {
        console.error("Error fetching response:", error);
      }
    } finally {
      setIsAIResponding(false);
    }
  };

  // Commit response to session
  const commitResponseToSession = async (
    response: string,
    sessionId: string | null
  ) => {
    if (sessionId) {
      try {
        await postRequest("post_commit_response_to_session", {
          session_id: sessionId,
          response: response,
        });
      } catch (error) {
        console.error("Error committing response to session:", error);
      }
    }
  };

  const [footerHeight, setFooterHeight] = useState(175);

  // Add this new function to update footer height
  const updateFooterHeight = useCallback((height: number) => {
    setFooterHeight(height);
  }, []);

  // Update getScrollAreaHeight function
  const getScrollAreaHeight = () => {
    const headerHeight = 64; // Adjust this value based on your actual header height
    return `calc(100vh - ${headerHeight}px - ${footerHeight}px)`;
  };

  return (
    <div className="flex flex-col h-[calc(100vh-64px)]">
      <div
        className="flex justify-center w-full"
        style={{ height: getScrollAreaHeight() }}
      >
        <ScrollArea className="flex-1 px-3 w-full" ref={listRef} scrollHideDelay={0}>
          <div className="max-w-[1000px] mx-auto px-3 mb-2 break-words">
            {messages.map((message, index) => (
              <UserChatMessage
                key={index}
                message={message}
                index={index}
                isAIResponding={isAIResponding}
                isMostRecent={index === messages.length - 1}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>
      </div>

      {!isAtBottom && (
        <div className="absolute bottom-[160px] right-[60px] z-[1]">
          <Button size="icon" variant="ghost" onClick={scrollToBottom}>
            <ArrowDownIcon />
          </Button>
        </div>
      )}
      <ChatFooter
        handleSendMessage={handleSendMessage}
        disabledBool={disabledBool}
        documents={documents}
        setSelectedDocumentIds={setSelectedDocumentIds}
        general={generalBool}
        id={projectId}
        sessionId={sessionId || ""}
        isAIResponding={isAIResponding}
        handleStopStreaming={handleStopStreaming}
        updateFooterHeight={updateFooterHeight}
      />
    </div>
  );
};

export default BaseChat;
