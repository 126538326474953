/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { splitId } from "@/utils/projectUtils";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";

/**
 * @description Source number button component
 * @param {string} sourceNumber - The source number
 */
interface SourceNumberButtonProps {
  sourceNumber: string;
  prefix?: string;
}

const SourceNumberButton: React.FC<SourceNumberButtonProps> = ({
  sourceNumber,
  prefix = "https://patents.google.com/patent/",
}) => {
  const finalString = /^[A-Za-z]/.test(sourceNumber)
    ? sourceNumber
    : (prefix || "") + sourceNumber;
  return (
    <a
      href={`https://patents.google.com/patent/${finalString}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <div className="flex items-center gap-1 whitespace-nowrap">
        {`${splitId(finalString)}`}
        <OpenInNewWindowIcon className="w-3 h-3" />
      </div>
    </a>
  );
};

export default SourceNumberButton;
