/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useAppStateStore, useProjectStore } from "@/store";
import useVector from "@/hooks/useVector";
import { Button } from "@/components/ui/button";
import { Loader } from "@/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { DataTableCell } from "@/components/ui/table";
import { MagnifyingGlassIcon, PlusIcon } from "@radix-ui/react-icons";
import { ReferenceTableCitation } from "./index";
import { InvalidityCitation, ElementType } from "@/types/types";

interface ReferenceTableCitationCellProps {
  row: { [key: string]: any };
  index: number;
  refId: string;
  isEditingClaim: boolean;
  addingCitationKey: string;
  referenceColumnWidth: string;
  claimNumber: string;
  claimText: string;
  invalidityId: string;
  handleSourceColorUpdate: (
    index: number,
    citationIndex: number,
    color: string,
    referenceId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean
  ) => void;
  handleSourceDelete: (
    itemIndex: number,
    citationIndex: number,
    referenceId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean
  ) => void;
  handleSaveCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    citationTextEdit: string,
    citationParagraphEdit: string,
    isAddingCitation: boolean,
    figureUrls: string[],
    citation: InvalidityCitation,
    invalidityId: string,
    figureRefs: string[]
  ) => void;
  setIsEditing: (isEditing: boolean) => void;
  retrieveInvalidityForItem: (
    rowIndex: number,
    referenceId: string,
    newInvalidity: InvalidityCitation[]
  ) => void;
  handleAddCitation: (index: number, refId: string) => void;
  handleAttachReferenceFiguresToCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrls: string[],
    citation: InvalidityCitation
  ) => void;
  removeImageFromCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrl: string,
    citation: InvalidityCitation
  ) => void;
  handleAddImageToCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    file: File,
    citation: InvalidityCitation
  ) => void;
  citations: InvalidityCitation[];
}

const ReferenceTableCitationCell: React.FC<ReferenceTableCitationCellProps> = ({
  row,
  index,
  refId,
  isEditingClaim,
  addingCitationKey,
  referenceColumnWidth,
  claimNumber,
  claimText,
  invalidityId,
  handleSourceColorUpdate,
  handleSourceDelete,
  handleSaveCitation,
  setIsEditing,
  retrieveInvalidityForItem,
  handleAddCitation,
  handleAttachReferenceFiguresToCitation,
  removeImageFromCitation,
  handleAddImageToCitation,
  citations = [],
}) => {
  const { retrieveInvalidityForClaim } = useVector();
  const { currentProjectId, elementEditIndex, selectedElementType } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  const [isInvalidityLoading, setIsInvalidityLoading] = useState(false);
  const [showNoMoreCitations, setShowNoMoreCitations] = useState(false);

  const retrieveInvalidity = async (index: number, refId: string) => {
    setIsInvalidityLoading(true);
    const response = await retrieveInvalidityForClaim(
      currentProjectId,
      claimNumber,
      refId,
      claimText,
      selectedElementType === ElementType.FEATURE
    );
    if (!response.success && response.status === 404) {
      setShowNoMoreCitations(true);
      setIsInvalidityLoading(false);
      return;
    } else if (!response.success) {
      addErrorMessage(`Error retrieving invalidity for claim ${claimNumber}. `);
      setIsInvalidityLoading(false);
      setIsInvalidityLoading(false);
      return;
    }
    let itemResponse = response.data[refId];
    if (itemResponse && itemResponse.length > 0) {
      retrieveInvalidityForItem(index, refId, itemResponse);
    } else {
      setShowNoMoreCitations(true);
      setIsInvalidityLoading(false);
    }
    setIsInvalidityLoading(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showNoMoreCitations) {
      timer = setTimeout(() => {
        setShowNoMoreCitations(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showNoMoreCitations]);

  return (
    <DataTableCell style={{ width: `${referenceColumnWidth}px` }} className="gap-2">
      {citations.map((citation, citationIndex) => (
        <ReferenceTableCitation
          key={`${index}-${refId}-${citationIndex}`}
          citationKey={`${index}-${refId}-${citationIndex}`}
          citation={citation}
          index={index}
          refId={refId}
          citationIndex={citationIndex}
          invalidityId={invalidityId}
          handleSourceColorUpdate={handleSourceColorUpdate}
          handleSourceDelete={handleSourceDelete}
          handleSaveCitation={handleSaveCitation}
          isEditingClaim={isEditingClaim}
          addingCitationKey={addingCitationKey}
          setIsEditing={setIsEditing}
          handleAttachReferenceFiguresToCitation={
            handleAttachReferenceFiguresToCitation
          }
          removeImageFromCitation={removeImageFromCitation}
          handleAddImageToCitation={handleAddImageToCitation}
        />
      ))}

      {showNoMoreCitations && (
        <div className="mb-5 text-center">
          <p className="text-sm text-muted-foreground">No more citations found</p>
        </div>
      )}

      {(isInvalidityLoading || elementEditIndex === index) && (
        <div className="mb-2">
          <Loader message="Retrieving invalidity..." />
        </div>
      )}

      <div className="flex items-center justify-center space-x-2 ">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                disabled={isEditingClaim || isInvalidityLoading}
                onClick={() => retrieveInvalidity(index, refId)}
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
                <span className="sr-only">Find more citations</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Find more</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                disabled={isEditingClaim || isInvalidityLoading}
                onClick={() => handleAddCitation(index, refId)}
              >
                <PlusIcon className="h-4 w-4" />
                <span className="sr-only">Add citation</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add citation</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </DataTableCell>
  );
};

export default ReferenceTableCitationCell;
