/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import { useAppStateStore, useProjectStore } from "@/store";
import { useVector, useDocumentTitle } from "@/hooks";
import SearchResultsTable from "./components/SearchResultsTable";
import SemanticSearchHeader from "./components/SearchHeader";
import ExploreHeader from "../components/ExploreHeader";
import { SidebarPage, Loader, ErrorMessage } from "@/components";
import { PageContentBox } from "@/styled";

/**
 * @description Renders the explore search page
 */
const ExploreSearchPage = () => {
  const { semanticSearchDocuments } = useVector();
  useDocumentTitle("Explore - Search&AI");

  // Global state from store
  const { searchChatProjectId } = useAppStateStore();
  const referenceIds = useProjectStore(
    (state) => state.currentProjectDetails.referenceIds
  );

  // Local state setup
  const [showError, setShowError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchMode, setSearchMode] = useState("semantic");
  const [sentSearchQuery, setSentSearchQuery] = useState("");
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  const handleDocumentChange = (selectedIds: string[]) => {
    setSelectedDocumentIds(selectedIds);
  };

  // @description Handles the semantic search
  const handleSemanticSearch = async () => {
    setSearchResults(null);
    setShowError(false);

    if (selectedDocumentIds.length === 0) {
      return;
    }

    const trimmedSearchQuery = searchQuery.trim();
    setSentSearchQuery(trimmedSearchQuery);

    if (trimmedSearchQuery !== "" && trimmedSearchQuery !== null) {
      setShowSpinner(true);
      const response = await semanticSearchDocuments(
        searchChatProjectId,
        trimmedSearchQuery,
        selectedDocumentIds,
        searchMode
      );
      if (response.success) {
        setShowSpinner(false);
        setShowError(false);
        setSearchResults(response.data.sources[0]);
      } else {
        setShowError(true);
        setShowSpinner(false);
      }
    }
  };
  return (
    <SidebarPage>
      <ExploreHeader />
      <PageContentBox overflowy="hidden">
        <div className="sticky top-0 bg-background z-10">
          <SemanticSearchHeader
            handleSemanticSearch={handleSemanticSearch}
            searchMode={searchMode}
            setSearchMode={setSearchMode}
            handleDocumentChange={handleDocumentChange}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            general={true}
            disabled={referenceIds.length === 0}
          />
          {showSpinner && <Loader message={"Searching the documents..."} />}
          {showError && <ErrorMessage />}
        </div>
        {searchResults && (
          <SearchResultsTable
            searchResults={searchResults}
            sentSearchQuery={sentSearchQuery}
            general={true}
            searchChatProjectId={searchChatProjectId}
          />
        )}
      </PageContentBox>
    </SidebarPage>
  );
};
export default ExploreSearchPage;
