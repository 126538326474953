/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCellCondensed, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Cross1Icon,
  PlusIcon,
  CheckIcon,
  Pencil1Icon,
  CaretDownIcon,
} from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { ElementType } from "@/types/types";
import { useProjectStore } from "@/store";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Spinner } from "@/components/ui/spinner";

interface FeatureAndClaimEditorProps {
  setItems?: (items: { [key: string]: string }[]) => void;
  initialItems?: { [key: string]: string }[];
  isUpdateMode: boolean;
  startInEditMode: boolean;
  itemType: ElementType;
  onAdd?: (id: string, text: string, index: number) => Promise<void>;
  onEdit?: (oldId: string, newId: string, newText: string) => Promise<void>;
  onDelete?: (id: string, index: number) => Promise<void>;
  useSubjectDetails?: boolean;
}

interface ListItem {
  [key: string]: string;
}

const FeatureClaimEditor: React.FC<FeatureAndClaimEditorProps> = ({
  setItems,
  itemType,
  initialItems,
  startInEditMode,
  isUpdateMode,
  onAdd,
  onEdit,
  onDelete,
  useSubjectDetails = false,
}) => {
  const { currentProjectDetails } = useProjectStore();
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [newText, setNewText] = useState<string>("");
  const [newId, setNewId] = useState<string>("");
  const [originalId, setOriginalId] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [claimInputError, setClaimInputError] = useState<boolean>(false);
  const [claimIdError, setClaimIdError] = useState<boolean>(false);
  const [labelClick, setLabelClick] = useState<boolean>(false);
  const [list, setList] = useState<ListItem[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const updateFeatureIds = (items: ListItem[]): ListItem[] => {
    return items.map((item, index) => {
      const [, text] = Object.entries(item)[0];
      return { [(index + 1).toString()]: text };
    });
  };

  const updateListAndItems = (newList: ListItem[]) => {
    setList(newList);
    if (setItems) {
      setItems(newList);
    }
  };

  useEffect(() => {
    if (initialItems) {
      setList(initialItems);
    }
    if (startInEditMode && initialItems.length === 1 && initialItems[0]["1"] === "") {
      setIsEditing(true);
      setNewId("1");
      setEditIndex(0);
    }
  }, [initialItems]);

  useEffect(() => {
    if (!useSubjectDetails) {
      setList(initialItems);
    } else {
      if (itemType === ElementType.FEATURE) {
        setList(currentProjectDetails.features);
      } else {
        setList(currentProjectDetails.claims);
      }
    }
  }, [itemType]);

  const handleEdit = (id: string, index: number, itemClick: boolean) => {
    if (editIndex !== null && editIndex !== index) {
      handleSave(editIndex);
    }
    setIsEditing(true);
    setOriginalId(id);
    if (index !== editIndex) {
      const item = list[index];
      setLabelClick(itemClick);
      setNewText(item[id]);
      setNewId(id);
      setEditIndex(index);
    }
  };

  const handleSave = async (index: number): Promise<ListItem[]> => {
    const itemArray = [...list];
    let updatedId = newId;

    if (updatedId === "") {
      updatedId = (index + 1).toString();
    }

    const isNumberUnique = !itemArray.some((item, idx) => {
      const claimNumber = Object.keys(item)[0];
      return claimNumber === updatedId && idx !== editIndex;
    });

    if (updatedId === "" || !isNumberUnique) {
      setClaimIdError(true);
      return [];
    }
    setClaimIdError(false);
    if (newText === "") {
      setClaimInputError(true);
      return [];
    }

    if (isUpdateMode) {
      try {
        setIsSaving(true);
        if (isAdding) {
          await onAdd(updatedId, newText, editIndex - 1);
        } else if (isEditing) {
          await onEdit(originalId, updatedId, newText);
        }
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
      }
    }
    setClaimInputError(false);
    const newItem = { [updatedId]: newText };
    itemArray[index] = newItem;
    updateListAndItems(itemArray);
    setEditIndex(null);
    setNewText("");
    setNewId("");
    setIsEditing(false);
    setOriginalId("");
    setIsAdding(false);
    return itemArray;
  };

  const getNextSubclaim = (currentId: string): string => {
    const [mainPart, subPart] = currentId.split("(");
    if (!subPart) return `${mainPart}(a)`;
    const nextLetter = String.fromCharCode(subPart.charCodeAt(0) + 1);
    return `${mainPart}(${nextLetter})`;
  };

  const updateSubclaims = (items: ListItem[], startIndex: number): ListItem[] => {
    const [mainNumber, currentSubclaimPart] = Object.keys(items[startIndex])[0].split("(");
    let currentSubclaim = currentSubclaimPart ? currentSubclaimPart.replace(")", "") : "a";
    return items.map((item, index) => {
      if (index < startIndex) return item;
      const [id, text] = Object.entries(item)[0];
      if (!id.startsWith(mainNumber)) return item;
      if (id.includes("(")) {
        const newId = `${mainNumber}(${currentSubclaim})`;
        currentSubclaim = String.fromCharCode(currentSubclaim.charCodeAt(0) + 1);
        return { [newId]: text };
      }
      return item;
    });
  };

  const updateSubclaimsOnRemoval = (items: ListItem[], removedIndex: number): ListItem[] => {
    const [mainNumber] = Object.keys(items[removedIndex])[0].split("(");
    return items.map((item, index) => {
      if (index < removedIndex) return item;
      const [id, text] = Object.entries(item)[0];
      if (!id.startsWith(mainNumber)) return item;
      if (id.includes("(")) {
        const [, subclaimPart] = id.split("(");
        const currentSubclaim = subclaimPart.replace(")", "");
        const newSubclaim = String.fromCharCode(currentSubclaim.charCodeAt(0) - 1);
        const newId = `${mainNumber}(${newSubclaim})`;
        return { [newId]: text };
      }
      return item;
    });
  };

  const incrementMainClaimsFrom = (
    items: ListItem[],
    startFrom: number
  ): ListItem[] => {
    return items.map((item) => {
      const [id, text] = Object.entries(item)[0];
      const [mainPart, subPart] = id.split("(");
      const currentMainNumber = parseInt(mainPart);

      if (currentMainNumber >= startFrom) {
        const newMainNumber = currentMainNumber + 1;
        const newId = subPart ? `${newMainNumber}(${subPart}` : `${newMainNumber}`;
        return { [newId]: text };
      }
      return item;
    });
  };

  const addFirstEmptyItem = () => {
    setList([{ "1": "" }]);
    setNewText("");
    setNewId("1");
    setEditIndex(0);
    setIsAdding(true);
  };

  const handleAdd = (indexAbove: number, isSubClaim: boolean) => {
    let newId = "";

    setList((prevList) => {
      let itemArray = [...prevList];

      if (itemType === ElementType.CLAIM && !isUpdateMode) {
        if (isSubClaim && indexAbove >= 0) {
          const [prevId] = Object.keys(itemArray[indexAbove]);
          newId = getNextSubclaim(prevId);

          if (
            indexAbove + 1 < itemArray.length &&
            Object.keys(itemArray[indexAbove + 1])[0].startsWith(prevId.split("(")[0])
          ) {
            itemArray.splice(indexAbove + 1, 0, { [newId]: "" });
            return updateSubclaims(itemArray, indexAbove + 1);
          }
        } else {
          const prevMainNumber =
            indexAbove >= 0
              ? parseInt(Object.keys(itemArray[indexAbove])[0].split("(")[0])
              : 0;
          newId = (prevMainNumber + 1).toString();

          itemArray = incrementMainClaimsFrom(itemArray, parseInt(newId));

          itemArray.splice(indexAbove + 1, 0, { [newId]: "" });
          return itemArray;
        }
      } else if (itemType === ElementType.FEATURE && !isUpdateMode) {
        const newIndex = indexAbove + 2;
        newId = newIndex.toString();

        itemArray.splice(indexAbove + 1, 0, { [newId]: "" });
        return updateFeatureIds(itemArray);
      }

      itemArray.splice(indexAbove + 1, 0, { [newId]: "" });
      return itemArray;
    });

    if (setItems) {
      setItems(list);
    }

    setNewText("");
    setEditIndex(indexAbove + 1);
    setIsEditing(true);
    setIsAdding(true);
    setNewId(newId);
  };

  const isMainClaimCompletelyRemoved = (
    list: ListItem[],
    mainNumber: number
  ): boolean => {
    return !list.some((item) => Object.keys(item)[0].startsWith(`${mainNumber}`));
  };

  const decrementMainClaimsFrom = (
    items: ListItem[],
    startFrom: number
  ): ListItem[] => {
    return items.map((item) => {
      const [id, text] = Object.entries(item)[0];
      const [mainPart, subPart] = id.split("(");
      const currentMainNumber = parseInt(mainPart);

      if (currentMainNumber >= startFrom) {
        const newMainNumber = currentMainNumber - 1;
        const newId = subPart ? `${newMainNumber}(${subPart}` : `${newMainNumber}`;
        return { [newId]: text };
      }
      return item;
    });
  };

  const handleRemove = async (id: string, index: number) => {
    if (isUpdateMode) {
      await onDelete(id, index);
    }

    setList((prevList) => {
      let newList = [...prevList];
      newList.splice(index, 1);
  
      if (itemType === ElementType.FEATURE && !isUpdateMode) {
        newList = updateFeatureIds(newList);
      } else if (itemType === ElementType.CLAIM && !isUpdateMode) {
        const [mainPart, subPart] = id.split("(");
        const mainNumber = parseInt(mainPart);
  
        if (subPart) {
          // Check if this was the last subclaim
          const isLastSubclaim = !newList.some(item => 
            Object.keys(item)[0].startsWith(`${mainNumber}(`)
          );
  
          if (isLastSubclaim) {
            newList = decrementMainClaimsFrom(newList, mainNumber);
          } else {
            newList = updateSubclaimsOnRemoval(newList, index);
          }
        } else {
          // Main claim deletion logic remains the same
          const hasRemainingSubclaims = newList.some(item => 
            Object.keys(item)[0].startsWith(`${mainNumber}(`)
          );
          
          if (!hasRemainingSubclaims) {
            newList = decrementMainClaimsFrom(newList, mainNumber + 1);
          }
        }
      }
  
      if (setItems) {
        setItems(newList);
      }
  
      return newList;
    });

    setEditIndex(null);
    setIsEditing(false);
    setIsAdding(false);
    setNewText("");
    setNewId("");
    setOriginalId("");
    setClaimInputError(false);
    setClaimIdError(false);
  };

  const handleAddSubClaim = (indexAbove: number) => {
    handleAdd(indexAbove, true);
  };

  return (
    <>
      {list.length === 0 ? (
        <div className="flex justify-center">
          <Button
            variant="outline"
            className="w-full"
            onClick={() => addFirstEmptyItem()}
          >
            Add {itemType === ElementType.FEATURE ? "Features" : "Claims"}
          </Button>
        </div>
      ) : (
        <Table
        // className={cn(
        //   "border-collapse border border-border",
        //   startInEditMode ? "rounded-lg" : "rounded-b-lg border-t-0"
        // )}
        >
          <TableBody>
            {list.map((item, index) => {
              if (!item) return null;
              const [id, text] = Object.entries(item)[0];
              return (
                <TableRow key={index}>
                  {itemType === ElementType.CLAIM && !isUpdateMode && (
                    <TableCellCondensed>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => handleAddSubClaim(index)}
                            >
                              <CaretDownIcon className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Add sub-claim</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCellCondensed>
                  )}
                  <TableCellCondensed className="w-[15%]">
                    {(isUpdateMode || itemType === ElementType.CLAIM) &&
                    editIndex === index ? (
                      <Input
                        value={newId}
                        onChange={(e) => setNewId(e.target.value)}
                        className={cn("w-full", claimIdError && "border-red-500")}
                        autoFocus={labelClick}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSave(index);
                          }
                        }}
                        disabled={isSaving}
                        multiple
                      />
                    ) : (
                      <span>{id}</span>
                    )}
                  </TableCellCondensed>
                  <TableCellCondensed className="w-[70%]">
                    {editIndex === index ? (
                      <AutosizeTextarea
                        value={newText}
                        onChange={(e) => setNewText(e.target.value)}
                        className={cn("w-full", claimInputError && "border-red-500")}
                        autoFocus={editIndex === index}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSave(index);
                          }
                        }}
                        disabled={isSaving}
                      />
                    ) : (
                      <span>{text}</span>
                    )}
                  </TableCellCondensed>
                  <TableCellCondensed className="w-[15%]">
                    <div className="flex justify-center">
                      {editIndex === index && isUpdateMode && isSaving ? (
                        <Spinner className="h-6 w-6" />
                      ) : (
                        <div className="flex flex-row justify-end gap-2">
                          {editIndex === index ? (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleSave(index)}
                                    disabled={isUpdateMode && isSaving}
                                  >
                                    <CheckIcon className="h-4 w-4" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>Save</TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          ) : (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleEdit(id, index, false)}
                                    disabled={
                                      (isEditing && editIndex !== index) ||
                                      (isUpdateMode && isSaving)
                                    }
                                  >
                                    <Pencil1Icon className="h-4 w-4" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>Edit</TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}

                          {(isUpdateMode || list.length > 1) && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleRemove(id, index)}
                                    disabled={isSaving}
                                  >
                                    <Cross1Icon className="h-4 w-4" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>Remove</TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => handleAdd(index, false)}
                                  disabled={isSaving}
                                >
                                  <PlusIcon className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Add Below</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      )}
                    </div>
                  </TableCellCondensed>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default FeatureClaimEditor;
