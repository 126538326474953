/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore } from "@/store";
import { useViz } from "@/hooks";
import { ElementType } from "@/types/types";
import { FeatureClaimEditor } from "@/features/project/components";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface UpdateFeatureClaimModalProps {
  open: boolean;
  onClose: () => void;
  itemType: ElementType.FEATURE | ElementType.CLAIM;
}

function UpdateFeatureClaimModal({
  open,
  onClose,
  itemType,
}: UpdateFeatureClaimModalProps) {
  const { addClaimOrFeatureToProject, editClaimOrFeature, deleteClaimOrFeature } =
    useViz();
  const { currentProjectId } = useProjectStore();

  const handleAdd = async (id: string, text: string, index: number) => {
    await addClaimOrFeatureToProject(
      currentProjectId,
      id,
      text,
      index,
      itemType === ElementType.FEATURE,
      false // is not in chart
    );
  };

  const handleEdit = async (oldId: string, newId: string, newText: string) => {
    await editClaimOrFeature(
      currentProjectId,
      oldId,
      newId,
      newText,
      itemType === ElementType.FEATURE,
      false // is not in chart
    );
  };

  const handleDelete = async (id: string, index: number) => {
    await deleteClaimOrFeature(
      currentProjectId,
      id,
      itemType === ElementType.FEATURE,
      index,
      false // is not in chart
    );
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col">
        <DialogHeader>
          <DialogTitle>
            Edit {itemType === ElementType.FEATURE ? "features" : "claims"}
          </DialogTitle>
        </DialogHeader>
        <div className="mt-2 overflow-y-auto flex-grow">
          <FeatureClaimEditor
            itemType={itemType}
            isUpdateMode={true}
            startInEditMode={false}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDelete}
            useSubjectDetails={true}
          />
        </div>
        <div className="flex justify-end">
          <Button onClick={onClose} aria-label="Done">
            Done
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateFeatureClaimModal;
