/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useLlm, useViz } from "@/hooks";
import ColorSelect from "./ColorSelect";
import MultiDocumentSelect from "@/components/files/MultiDocumentSelect";
import { ChartColors, ElementType } from "@/types/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { nanoid } from "nanoid";

interface PruneInvalidityColorModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

/**
 * @description Prune invalidity charts by color
 */
const PruneInvalidityColorModal: React.FC<PruneInvalidityColorModalProps> = ({
  open,
  onOpenChange,
}) => {
  const { pruneInvalidityColor } = useLlm();
  const { getProjectMetadata } = useViz();

  const { currentProjectId, selectedElementType } = useProjectStore();
  const { addErrorMessage, addLoadingMessage, removeLoadingMessage } = useAppStateStore();

  const [selectedColors, setSelectedColors] = useState<string[]>([
    ChartColors.YELLOW,
    ChartColors.RED,
  ]);
  const [selectedReferences, setSelectedReferences] = useState<string[]>([]);

  useEffect(() => {
    resetModal();
  }, [open]);

  const resetModal = () => {
    setSelectedColors([ChartColors.YELLOW, ChartColors.RED]);
    setSelectedReferences([]);
  };

  const handleConfirmPrune = async () => {
    const key = nanoid();
    addLoadingMessage("Prune invalidity color...", key);
    onOpenChange(false);

    const pruneResponse = await pruneInvalidityColor(
      currentProjectId,
      selectedReferences,
      selectedColors,
      selectedElementType == ElementType.FEATURE
    );
    if (!pruneResponse.success) {
      addErrorMessage("Failed to prune citations by color.");
      removeLoadingMessage(key);
      return;
    }

    await getProjectMetadata(currentProjectId);
    resetModal();
    removeLoadingMessage(key);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Prune citations by color</DialogTitle>
          <DialogDescription>
            Remove all citations of the selected color from the selected reference(s).
            This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
       
        <div className="flex space-x-2">
          <ColorSelect
            colors={[ChartColors.YELLOW, ChartColors.RED]}
            selectedColors={selectedColors}
            setSelectedColors={setSelectedColors}
          />
          <MultiDocumentSelect handleChange={setSelectedReferences} general={true} />
        </div>
       
        <DialogFooter>
          <div className="flex items-center justify-end gap-2">
            <Button
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              disabled={!selectedColors.length}
              onClick={handleConfirmPrune}
            >
              Prune
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PruneInvalidityColorModal;
