/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { H3, H4, Paragraph } from "@/components/ui/typography";
import { Button } from "@/components/ui/button";

interface PlaceholderBoxProps {
  title: string;
  subtitle?: string;
  link?: string;
  handleLinkClick?: () => void;
  frontPos?: boolean;
}

/**
 * @description Placeholder box component
 */
const PlaceholderBox: React.FC<PlaceholderBoxProps> = ({
  title,
  subtitle,
  link,
  handleLinkClick,
  frontPos,
}) => {
  return (
    <div className="flex flex-col items-center justify-center mt-4">
      <H3>{title}</H3>
      {link && frontPos && (
        <H4>
          <Button onClick={handleLinkClick}>{link}</Button>
          {subtitle}
        </H4>
      )}
      {link && !frontPos && (
        <Paragraph>
          {subtitle}
          <Button onClick={handleLinkClick}>{link}</Button>
        </Paragraph>
      )}

      {!link && <Paragraph>{subtitle}</Paragraph>}
    </div>
  );
};

export default PlaceholderBox;
