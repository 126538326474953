/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import he from "he";
import React from "react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import SubjectAbstractImages from "./SubjectAbstractImages";
import { DocumentMetadata } from "@/features/project/documents/components";
import { Patent, ElementType } from "@/types/types";
import { splitId } from "@/utils/projectUtils";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import PatentBody from "./PatentBody";
import { H3, H4 } from "@/components/ui/typography";
import { FeatureClaimList } from "@/features/project/documents/components";
import { cn } from "@/lib/utils";
import { useProjectStore } from "@/store";

interface SubjectPatentLayoutProps {
  patent: Patent | null;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement>;
}

interface PatentNumberDisplayProps {
  finalIdString: string;
}

const PatentNumberDisplay: React.FC<PatentNumberDisplayProps> = ({ finalIdString }) => {
  return (
    <div className="inline-flex items-center gap-2 bg-primary rounded-lg p-2 pl-4 whitespace-nowrap">
      <H4 className="text-white">{splitId(finalIdString)}</H4>
      <Button
        variant="ghost"
        size="icon"
        asChild
        className="text-white hover:bg-primary-foreground/10 flex-shrink-0"
      >
        <a
          href={`https://patents.google.com/patent/${finalIdString}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExternalLinkIcon className="h-4 w-4" />
        </a>
      </Button>
    </div>
  );
};


const SubjectPatentLayout: React.FC<SubjectPatentLayoutProps> = ({
  patent,
  citationText,
  highlightedRef,
}) => {
  if (!patent) return null;

  const { currentProjectDetails } = useProjectStore();

  const finalIdString = /^[A-Za-z]/.test(patent.number)
    ? patent.number
    : (patent.prefix || "") + patent.number;

   
  return (
    <>
    {/* Large screen layout */}
    <div className="hidden lg:flex lg:h-[calc(100vh-55px)]">
      <div
        className={cn("w-[50%] overflow-y-auto overflow-x-hidden hide-scrollbar p-3")}
      >
    
        <H4 className="mb-4 ">{he.decode(patent.name)}</H4>
        <SubjectAbstractImages details={patent} />
        <PatentBody
          body={patent.body?.map((item) => item.text) || patent.fullBody}
          citationText={citationText}
        />
      </div>

      <Separator orientation="vertical" className="h-auto" />

      <div
        className={cn("w-[50%] overflow-y-auto overflow-x-hidden p-3 hide-scrollbar")}
      >
        <div className="flex justify-end mb-2">
        <PatentNumberDisplay finalIdString={finalIdString} />
        </div>
      
        <DocumentMetadata
          details={patent}
          showHead={true}
          subjectPage={true}
          projectPriorityDate={currentProjectDetails.priorityDate}
        />

        {/* <ContextList startCollapsed={true} /> */}
        <FeatureClaimList
          list={currentProjectDetails.features}
          itemType={ElementType.FEATURE}
          startCollapsed={false}
          editingBool={true}
        />

        <FeatureClaimList
          list={currentProjectDetails.claims}
          itemType={ElementType.CLAIM}
          startCollapsed={false}
          editingBool={true}
        />
      </div>
      </div>
      <div className="lg:hidden h-[calc(100vh-55px)] overflow-y-auto">
        <div className="p-3">
        <div className="flex justify-between mb-2">
        <H4 className="mb-4">{he.decode(patent.name)}</H4>
        <PatentNumberDisplay finalIdString={finalIdString} />
        </div>
         
          <DocumentMetadata
            details={patent}
            showHead={true}
            subjectPage={true}
            projectPriorityDate={currentProjectDetails.priorityDate}
          />
         <div className="mt-4">
          <SubjectAbstractImages details={patent} />
          </div>

          <FeatureClaimList
          list={currentProjectDetails.features}
          itemType={ElementType.FEATURE}
          startCollapsed={false}
          editingBool={true}
        />

        <FeatureClaimList
          list={currentProjectDetails.claims}
          itemType={ElementType.CLAIM}
          startCollapsed={false}
          editingBool={true}
        />
        
        <div className="mt-4">
        <PatentBody
          body={patent.body?.map((item) => item.text) || patent.fullBody}
            citationText={citationText}
          />
            </div>
        </div>
      </div>
    </>
  );
};

export default SubjectPatentLayout;
