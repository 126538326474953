/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, MouseEvent, useEffect } from "react";
import { DataTableRowOutline, DataTableCell } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { CheckIcon } from "@radix-ui/react-icons";
import { InvalidityCitation, ElementType } from "@/types/types";
import { useProjectStore, useAppStateStore } from "@/store";
import useViz from "@/hooks/useViz";
import { ReferenceTableCitationCell } from "./index";
import { Pencil1Icon, PlusIcon, ScissorsIcon, TrashIcon } from "@radix-ui/react-icons";

// Define the types for the props
interface DataTableRowProps {
  row: Record<string, any>;
  rowIndex: number;
  setClaimEditIndex: (index: number | null) => void;
  claimEditIndex: number | null;
  editBooleanArray: boolean[];
  updateEditBooleanArray: (index: number, value: boolean) => void;
  setEditBooleanArray: (array: boolean[]) => void;
  handleSourceDelete: (
    itemIndex: number,
    citationIndex: number,
    referenceId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean
  ) => void;
  handleSourceColorUpdate: (
    index: number,
    citationIndex: number,
    color: string,
    referenceId: string,
    citation: InvalidityCitation,
    isAddingCitation: boolean
  ) => void;
  referenceColumnWidth: string;
  handleSaveCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    citationTextEdit: string,
    citationParagraphEdit: string,
    isAddingCitation: boolean,
    figureUrls: string[],
    citation: InvalidityCitation,
    invalidityId: string,
    figureRefs: string[]
  ) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  addingCitationKey: string;
  retrieveInvalidityForItem: (
    rowIndex: number,
    referenceId: string,
    newInvalidity: InvalidityCitation[]
  ) => void;
  handleAddCitation: (index: number, referenceId: string) => void;
  handleAttachReferenceFiguresToCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrls: string[],
    citation: InvalidityCitation
  ) => void;
  removeImageFromCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    figureUrl: string,
    citation: InvalidityCitation
  ) => void;
  handleAddImageToCitation: (
    index: number,
    referenceId: string,
    citationIndex: number,
    file: File,
    citation: InvalidityCitation
  ) => void;
  claimIsAdding: boolean;
  setClaimIsAdding: (value: boolean) => void;
}

const DataTableRow: React.FC<DataTableRowProps> = ({
  row,
  rowIndex,
  setClaimEditIndex,
  claimEditIndex,
  editBooleanArray,
  updateEditBooleanArray,
  setEditBooleanArray,
  handleSourceDelete,
  handleSourceColorUpdate,
  referenceColumnWidth,
  handleSaveCitation,
  isEditing,
  setIsEditing,
  addingCitationKey,
  retrieveInvalidityForItem,
  handleAddCitation,
  handleAttachReferenceFiguresToCitation,
  removeImageFromCitation,
  handleAddImageToCitation,
  claimIsAdding,
  setClaimIsAdding,
}) => {
  const {
    splitClaimOrFeature,
    editClaimOrFeature,
    addClaimOrFeatureToProject,
    deleteClaimOrFeature,
    getReferenceChartData,
  } = useViz();

  const {
    currentProjectId,
    chartData,
    summaryChartData,
    updateChartData,
    updateSummaryChartData,
    currentProjectDetails,
    selectedReferenceDetails,
    updateElementEditIndex,
    selectedElementType,
    selectedElements,
    selectedColors,
  } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const referenceIds = useProjectStore(
    (state) => state.currentProjectDetails.referenceIds
  );

  const [claimEdit, setClaimEdit] = useState<string>("");
  const [claimNumberEdit, setClaimNumberEdit] = useState<string>("");
  const [claimEditError, setClaimEditError] = useState<boolean>(false);
  const [claimNumberEditError, setClaimNumberEditError] = useState<boolean>(false);
  const [claimNumberEditErrorMessage, setClaimNumberEditErrorMessage] =
    useState<string>("");
  const [originalClaimValue, setOriginalClaimValue] = useState<Record<string, string>>(
    {}
  );
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number | null;
    mouseY: number | null;
    cellType: string | null;
    showSplitText: boolean;
  }>({
    mouseX: null,
    mouseY: null,
    cellType: null,
    showSplitText: false,
  });

  const handleRightClick = (event: MouseEvent, rowIndex: number) => {
    event.preventDefault();

    let currentText = "";
    let selectedText = "";

    selectedText = window.getSelection()?.toString() || "";

    currentText = chartData[rowIndex].claim_text;
    setClaimEditIndex(rowIndex);
    setIsContextMenuOpen(true);
    setContextMenu({
      mouseX: event.pageX, // Change this line
      mouseY: event.pageY, // Change this line
      cellType: "claim",
      showSplitText: selectedText && currentText.endsWith(selectedText) ? true : false,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contextMenu.mouseX !== null && contextMenu.mouseY !== null) {
        const contextMenuElement = document.querySelector(".context-menu");
        if (contextMenuElement && !contextMenuElement.contains(event.target as Node)) {
          handleCloseContextMenu();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside as any);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside as any);
    };
  }, [contextMenu]);

  const handleCloseContextMenu = () => {
    setContextMenu({
      mouseX: null,
      mouseY: null,
      cellType: null,
      showSplitText: false,
    });
    setIsContextMenuOpen(false);
  };

  const updateClaimState = (
    text: string,
    num: string,
    textError: boolean,
    numError: boolean,
    editIdx: number | null
  ) => {
    setClaimEdit(text);
    setClaimNumberEdit(num);
    setClaimEditError(textError);
    setClaimNumberEditError(numError);
    setClaimEditIndex(editIdx);
  };

  // Sets claim editing state
  const handleEditClaim = () => {
    const originalText =
      chartData && claimEditIndex !== null && claimEditIndex < chartData.length
        ? chartData[claimEditIndex].claim_text
        : "";
    const originalNumber =
      chartData && claimEditIndex !== null && claimEditIndex < chartData.length
        ? chartData[claimEditIndex].claim_number
        : "";
    updateClaimState(originalText, originalNumber, false, false, claimEditIndex);
    // Set original values to enable undo
    setOriginalClaimValue({
      id: originalNumber,
      text: originalText,
    });
    updateEditBooleanArray(claimEditIndex!, true);
    handleCloseContextMenu();
    setIsEditing(true);
  };

  // Adds empty element row at next index
  const handleAddRowAtIndex = () => {
    handleCloseContextMenu();
    setIsEditing(true);
    setClaimIsAdding(true);

    const newIndex = claimEditIndex! + 1;
    const newRow: Record<string, any> = {
      claim_number: "",
      claim_text: "",
    };
    for (const ref in selectedReferenceDetails) {
      newRow[selectedReferenceDetails[ref].id] = [];
    }
    let newChartData = [...chartData];
    newChartData.splice(newIndex, 0, newRow);
    updateChartData(newChartData);
    updateEditBooleanArray(newIndex, true);
    updateClaimState("", "", false, false, newIndex);
  };

  // Saves claim after adding/editing
  const handleSaveClaimEdit = async () => {
    if (claimEdit === "" && claimNumberEdit === "") {
      setClaimEditError(true);
      setClaimNumberEditError(true);
      setClaimNumberEditErrorMessage("Claim number cannot be empty.");
      return;
    } else if (claimNumberEdit === "") {
      setClaimNumberEditError(true);
      setClaimEditError(false);
      setClaimNumberEditErrorMessage("Claim number cannot be empty.");
      return;
    } else if (claimEdit === "") {
      setClaimEditError(true);
      setClaimNumberEditError(false);
      return;
    }

    // Get previous claim number
    const prevClaimNumber = chartData[claimEditIndex!].claim_number;

    // Ensure label is unique and new, if not return with error
    const isClaimNumberUnique = chartData.every(
      (row) =>
        row.claim_number !== claimNumberEdit || row.claim_number === prevClaimNumber
    );
    if (!isClaimNumberUnique) {
      setClaimNumberEditError(true);
      setClaimEditError(false);
      setClaimNumberEditErrorMessage("Claim number must be unique.");
      return;
    }

    // if (
    //   claimNumberEdit === originalClaimValue.id ||
    //   claimEdit === originalClaimValue.text
    // ) {
    //   setClaimNumberEditError(false);
    //   setClaimEditError(false);
    //   updateElementEditIndex(-1);

    //   return;
    // }

    setIsEditing(false);

    // If adding new claim, add to summary chart data
    if (claimIsAdding) {
      addItemSummaryUpdate(true, claimNumberEdit);
    } else if (claimNumberEdit !== originalClaimValue.id) {
      // If editing claim, update summary chart data
      const newSummaryChartData = [...summaryChartData];
      newSummaryChartData[claimEditIndex!].claim_number = claimNumberEdit;
      updateSummaryChartData(newSummaryChartData);
    }

    // Update chart data
    let newChartData = [...chartData];
    newChartData = chartData.map((row, rowIndex) => {
      if (rowIndex === claimEditIndex) {
        const newRow = { ...row };
        newRow.claim_number = claimNumberEdit;
        newRow.claim_text = claimEdit;
        return newRow;
      }
      return row;
    });

    // Update edit boolean array and chart data
    updateEditBooleanArray(claimEditIndex!, false);
    updateChartData(newChartData);

    // Add or edit claim or feature to project

    const is_feature = selectedElementType === ElementType.FEATURE;
    updateElementEditIndex(rowIndex);

    if (claimIsAdding) {
      const index = rowIndex - 1;
      const addResponse = await addClaimOrFeatureToProject(
        currentProjectId,
        claimNumberEdit,
        claimEdit,
        index,
        is_feature,
        true // is in chart
      );
      if (!addResponse.success) {
        addErrorMessage(
          `Error adding claim ${claimNumberEdit} to project ${currentProjectDetails.name} `
        );
      }

      setClaimIsAdding(false);
    } else {
      const editResponse = await editClaimOrFeature(
        currentProjectId,
        originalClaimValue.id,
        claimNumberEdit,
        claimEdit,
        is_feature,
        true // is in chart
      );
      if (!editResponse.success) {
        addErrorMessage(
          `Error editing claim ${claimNumberEdit} in project ${currentProjectDetails.name} `
        );
      }
    }

    const refIds = selectedReferenceDetails.map((ref) => ref.id);
    await getReferenceChartData(
      currentProjectId,
      refIds,
      selectedElementType === ElementType.FEATURE,
      selectedElements,
      selectedColors
    );
    updateElementEditIndex(-1);

    updateClaimState("", "", false, false, null);
  };

  // Remove element
  const handleRemoveItem = async () => {
    handleCloseContextMenu();
    const claimNum = chartData[claimEditIndex!].claim_number;

    setIsEditing(false);
    const response = await deleteClaimOrFeature(
      currentProjectId,
      claimNum,
      selectedElementType === ElementType.FEATURE,
      claimEditIndex,
      true // is in chart
    );
    if (!response.success) {
      addErrorMessage(
        `Error deleting claim ${claimNum} in project ${currentProjectDetails.name} `
      );
      return;
    }

    const newEditBooleanArray = editBooleanArray.filter(
      (_, index) => index !== claimEditIndex
    );
    setEditBooleanArray(newEditBooleanArray);
    updateClaimState("", "", false, false, null);
  };

  // Split element text
  const handleSplitText = async () => {
    const claimNumber = chartData[rowIndex].claim_number;

    if (rowIndex !== null) {
      const selectedText = window.getSelection()?.toString() || "";
      const currentText = chartData[claimEditIndex!].claim_text;

      const currentTextCopy = currentText;
      const remainingText = currentTextCopy.replace(selectedText, "");

      const originalCitations: Record<string, any[]> = {};
      for (const ref in selectedReferenceDetails) {
        const id = selectedReferenceDetails[ref].id;
        originalCitations[id] = chartData[claimEditIndex!][id];
      }

      let newNumbers: string[];
      let newTexts: string[];
      const newChartData = [...chartData];

      // Split text
      if (currentText.startsWith(selectedText)) {
        const remainingItem: Record<string, any> = {
          claim_number: claimNumber + "split",
          claim_text: remainingText.trim(),
        };
        const selectedItem: Record<string, any> = {
          claim_number: claimNumber,
          claim_text: selectedText.trim(),
        };

        for (const ref in selectedReferenceDetails) {
          const id = selectedReferenceDetails[ref].id;
          remainingItem[id] = originalCitations[id];
          selectedItem[id] = originalCitations[id];
        }

        newChartData.splice(rowIndex + 1, 0, remainingItem);
        newChartData[rowIndex] = selectedItem;
        newTexts = [selectedText, remainingText];
        newNumbers = [claimNumber + "-split", claimNumber];
      } else {
        const remainingItem: Record<string, any> = {
          claim_number: claimNumber,
          claim_text: remainingText.trim(),
        };
        const selectedItem: Record<string, any> = {
          claim_number: claimNumber + "-split",
          claim_text: selectedText.trim(),
        };

        for (const ref in selectedReferenceDetails) {
          const id = selectedReferenceDetails[ref].id;
          remainingItem[id] = originalCitations[id];
          selectedItem[id] = originalCitations[id];
        }
        newChartData[rowIndex] = remainingItem;
        newChartData.splice(rowIndex + 1, 0, selectedItem);
        newTexts = [remainingText, selectedText];
        newNumbers = [claimNumber, claimNumber + "-split"];
      }
      addItemSummaryUpdate(false, claimNumber + "-split");
      updateChartData(newChartData);
      updateEditBooleanArray(rowIndex, false);
      updateClaimState("", "", false, false, null);
      handleCloseContextMenu();

      const splitResponse = await splitClaimOrFeature(
        currentProjectId,
        claimNumber,
        newNumbers,
        newTexts,
        selectedElementType === ElementType.FEATURE,
        true // is in chart
      );
      if (!splitResponse.success) {
        addErrorMessage(
          `Error splitting claim ${claimNumber} in project ${currentProjectDetails.name} `
        );
      }
    }
  };

  // Adds new claim or feature to summary chart data
  const addItemSummaryUpdate = (isAdding: boolean, newLabel: string) => {
    const newSummaryChartData = [...summaryChartData];
    const newRow: Record<string, string> = {
      claim_number: newLabel,
    };
    if (isAdding) {
      for (const i in referenceIds) {
        newRow[referenceIds[i]] = "#D3D3D3";
      }
    } else {
      const currentRow = newSummaryChartData[rowIndex];
      for (const i in referenceIds) {
        newRow[referenceIds[i]] = currentRow[referenceIds[i]];
      }
    }
    newSummaryChartData.splice(rowIndex + 1, 0, newRow);
    updateSummaryChartData(newSummaryChartData);
  };

  return (
    <>
      <DataTableRowOutline
        key={rowIndex}
        className={isContextMenuOpen && claimEditIndex === rowIndex ? "bg-muted" : ""}
        style={{ position: "relative" }}
      >
        <DataTableCell
          key={`${rowIndex}-claim_number`}
          onContextMenu={(e) => handleRightClick(e, rowIndex)}
        >
          {editBooleanArray[rowIndex] ? (
            <div>
              <Input
                value={claimNumberEdit}
                onChange={(e) => setClaimNumberEdit(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSaveClaimEdit();
                  }
                }}
                className={claimNumberEditError ? "border-destructive" : ""}
              />
              {claimNumberEditError && (
                <p className="text-sm text-destructive mt-1">
                  {claimNumberEditErrorMessage}
                </p>
              )}
            </div>
          ) : (
            <span>{row.claim_number}</span>
          )}
        </DataTableCell>
        <DataTableCell
          key={`${rowIndex}-claim_text`}
          onContextMenu={(e) => handleRightClick(e, rowIndex)}
          className="align-top"
        >
          {editBooleanArray[rowIndex] ? (
            <div>
              <AutosizeTextarea
                value={claimEdit}
                onChange={(e) => setClaimEdit(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSaveClaimEdit();
                  }
                }}
                className={claimEditError ? "border-destructive" : ""}
              />
              {claimEditError && (
                <p className="text-sm text-destructive mt-1">
                  This field cannot be empty
                </p>
              )}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="default"
                      size="icon"
                      onClick={() => handleSaveClaimEdit()}
                      className="m-1"
                    >
                      <CheckIcon className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Save</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          ) : (
            <span>{row.claim_text}</span>
          )}
        </DataTableCell>

        {Object.keys(row)
          .filter(
            (key) =>
              key !== "claim_number" && key !== "claim_text" && key !== "invalidity_id"
          )
          .map((refId) => (
            <ReferenceTableCitationCell
              key={`${rowIndex}-${refId}`}
              referenceColumnWidth={referenceColumnWidth}
              row={row}
              index={rowIndex}
              refId={refId}
              invalidityId={row[refId].invalidity_id}
              citations={row[refId].citations}
              handleSourceColorUpdate={handleSourceColorUpdate}
              handleSourceDelete={handleSourceDelete}
              handleSaveCitation={handleSaveCitation}
              isEditingClaim={isEditing}
              addingCitationKey={addingCitationKey}
              setIsEditing={setIsEditing}
              retrieveInvalidityForItem={retrieveInvalidityForItem}
              handleAddCitation={handleAddCitation}
              claimNumber={row.claim_number}
              claimText={row.claim_text}
              handleAttachReferenceFiguresToCitation={
                handleAttachReferenceFiguresToCitation
              }
              removeImageFromCitation={removeImageFromCitation}
              handleAddImageToCitation={handleAddImageToCitation}
            />
          ))}
      </DataTableRowOutline>

      {contextMenu.mouseX && contextMenu.mouseY && (
        <div
          style={{
            position: "fixed",
            top: `${contextMenu.mouseY}px`,
            left: `${contextMenu.mouseX}px`,
            zIndex: 1000,
          }}
          className="flex flex-col bg-white shadow-md rounded-md p-2 context-menu"
        >
          {contextMenu.cellType === "claim" &&
            selectedElementType === ElementType.CLAIM && (
              <>
                {!isEditing && (
                  <>
                    <Button
                      variant="ghost"
                      onClick={handleEditClaim}
                      className="justify-start w-full"
                    >
                      <Pencil1Icon className="mr-2 h-4 w-4" /> Edit Claim
                    </Button>
                    {contextMenu.showSplitText && (
                      <Button
                        variant="ghost"
                        onClick={handleSplitText}
                        className="justify-start w-full"
                      >
                        <ScissorsIcon className="mr-2 h-4 w-4" /> Split Claim
                      </Button>
                    )}
                    <Button
                      variant="ghost"
                      onClick={handleAddRowAtIndex}
                      className="justify-start w-full"
                    >
                      <PlusIcon className="mr-2 h-4 w-4" /> Add Claim Below
                    </Button>
                  </>
                )}
                <Button
                  variant="ghost"
                  onClick={handleRemoveItem}
                  className="justify-start w-full"
                >
                  <TrashIcon className="mr-2 h-4 w-4" /> Delete Claim
                </Button>
              </>
            )}

          {contextMenu.cellType === "claim" &&
            selectedElementType === ElementType.FEATURE && (
              <>
                {!isEditing && (
                  <>
                    <Button
                      variant="ghost"
                      onClick={handleEditClaim}
                      className="justify-start w-full"
                    >
                      <Pencil1Icon className="mr-2 h-4 w-4" /> Edit Feature
                    </Button>
                    {contextMenu.showSplitText && (
                      <Button
                        variant="ghost"
                        onClick={handleSplitText}
                        className="justify-start w-full"
                      >
                        <ScissorsIcon className="mr-2 h-4 w-4" /> Split Feature
                      </Button>
                    )}
                    <Button
                      variant="ghost"
                      onClick={handleAddRowAtIndex}
                      className="justify-start w-full"
                    >
                      <PlusIcon className="mr-2 h-4 w-4" /> Add Feature Below
                    </Button>
                  </>
                )}
                <Button
                  variant="ghost"
                  onClick={handleRemoveItem}
                  className="justify-start w-full"
                >
                  <TrashIcon className="mr-2 h-4 w-4" /> Delete Feature
                </Button>
              </>
            )}
        </div>
      )}
    </>
  );
};

export default DataTableRow;
