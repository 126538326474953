/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SourceNumberButton } from "@/components";
import { Patent } from "@/types/types";

interface DocumentsOnCreateProps {
  details: Patent[];
}

/**
 * @description Document metadata table
 */
const DocumentsOnCreate: React.FC<DocumentsOnCreateProps> = ({ details }) => {
  const formatInventorName = (name: string) => {
    if (Array.isArray(name)) {
      return name.map((n) => formatInventorName(n)).join(", ");
    }
    if (name && name.includes(",") && name === name.toUpperCase()) {
      const [lastName, firstName] = name.split(",").map((part) => part.trim());
      return `${firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()} ${lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()}`;
    }
    return name;
  };

  return (
    <div className="border border-border rounded-lg">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Number</TableHead>
            <TableHead>Title</TableHead>
            <TableHead>Publication</TableHead>
            <TableHead>Priority</TableHead>
            <TableHead>Inventors</TableHead>
            <TableHead>Assignee</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {details.map((detail) => (
            <TableRow key={detail.id}>
              <TableCell className="whitespace-nowrap">
                <SourceNumberButton
                  sourceNumber={detail.number}
                  prefix={detail.prefix || ""}
                />
              </TableCell>
              <TableCell>{detail.name}</TableCell>
              <TableCell className="whitespace-nowrap">
                {new Date(
                  detail.publicationDate || detail.publication_date
                ).toLocaleDateString() || "—"}
              </TableCell>
              <TableCell className="whitespace-nowrap">
                {new Date(
                  detail.filingDate || detail.filing_date
                ).toLocaleDateString() || "—"}
              </TableCell>
              <TableCell>
                {detail.inventors ? formatInventorName(detail.inventors) : "—"}
              </TableCell>
              <TableCell>{detail.assignee || "—"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DocumentsOnCreate;
