/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import useApi from "./useApi";
import { useProjectStore } from "../store";
import { ApiResponse, Patent, PatentContext } from "../types/types";

interface GenerateKeywordsPayload {
  project_id: string;
  subject_id?: string;
  features: any[];
  set_keywords: string;
}

interface SearchPriorArtPayload {
  project_id: string;
  keywords?: any[];
  [key: string]: any;
}

interface GenerateFeaturesPayload {
  subject_id: string;
  project_id?: string;
}

interface GenerateFeaturesFromClaimsPayload {
  claims: any[];
}

interface GeneratePatentContextPayload {
  project_id: string;
  document_id?: string;
  text?: string;
}

interface SavePatentContextPayload {
  project_id: string;
  context: PatentContext;
}

interface PruneReferencesPayload {
  project_id: string;
  num_references?: number;
  strict?: boolean;
}

interface PruneInvalidityColorPayload {
  project_id: string;
  document_ids: string[];
  colors: string[];
  is_feature: boolean;
}

interface PruneInvalidityScorePayload {
  project_id: string;
  document_ids: string[];
  score: number;
  is_feature: boolean;
}

interface ExplainCitationsPayload {
  claim: string;
  citations: any[];
}

interface GetElementStrengthsPayload {
  project_id: string;
}

const useLlm = () => {
  const { postRequest, getRequest, handleError } = useApi();

  const { currentProjectDetails, updateCurrentProjectDetails } = useProjectStore();

  const generateKeywords = async (
    project_id: string,
    subject_id: string,
    features: any[],
    set_keywords: string
  ): Promise<ApiResponse<string[]>> => {
    try {
      const payload: GenerateKeywordsPayload = {
        project_id,
        subject_id,
        features,
        set_keywords,
      };
      const response = await postRequest("post_generate_keywords", payload);
      const keywords = response.data.keywords;
      if (set_keywords === "True") {
        updateCurrentProjectDetails({ ...currentProjectDetails, keywords });
      }

      return { success: true, data: keywords };
    } catch (error) {
      return handleError(error, "Error generating keywords");
    }
  };

  const searchPriorArt = async (
    project_id: string,
    keywords: any[],
    options: { [key: string]: any } = {}
  ): Promise<ApiResponse<Patent[]>> => {
    let payload: SearchPriorArtPayload = { project_id, ...options };
    if (keywords.length > 0) {
      payload.keywords = keywords;
    }

    try {
      const response = await postRequest("post_search_for_prior_art", payload);
      const priorArt = response.data.patents;
      return { success: true, data: priorArt };
    } catch (error) {
      console.error("Error searching for prior art:", error);

      return handleError(error, "Error searching for prior art");
    }
  };

  const generateFeatures = async (
    subject_id: string,
    project_id?: string
  ): Promise<ApiResponse<{ features: any[] }>> => {
    try {
      let payload: GenerateFeaturesPayload;
      if (project_id) {
        payload = { subject_id, project_id };
      } else {
        payload = { subject_id };
      }
      const response = await postRequest("post_generate_feature_list", payload);
      const features = response.data.features;
      return { success: true, data: features };
    } catch (error) {
      return handleError(error, "Error generating features");
    }
  };

  const generateContext = async (
    subject_id: string,
    project_id?: string
  ): Promise<ApiResponse<{ features: any[] }>> => {
    try {
      let payload: GenerateFeaturesPayload;
      payload = { subject_id };
      const response = await postRequest("post_generate_context_list", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error generating features");
    }
  };

  const generatePatentContextFromId = async (
    document_id?: string
  ): Promise<ApiResponse<PatentContext>> => {
    try {
      const payload: GeneratePatentContextPayload = {
        project_id: currentProjectDetails.id,
      };
      if (document_id) {
        payload.document_id = document_id;
      } else {
        throw new Error("Document ID must be provided");
      }
      const response = await postRequest("post_generate_patent_context", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error generating patent context");
    }
  };

  const generatePatentContextFromText = async (
    text?: string
  ): Promise<ApiResponse<PatentContext>> => {
    try {
      const payload: GeneratePatentContextPayload = {
        project_id: currentProjectDetails.id,
      };
      if (text) {
        payload.text = text;
      } else {
        throw new Error("Text must be provided");
      }
      const response = await postRequest("post_generate_patent_context", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error generating patent context");
    }
  };

  const getPatentContext = async (
    project_id: string
  ): Promise<ApiResponse<PatentContext>> => {
    try {
      const response = await getRequest("get_patent_context", { project_id });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting patent context");
    }
  };

  const savePatentContext = async (
    project_id: string,
    context: PatentContext
  ): Promise<ApiResponse<void>> => {
    try {
      const payload: SavePatentContextPayload = { project_id, context };
      await postRequest("post_save_patent_context", payload);
      return { success: true };
    } catch (error) {
      return handleError(error, "Error saving patent context");
    }
  };

  const generateFeaturesFromClaims = async (
    claims: any[]
  ): Promise<ApiResponse<{ features: any[] }>> => {
    try {
      const payload: GenerateFeaturesFromClaimsPayload = { claims };
      const response = await postRequest(
        "post_generate_feature_list_from_claims",
        payload
      );
      const features = response.data.features;
      return { success: true, data: features };
    } catch (error) {
      return handleError(error, "Error generating features from claims");
    }
  };

  const pruneReferences = async (
    project_id: string,
    num_references?: number,
    strict?: boolean
  ): Promise<ApiResponse<Patent[]>> => {
    try {
      const payload: PruneReferencesPayload = { project_id, num_references, strict };
      const response = await getRequest("get_references_to_prune", payload);
      return { success: true, data: response.data.to_remove };
    } catch (error) {
      return handleError(error, "Error pruning references");
    }
  };

  const pruneInvalidityColor = async (
    project_id: string,
    document_ids: string[],
    colors: string[],
    is_feature: boolean
  ): Promise<ApiResponse<{ features: any[] }>> => {
    try {
      const payload: PruneInvalidityColorPayload = {
        project_id,
        document_ids,
        colors,
        is_feature,
      };
      await postRequest("post_prune_invalidity_color", payload);
      return { success: true };
    } catch (error) {
      return handleError(error, "Error pruning invalidities");
    }
  };

  const pruneInvalidityScore = async (
    project_id: string,
    document_ids: string[],
    score: number,
    is_feature: boolean
  ): Promise<ApiResponse<{ features: any[] }>> => {
    try {
      const payload: PruneInvalidityScorePayload = {
        project_id,
        document_ids,
        score,
        is_feature,
      };
      await postRequest("post_prune_invalidity_score", payload);
      return { success: true };
    } catch (error) {
      return handleError(error, "Error pruning invalidities");
    }
  };

  const explainCitations = async (
    claim: string,
    citations: any[]
  ): Promise<ApiResponse<{ explanation: any }>> => {
    try {
      const payload: ExplainCitationsPayload = { claim, citations };
      const response = await postRequest("post_explain_citations", payload);
      return { success: true, data: response.data.explanation };
    } catch (error) {
      return handleError(error, "Error explaining citations");
    }
  };

  const getElementStrengths = async (
    project_id: string
  ): Promise<
    ApiResponse<{
      claims: { weak: any[]; strong: any[] };
      features: { weak: any[]; strong: any[] };
    }>
  > => {
    try {
      const payload: GetElementStrengthsPayload = { project_id };
      const response = await getRequest("get_element_strengths", payload);
      return { success: true, data: response.data.element_strengths };
    } catch (error) {
      return handleError(error, "Error getting element strengths");
    }
  };

  const getAssigneePortfolio = async (
    assignee: string,
    options: { [key: string]: any } = {}
  ): Promise<ApiResponse<Patent[]>> => {
    let payload = { assignee, ...options };

    try {
      const response = await postRequest("get_assignee_portfolio", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting assignee portfolio");
    }
  };

  const generatePatentFromDisclosure = async (
    disclosure: string
  ): Promise<ApiResponse<Patent>> => {
    try {
      const response = await postRequest(
        "post_generate_patent_from_disclosure",
        { disclosure }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error generating patent from disclosure");
    }
  };

  return {
    generateKeywords,
    generatePatentFromDisclosure,
    generateFeatures,
    searchPriorArt,
    explainCitations,
    pruneReferences,
    pruneInvalidityColor,
    pruneInvalidityScore,
    generateFeaturesFromClaims,
    getElementStrengths,
    getAssigneePortfolio,
    generateContext,
    generatePatentContextFromId,
    generatePatentContextFromText,
    getPatentContext,
    savePatentContext,
  };
};

export default useLlm;
