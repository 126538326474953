/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useCallback, useEffect } from "react";
import { useViz } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import { ProjectPage } from "@/components";
import {
  AddReferenceByNumberModal,
  SearchReferencesModal,
  DocumentsTable,
  UploadFileModal,
  PatchReferencesModal,
  PruneReferencesModal,
} from "./components";
import { Patent } from "@/types/types";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { H4 } from "@/components/ui/typography";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { ElementType } from "@/types/types";
import { UpdateFeatureClaimModal } from "@/features/project/documents/components";

/**
 * @description Reference details page for invalidity project
 */
const ReferencesPage: React.FC = () => {
  const { fetchProjectData, deleteReferenceFromProject } = useViz();

  // Check our url for a project id
  const urlParams = new URLSearchParams(window.location.search);
  const projectIdUrl = urlParams.get("id");

  // Global state from store
  const referenceDetails = useProjectStore(
    (state) => state.currentProjectDetails.referenceDetails
  );
  const { currentProjectId, currentProjectDetails, subjectDetails } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  // Set page name
  const pageName = currentProjectDetails.name
    ? `${currentProjectDetails.name} - References - &AI`
    : "References - &AI";

  // Local state setup
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddClaimsModal, setShowAddClaimsModal] = useState(false);
  const [showEditClaimsModal, setShowEditClaimsModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showPatchReferencesModal, setShowPatchReferencesModal] = useState(false);
  const [showPruneModal, setShowPruneModal] = useState(false);
  const [isReferenceLoading, setIsReferenceLoading] = useState<string[]>([]);

  const handleAddPublishedPatents = () => {
    setShowAddModal(true);
  };

  const handleAddOtherDocuments = () => {
    setShowUploadModal(true);
  };

  useEffect(() => {
    setIsReferenceLoading([]);
  }, [currentProjectId]);

  useEffect(() => {
    const effectiveProjectId = projectIdUrl || currentProjectId;
    if (effectiveProjectId) {
      fetchProjectData(effectiveProjectId);
    }
  }, [projectIdUrl, currentProjectId]);

  /**
   * @param {reference object} reference - the reference to be deleted
   * @description Removeds a reference from the project
   */
  const handleRemoveReferenceFromProject = useCallback(
    async (reference: Patent) => {
      const refId = reference.id || reference.referenceId;
      if (refId && currentProjectId) {
        const response = await deleteReferenceFromProject(currentProjectId, refId);
        if (!response.success) {
          addErrorMessage(`Error deleting prior art ${reference.number}`);
        }
      }
    },
    [currentProjectId, referenceDetails, isReferenceLoading]
  );

  const handleSearchReferences = () => {

    if (currentProjectDetails?.claims?.length > 0) {
      setShowSearchModal(true);
    } else {
      setShowAddClaimsModal(true);
    }
  };

  return (
    <ProjectPage pageName={pageName}>
      {referenceDetails?.length > 0 ? (
        <>
          <div className="flex justify-between mb-1 pt-2 px-3">
            <div className="flex gap-2">
              <Button
                variant="outline"
                onClick={() => setShowPatchReferencesModal(true)}
              >
                Patch
              </Button>
              {referenceDetails?.length > 0 && (
                <Button variant="outline" onClick={() => setShowPruneModal(true)}>
                  Prune
                </Button>
              )}
              <Button variant="outline" onClick={handleSearchReferences}>
                Search
              </Button>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className="ml-2">Add References</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={handleAddPublishedPatents}>
                  Add published patents/applications
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleAddOtherDocuments}>
                  Upload other documents
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <DocumentsTable
            referenceDetails={referenceDetails}
            handleDelete={handleRemoveReferenceFromProject}
            showHead={true}
            showNotes={true}
            bodyHeight={`h-[calc(100vh-155px)]`}
          />
        </>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>This project has no references.</H4>

          <div className="flex gap-2 mt-3">
            <Button variant="outline" onClick={handleSearchReferences}>
              Search
            </Button>
            <Button variant="outline" onClick={() => setShowAddModal(true)}>
              Add by Number
            </Button>
            <Button variant="outline" onClick={() => setShowUploadModal(true)}>
              Upload Files
            </Button>
          </div>
        </div>
      )}

      <AddReferenceByNumberModal
        open={showAddModal}
        handleClose={() => setShowAddModal(false)}
        general={false}
      />
      <SearchReferencesModal
        open={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
      />
      <UploadFileModal
        open={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
      />
      <PatchReferencesModal
        open={showPatchReferencesModal}
        handleClose={() => setShowPatchReferencesModal(false)}
      />
      <PruneReferencesModal open={showPruneModal} onOpenChange={setShowPruneModal} />

      <Dialog open={showAddClaimsModal} onOpenChange={setShowAddClaimsModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add claims to search</DialogTitle>
            <DialogDescription>
              You must add claims to search for prior art.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowAddClaimsModal(false)}>
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={() => {
                setShowAddClaimsModal(false);
                setShowEditClaimsModal(true);
              }}
            >
              Add Claims
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <UpdateFeatureClaimModal
        open={showEditClaimsModal}
        onClose={() => setShowEditClaimsModal(false)}
        itemType={ElementType.CLAIM}
      />
    </ProjectPage>
  );
};

export default ReferencesPage;
