/**
 * Utility function to convert snake_case keys to camelCase
 * @param {object} obj - The object to convert
 * @returns {object} - The object with camelCase keys
 */
export const toCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => toCamelCase(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      const camelKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
      result[camelKey] = toCamelCase(obj[key]);
      return result;
    }, {} as any);
  }
  return obj;
};

/**
 * @param {string} string - The string to capitalize
 * @returns {string} - The capitalized string
 */
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @param {string} string - The comma-separated string to parse
 * @returns {array} - An array of strings
 */
export const parseCommaSeparatedString = (string: string) => {
  return string.split(",").map((s) => s.trim());
};

/**
 * @param {string} string - The string to check
 * @returns {boolean} - Whether the string is likely a patent number
 */
export const isLikelyPatentNumber = (string: string) => {
  const length = string.length;
  const isValidLength =
    (length >= 5 && /^[0-9]+$/.test(string)) ||
    (length >= 7 && length <= 15 && /^[A-Z0-9]+$/.test(string));
  return isValidLength && /^[A-Z0-9]{5,15}$/.test(string);
};

export const formatCurrency = (
  value: string | number | null,
  inCents: boolean = false
): string => {
  if (value === null) return;
  const numValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numValue)) return "N/A";

  const dollars = inCents ? numValue / 100 : numValue;
  return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" });
};
