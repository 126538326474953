/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { H4 } from "@/components/ui/typography";
import { ChevronDown, ChevronRight } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { AdminProject } from "@/types/types";

const UsageLogTable: React.FC<{
  projects: AdminProject[];
}> = ({ projects }) => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const renderRow = (project: AdminProject, level: number = 0) => {
    const isExpanded = expandedRows.has(project.id);
    const hasChildren = project.children && project.children.length > 0;

    return (
      <React.Fragment key={project.id}>
        <TableRow>
          <TableCellCondensed style={{ paddingLeft: `${level * 20}px` }}>
            {hasChildren && (
              <button onClick={() => toggleRow(project.id)}>
                {isExpanded ? (
                  <ChevronDown size={16} />
                ) : (
                  <ChevronRight size={16} />
                )}
              </button>
            )}
          </TableCellCondensed>
          <TableCellCondensed>
            <Badge variant="outline">
              {project.type === "PFA"
                ? "Portfolio"
                : project.type === "PFP"
                  ? "Portfolio Child"
                  : project.type === "PFC"
                    ? "Portfolio Child"
                    : project.type === "INV"
                      ? "Invalidity"
                      : project.type === "APP"
                        ? "Application"
                        : project.type}
            </Badge>
          </TableCellCondensed>
          <TableCellCondensed>
            <Badge variant="outline">{project.status}</Badge>
          </TableCellCondensed>
          <TableCellCondensed>{project.createdBy}</TableCellCondensed>
          <TableCellCondensed>
            {new Date(project.createdAt).toLocaleString(undefined, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </TableCellCondensed>
          <TableCellCondensed>
            {new Date(project.updatedAt).toLocaleString(undefined, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </TableCellCondensed>
          <TableCellCondensed>{project.numUsers}</TableCellCondensed>
          <TableCellCondensed>{project.numDocumentsAdded}</TableCellCondensed>
          <TableCellCondensed>
            {project.numDocumentsExported}
          </TableCellCondensed>
        </TableRow>
        {isExpanded &&
          hasChildren &&
          project.children!.map((child) => renderRow(child, level + 1))}
      </React.Fragment>
    );
  };

  return (
    <Card>
      <CardHeader>
        <H4>Usage</H4>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Created By</TableHead>
              <TableHead>Created At</TableHead>
              <TableHead>Updated At</TableHead>
              <TableHead>Users</TableHead>
              <TableHead>Documents Processed</TableHead>
              <TableHead>Documents Exported</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {projects && projects.map((project) => renderRow(project))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UsageLogTable;
