/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import useApi from "./useApi";
import { User, ApiResponse } from "../types/types";
import { useUserStore } from "../store";

const useAuth = () => {
  const { postRequest, handleError } = useApi();
  const { organizationId } = useUserStore();

  /**
   * @description Signs in a user
   * @param {string} email - The email of the user to sign in.
   * @param {string} password - The password of the user to sign in.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const signIn = async (email: string, password: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_signin", { email, password });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error signing in");
    }
  };

  /**
   * @description Signs out a user
   * @param {string} email - The email of the user to sign out.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const signOut = async (email: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_signout", { email });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error signing out");
    }
  };

  /**
   * @description Updates user role (User, Admin, SuperAdmin)
   * @param {string} email - The email of the user to update the role for.
   * @param {string} new_role - The new role for the user.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const updateUserRole = async (
    user_id: string,
    new_role: string,
    orgId: string
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_user_role", {
        user_id: user_id,
        role: new_role,
        organization_id: orgId,
        organization_id_owner: organizationId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(
        error,
        `Error updating user role for ${user_id} to ${new_role}`
      );
    }
  };

  /**
   * @description Sends a password reset email to a user in the specified organization.
   * @param {Object} user - The user object to send the password reset email to.
   * @param {string} orgName - The name of the organization to send the password reset email to.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const sendPasswordResetAdmin = async (
    user: User,
    orgName: string
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_create_user_email", {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        organization_name: orgName,
        role: user.role,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, `Error sending password reset for ${user.email}`);
    }
  };

  /**
   * @description Sends a password reset email to a user in the specified organization.
   * @param {string} email - The email of the user to send the password reset email to.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const sendPasswordResetEmail = async (email: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_reset_password", { email });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(
        error,
        "Email address entered is not associated with an active account."
      );
    }
  };

  /**
   * @description Changes the password for a user
   * @param {string} email - The email of the user to change the password for.
   * @param {string} old_password - The old password of the user.
   * @param {string} new_password - The new password for the user.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const changePassword = async (
    email: string,
    old_password: string,
    new_password: string
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_change_password", {
        email,
        old_pass: old_password,
        new_pass: new_password,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, `Error changing password for ${email}`);
    }
  };

  /**
   * @description Deactivates the specified user
   * @param {string} user_id - The email of the user to deactivate.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const disableUser = async (user_id: string, email: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_disable_user", {
        user_id: user_id,
        organization_id_owner: organizationId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, `Error deactivating user: ${email}`);
    }
  };

  /**
   * @description Activates the specified user
   * @param {string} email - The email of the user to activate.
   * @returns {Promise<Object>} - Result object with success status and data or error message.
   */
  const enableUser = async (user_id: string, email: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_enable_user", {
        user_id,
        organization_id_owner: organizationId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, `Error activating user: ${email}`);
    }
  };

  return {
    signIn,
    signOut,
    changePassword,
    updateUserRole,
    sendPasswordResetEmail,
    sendPasswordResetAdmin,
    disableUser,
    enableUser,
  };
};

export default useAuth;
