/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect, useMemo } from "react";
import { useUserStore, useAdminStore } from "@/store";
import { useOrgs } from "@/hooks";
import { PlaceholderBox, Loader } from "@/components";
import { OverviewDetailsCard, UsageLogTable, AdminPageLayout } from "@/features/admin/components";
import { RoleType } from "@/types/types";

/**
 * @description Admin page component
 */
function AdminPage() {
  const { getAdminDetailsForOrg } = useOrgs();
  const { organizationId, role } = useUserStore();
  const { projects } = useAdminStore();

  // Local state setup
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!projects) {
      getAdminDetailsForOrg(organizationId).catch(() => setError(true));
    }
  }, [organizationId, projects, getAdminDetailsForOrg]);

  const isAdmin = useMemo(() => role === RoleType.ADMIN || role === RoleType.OWNER, [role]);

  return (
    <AdminPageLayout isSuperAdmin={false}>
      {isAdmin ? (
        <div className="mt-2">
          {projects === null ? (
            <Loader />
          ) : error ? (
            <p className="text-destructive">Error fetching organization details.</p>
          ) : (
            <div className="flex flex-col gap-4">
              <OverviewDetailsCard />
              {projects.length > 0 ? <UsageLogTable projects={projects} /> : null}
            </div>
          )}
        </div>
      ) : (
        <PlaceholderBox
          title="You do not have permission to access this page."
          subtitle="Please contact your administrator for access."
        />
      )}
    </AdminPageLayout>
  );
}

export default AdminPage;
