/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { useProjectStore } from "@/store";
import { BaseChat } from "./components";
import { ProjectPage } from "@/components";

/**
 * @description Project-level chat page
 */
const ProjectChatPage: React.FC = () => {
  // Global state from store
  const subjectId: string = useProjectStore(
    (state) => state.currentProjectDetails.subjectId
  );
  const referenceIds: string[] = useProjectStore(
    (state) => state.currentProjectDetails.referenceIds
  );
  const currentProjectDetails: { id: string; name: string } = useProjectStore(
    (state) => state.currentProjectDetails
  );
  const projectIdArray: string[] = [subjectId, ...referenceIds];

  // Set page title
  const pageName = `${currentProjectDetails.name} - Chat&AI` || "Chat&AI";

  return (
    <ProjectPage pageName={pageName}>
      <BaseChat
        generalBool={false}
        projectId={currentProjectDetails.id}
        documents={projectIdArray}
        disabledBool={false}
      />
    </ProjectPage>
  );
};

export default ProjectChatPage;
