/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import styled from "styled-components";
import { Paragraph } from "@/components/ui/typography";

const PillBox = styled.div<{ bgcolor: string }>`
  background-color: ${(props) => props.bgcolor};
  border-radius: 8px;
  padding: 5px 8px;
  display: inline-block;
  color: #fff;
  margin: 1px;
  min-width: 40px;
  text-align: center;
  cursor: pointer;
`;

/**
 * @description Element pill component
 * @param {string} color
 * @param {string} label
 * @param {function} handleClick
 */
interface ElementPillProps {
  color: string;
  label: string;
  handleClick: () => void;
  isSelected?: boolean;
}

const ElementPill: React.FC<ElementPillProps> = ({ color, label, handleClick }) => {
  return (
    <PillBox bgcolor={color} onClick={handleClick}>
      <Paragraph className="text-black">{label}</Paragraph>
    </PillBox>
  );
};

export default ElementPill;
