/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { MultiDocumentSelect, InfoPopover } from "@/components";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface SemanticSearchHeaderProps {
  searchMode: string;
  setSearchMode: (mode: string) => void;
  handleDocumentChange: (selected: string[]) => void;
  handleSemanticSearch: () => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  general: boolean;
  disabled: boolean;
}

export default function SemanticSearchHeader({
  searchMode,
  setSearchMode,
  handleDocumentChange,
  handleSemanticSearch,
  searchQuery,
  setSearchQuery,
  general,
  disabled,
}: SemanticSearchHeaderProps) {
  const infoPopoverContent =
    "Semantic search finds citations that match your query in meaning. Keyword search finds citations with exact matches to the language in your query.";

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchModeChange = (value: string) => {
    if (value !== searchMode && value !== null && value !== "") {
      setSearchMode(value);
    }
  };

  return (
    <div className="flex mb-2.5 items-center gap-2 px-2 pt-2">
      <div className="max-w-500px">
        <MultiDocumentSelect
          handleChange={handleDocumentChange}
          general={general}
          disabled={disabled}
        />
      </div>
      <div className="flex-grow relative">
        <Input
          className="pr-10 min-w-[250px]"
          disabled={disabled}
          placeholder="Search selected documents..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSemanticSearch();
            }
          }}
        />
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                variant="ghost"
                className="absolute right-2 top-1/2 -translate-y-1/2"
                onClick={handleSemanticSearch}
                disabled={disabled}
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Search documents</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex gap-1">
        <ToggleGroup
          type="single"
          value={searchMode}
          onValueChange={(value) => value && handleSearchModeChange(value)}
          disabled={disabled}
        >
          <ToggleGroupItem
            value="semantic"
            aria-label="semantic search"
            className={`px-3 py-2 ${searchMode === "semantic" ? "bg-tertiary text-primary-foreground" : ""}`}
          >
            Semantic
          </ToggleGroupItem>
          <ToggleGroupItem
            value="keyword"
            aria-label="keyword search"
            className={`px-3 py-2 ${searchMode === "keyword" ? "bg-tertiary text-primary-foreground" : ""}`}
          >
            Keyword
          </ToggleGroupItem>
        </ToggleGroup>
        <InfoPopover content={infoPopoverContent} />
      </div>
    </div>
  );
}
