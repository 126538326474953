/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useLlm, useProcessReferences } from "@/hooks";
import { Patent, PATENT_TYPES } from "@/types/types";
import { toCamelCase } from "@/utils/dataUtils";
import SearchFilters from "@/features/project/create/components/SearchFilters";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  PriorArtSearchInstructions,
  PriorArtFilterInstructions,
} from "@/components/context/context";
import { Loader } from "@/components";
import DocumentsTable from "./DocumentsTable";

interface SearchReferencesModalProps {
  open: boolean;
  handleClose: () => void;
}

const STATUSES = ["Active", "Inactive"];

const SearchReferencesModal: React.FC<SearchReferencesModalProps> = ({
  open,
  handleClose,
}) => {
  const { searchPriorArt } = useLlm();
  const { addAndProcessReferences } = useProcessReferences();

  // Global state from store
  const { currentProjectId, currentProjectDetails, subjectDetails } =
    useProjectStore((state) => state);
  const { addErrorMessage } = useAppStateStore();

  // Search filters
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [cpcCodes, setCpcCodes] = useState<string[]>(
    subjectDetails.cpcCodes || []
  );
  const [keywords, setKeywords] = useState<string[]>([]);
  const [countryCodes, setCountryCodes] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>(PATENT_TYPES);
  const [statuses, setStatuses] = useState<string[]>(STATUSES);
  const [maxResults, setMaxResults] = useState<number>(50);

  const [dateError, setDateError] = useState<string>("");
  const [isSourcesLoading, setIsSourcesLoading] = useState<boolean>(false); // displays loader on search
  const [foundSources, setFoundSources] = useState<Patent[]>([]);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedReferences, setSelectedReferences] = useState<string[]>([]);

  const [priorArtSearchInstructions, setPriorArtSearchInstructions] =
    useState<string>("");
  const [priorArtFilterInstructions, setPriorArtFilterInstructions] =
    useState<string>("");

  useEffect(() => {
    resetModal();
    if (currentProjectDetails.priorityDate) {
      const priorityDate = currentProjectDetails.priorityDate;
      // Check if priorityDate is a valid Date object
      if (priorityDate instanceof Date) {
        setToDate(priorityDate);
      } else if (typeof priorityDate === "string") {
        const parsedDate = new Date(priorityDate);
        if (!isNaN(parsedDate.getTime())) {
          setToDate(parsedDate);
        }
      }
    } else if (subjectDetails.filingDate) {
      setToDate(new Date(subjectDetails.filingDate));
    }
  }, [open, subjectDetails.filingDate]);

  // Reset modal state
  const resetModal = () => {
    setKeywords(currentProjectDetails.keywords || []);
    setDateError("");
    setHasSubmitted(false);
    setIsSourcesLoading(false);
    setFoundSources([]);
    setError(false);
    setSelectedReferences([]);
    setFromDate(null);
    setToDate(null);
    setCpcCodes([]);
    setKeywords([]);
    setCountryCodes([]);
    setAssignees([]);
    setTypes(PATENT_TYPES);
    setStatuses(STATUSES);
    setMaxResults(50);
  };

  // Add references to project
  const handleAddReferences = async (addAll: boolean = false) => {
    handleClose();
    let referencesToAdd: string[];
    if (addAll) {
      referencesToAdd = foundSources.map((source) => source.number);
    } else {
      referencesToAdd = selectedReferences;
    }
    await addAndProcessReferences(
      currentProjectId,
      referencesToAdd,
      true,
      false
    );
  };

  /**
   * @description Searches for prior art based on the given keywords and search settings
   * @returns the prior art sources
   */
  const handleSearchClick = async () => {
    try {
      setHasSubmitted(true);
      setIsSourcesLoading(true);

      const payload: {
        max_results: number;
        to_date?: string;
        from_date?: string;
        types?: string[];
        statuses?: string[];
        cpc_codes?: string[];
        country_codes?: string[];
        assignees?: string[];
        search_instructions?: string;
        filter_instructions?: string;
      } = {
        max_results: maxResults || 10,
      };

      if (toDate) payload.to_date = toDate.toISOString().split("T")[0];
      if (fromDate) payload.from_date = fromDate.toISOString().split("T")[0];
      if (types.length > 0) payload.types = types;
      if (statuses.length > 0) payload.statuses = statuses;
      if (cpcCodes.length > 0) payload.cpc_codes = cpcCodes;
      if (countryCodes.length > 0) payload.country_codes = countryCodes;
      if (assignees.length > 0) payload.assignees = assignees;
      if (priorArtSearchInstructions)
        payload.search_instructions = priorArtSearchInstructions;
      if (priorArtFilterInstructions)
        payload.filter_instructions = priorArtFilterInstructions;

      const priorArt = await searchPriorArt(
        currentProjectId,
        keywords,
        payload
      );
      if (Array.isArray(priorArt.data)) {
        setFoundSources(toCamelCase(priorArt.data));
      } else {
        addErrorMessage("Invalid data format received.");
      }
      setIsSourcesLoading(false);
    } catch (error) {
      setHasSubmitted(false);
      setError(true);
    } finally {
      setIsSourcesLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col">
        <DialogHeader>
          <DialogTitle>Search for references</DialogTitle>
        </DialogHeader>
        <div className="flex-grow overflow-y-auto">
          {!hasSubmitted ? (
            <div className="flex flex-col gap-6">
              <SearchFilters
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                disabled={isSourcesLoading}
                keywords={keywords}
                setKeywords={setKeywords}
                countryCodes={countryCodes}
                setCountryCodes={setCountryCodes}
                maxResults={maxResults}
                setMaxResults={setMaxResults}
                setAssignees={setAssignees}
                assignees={assignees}
                setCpcCodes={setCpcCodes}
                cpcCodes={cpcCodes}
                types={types}
                setTypes={setTypes}
                statuses={statuses}
                setStatuses={setStatuses}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <PriorArtSearchInstructions
                  instructions={priorArtSearchInstructions}
                  onChange={setPriorArtSearchInstructions}
                  isEditing={true}
                />
                <PriorArtFilterInstructions
                  instructions={priorArtFilterInstructions}
                  onChange={setPriorArtFilterInstructions}
                  isEditing={true}
                />
              </div>
            </div>
          ) : (
            <>
              {isSourcesLoading ? (
                <div className="flex items-center justify-center h-64">
                  <Loader message="Finding references..." />
                </div>
              ) : (
                <div>
                  {foundSources?.length > 0 ? (
                    <DocumentsTable
                      referenceDetails={foundSources}
                      searchResultsBoolean={true}
                      showHead={true}
                      setSelectedReferences={setSelectedReferences}
                      selectedReferences={selectedReferences}
                      bodyHeight={`h-[calc(85vh-200px)]`}
                    />
                  ) : (
                    <div className="flex flex-col items-center mt-8">
                      <p className="mb-4">
                        No references found. Try searching with different
                        parameters.
                      </p>
                      <Button variant="outline" onClick={resetModal}>
                        Search Again
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        {error && (
          <p className="text-red-500">An error occurred. Please try again.</p>
        )}
        <DialogFooter>
          {!hasSubmitted && (
            <div className="flex justify-end mt-4 gap-2">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={dateError !== "" || toDate === null}
                onClick={handleSearchClick}
                variant="default"
              >
                Search
              </Button>
            </div>
          )}
          {!isSourcesLoading && hasSubmitted && foundSources?.length > 0 && (
            <div className="flex gap-2 justify-between">
              <Button variant="outline" onClick={resetModal}>
                Clear Results
              </Button>
              <div className="flex gap-2">
                <Button
                  variant={
                    selectedReferences.length === 0 ? "default" : "outline"
                  }
                  onClick={() => handleAddReferences(true)}
                >
                  Add All
                </Button>
                <Button
                  variant="default"
                  onClick={() => handleAddReferences(false)}
                  disabled={selectedReferences.length === 0}
                >
                  Add Selected
                </Button>
              </div>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SearchReferencesModal;
