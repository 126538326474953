/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface AddImageMenuProps {
  mouseX: number | null;
  mouseY: number | null;
  handleClose: () => void;
  onBrowseClick: () => void;
  onUploadClick: () => void;
  displayBrowse: boolean;
}

const AddImageMenu: React.FC<AddImageMenuProps> = ({
  mouseX,
  mouseY,
  handleClose,
  onBrowseClick,
  onUploadClick,
  displayBrowse,
}) => {
  const open = mouseX !== null;

  const handleBrowseClick = () => {
    onBrowseClick();
    handleClose();
  };

  const handleUploadClick = () => {
    onUploadClick();
    handleClose();
  };

  return (
    <DropdownMenu open={open} onOpenChange={handleClose}>
      <DropdownMenuTrigger asChild>
        <div
          style={{
            position: "fixed",
            top: mouseY ?? 0,
            left: mouseX ?? 0,
            visibility: "hidden",
          }}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {displayBrowse && (
          <DropdownMenuItem onClick={handleBrowseClick}>
            Browse figures
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={handleUploadClick}>Upload images</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AddImageMenu;
