/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useCallback, useState, ChangeEvent, DragEvent } from "react";
import { Upload } from "lucide-react";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

interface DragAndDropFileAreaProps {
  handleFiles: (files: File[]) => void;
  supportedFileTypes: string[];
  maxFileSize?: number;
}

/**
 * @description Drag and drop file area
 */
const DragAndDropFileArea: React.FC<DragAndDropFileAreaProps> = ({
  handleFiles,
  supportedFileTypes,
  maxFileSize = 5 * 1024 * 1024,
}) => {
  const [dragging, setDragging] = useState(false);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleAreaClick = () => {
    fileInputRef.current?.click();
  };

  const validateFiles = (files: File[]): File[] => {
    return files.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      const isFileTypeValid = supportedFileTypes.some((type) => {
        const matchesMimeType = type.toLowerCase() === file.type.toLowerCase();
        const matchesExtension = type.toLowerCase() === `.${fileExtension}`;
        return matchesMimeType || matchesExtension;
      });
      const isFileSizeValid = file.size <= maxFileSize;
      return isFileTypeValid && isFileSizeValid;
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      // const validFiles = validateFiles(Array.from(files));
      const validFiles = Array.from(files);
      handleFiles(validFiles);
    }
  };

  const handleDrag = useCallback((event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragEnter = () => setDragging(true);
  const handleDragLeave = () => setDragging(false);

  const handleDrop = (event: DragEvent<HTMLElement>) => {
    handleDrag(event);
    setDragging(false);
    const files = event.dataTransfer.files;
    if (files?.length > 0) {
      handleFiles(Array.from(files));
    }
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={cn(
        "p-8 border border-input rounded-lg text-center max-h-[80vh] overflow-y-auto w-full cursor-pointer",
        dragging && "border-primary bg-secondary"
      )}
      onClick={handleAreaClick}
    >
      <Upload className="mx-auto mb-4 h-6 w-6 " />
      <h3 className="text-lg font-semibold">
        Drag and drop a file here or click to browse.
        <label htmlFor="file-upload-button" className="cursor-pointer">
          <Input
            ref={fileInputRef}
            type="file"
            className="hidden"
            onChange={handleFileChange}
            multiple
          />
          {/* <Button variant="link" className="p-0">
            Browse
          </Button> */}
        </label>
      </h3>
      <p className="mt-2 text-sm text-muted-foreground">
        Supported file types: {supportedFileTypes.join(", ")}
      </p>
    </div>
  );
};

export default DragAndDropFileArea;
