/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useProjectStore, useAppStateStore } from "@/store";
import { useViz } from "@/hooks";
import { ProjectPage } from "@/components";
import {
  ReferenceSelect,
  SummaryTable,
  ExportChartsModal,
  DataTable,
  ElementSelect,
  PruneInvalidityColorModal,
  PruneInvalidityScoreModal,
  ColorSelect,
} from "./components";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { ElementType, ChartColors } from "@/types/types";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { H4 } from "@/components/ui/typography";

/**
 * @description Renders the invalidity page
 */
const ElementChartsPage: React.FC = () => {
  const { getSummaryChartData, getReferenceChartData, getReferenceMetadata } =
    useViz();

  // Global state from store
  const {
    currentProjectId,
    updateChartData,
    selectedReferenceDetails,
    updateSelectedReferenceDetails,
    currentProjectDetails,
    updateSelectedElements,
    subjectDetails,
    selectedColors,
    selectedElementType,
    updateSelectedColors,
    updateSelectedElementType,
    selectedElements,
  } = useProjectStore();
  const referenceIds = useProjectStore(
    (state) => state.currentProjectDetails.referenceIds
  );
  const { addErrorMessage, isStoreEmpty } = useAppStateStore();

  // Local state setup
  const navigate = useNavigate();
  const [showRefError, setShowRefError] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showPruneColorModal, setShowPruneColorModal] = useState(false);
  const [showPruneScoreModal, setShowPruneScoreModal] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  // Set page title
  const defaultProjectName = "Charts - &AI";
  const projectName = currentProjectDetails.name || defaultProjectName;
  const pageName = `${projectName} - Charts - &AI`;

  useEffect(() => {
    if (isStoreEmpty) updateSelectedElementType(ElementType.CLAIM);
  }, [isStoreEmpty]);

  useEffect(() => {
    updateSelectedReferenceDetails([]);
  }, []);

  // on load clear the selected reference details
  useEffect(() => {
    updateSelectedElements([]);
    if (currentProjectId && selectedElementType) {
      fetchSummaryChartData(selectedElementType);
    }
  }, [selectedElementType]);

  // Fetches the summary chart data
  const fetchSummaryChartData = async (type: string) => {
    const response = await getSummaryChartData(currentProjectId, type);
    if (!response.success) {
      setShowRefError(true);
      addErrorMessage(`Error fetching summary chart data`);
    }
  };

  // Fetches the reference chart data
  const fetchReferenceChartData = async (
    referenceIds: string[],
    type: string,
    subset: string[] = [],
    colors: string[] = []
  ) => {
    const response = await getReferenceChartData(
      currentProjectId,
      referenceIds,
      type === ElementType.FEATURE,
      subset,
      colors
    );

    if (!response.success) {
      setShowRefError(true);
      addErrorMessage(`Error fetching reference chart data`);
    }
  };

  // Refreshes the reference data
  const refreshReferenceData = async (
    referenceIds: string[],
    subset?: string[],
    colors?: string[]
  ) => {
    if (referenceIds === null || referenceIds.length === 0) {
      updateChartData([]);
      updateSelectedReferenceDetails([]);
    } else {
      await fetchReferenceChartData(
        referenceIds,
        selectedElementType,
        subset,
        colors
      );
      await getReferenceMetadata(referenceIds);
    }
  };

  const handleTypeSelect = useCallback(
    async (newType: string | null) => {
      if (
        newType !== selectedElementType &&
        newType !== null &&
        newType !== ""
      ) {
        updateSelectedElementType(
          newType === ElementType.CLAIM
            ? ElementType.CLAIM
            : ElementType.FEATURE
        );
        let labels = [];
        if (newType === ElementType.CLAIM) {
          labels =
            subjectDetails?.claims && subjectDetails.claims.length > 0
              ? subjectDetails.claims.map((claim) => Object.keys(claim)[0])
              : [];
        } else if (newType === ElementType.FEATURE) {
          labels =
            subjectDetails?.features && subjectDetails.features.length > 0
              ? subjectDetails.features.map(
                  (feature) => Object.keys(feature)[0]
                )
              : [];
        }
        updateSelectedElements(labels);
        await fetchSummaryChartData(newType);
        if (selectedReferenceDetails && selectedReferenceDetails.length > 0) {
          const refIds = selectedReferenceDetails.map((ref) => ref.id);
          await fetchReferenceChartData(
            refIds,
            newType,
            labels,
            selectedColors
          );
        }
      }
    },
    [
      selectedElementType,
      selectedReferenceDetails,
      fetchSummaryChartData,
      fetchReferenceChartData,
    ]
  );

  const handleReferenceSelect = useCallback(
    async (referenceIds: string[]) => {
      await refreshReferenceData(
        referenceIds,
        selectedElements,
        selectedColors
      );
    },
    [
      selectedElementType,
      selectedColors,
      selectedElements,
      refreshReferenceData,
    ]
  );

  const handleDataTableRefresh = async () => {
    const refIds = selectedReferenceDetails.map((ref) => ref.id);
    await refreshReferenceData(refIds, selectedElements, selectedColors);
  };

  const handleColorSelect = useCallback(
    async (colors: string[]) => {
      if (selectedReferenceDetails.length > 0) {
        updateSelectedColors(colors);
        const refIds = selectedReferenceDetails.map((ref) => ref.id);
        await refreshReferenceData(refIds, selectedElements, selectedColors);
      } else {
        updateSelectedColors(colors);
      }
    },
    [
      selectedElementType,
      selectedReferenceDetails,
      selectedElements,
      fetchReferenceChartData,
    ]
  );

  const handleClaimSelect = useCallback(
    async (claimIds: string[]) => {
      if (selectedReferenceDetails.length > 0) {
        const refIds = selectedReferenceDetails.map((ref) => ref.id);
        updateSelectedElements(claimIds);
        await refreshReferenceData(refIds, claimIds, selectedColors);
      } else {
        updateSelectedElements(claimIds);
      }
    },
    [
      selectedElementType,
      selectedReferenceDetails,
      selectedColors,
      refreshReferenceData,
    ]
  );

  // Selects the chart display
  const selectChartDisplay = useCallback(
    async (isExpanded: boolean, claimNumber: string | null, key: string) => {
      const refIds = [key];
      const elements = claimNumber ? [claimNumber] : [];

      if (isExpanded) {
        updateSelectedElements([elements]);
        await refreshReferenceData(refIds, elements);
      } else {
        updateSelectedElements([]);
        await refreshReferenceData(refIds, []);
      }
    },
    [selectedElementType, updateSelectedElements, refreshReferenceData]
  );

  const handleReferencesNavigate = () => {
    navigate(`/project/${currentProjectId}/references`);
  };

  // const handleClickAddNew = (event: MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePruneMenuClick = (pruneType: "color" | "score") => {
    handleCloseMenu();
    if (pruneType === "color") {
      setShowPruneColorModal(true);
    } else {
      setShowPruneScoreModal(true);
    }
  };

  //refresh charts after prune
  useEffect(() => {
    if (!showPruneColorModal) {
      fetchSummaryChartData(selectedElementType);
    }
  }, [showPruneColorModal]);

  useEffect(() => {
    if (!showPruneScoreModal) {
      fetchSummaryChartData(selectedElementType);
    }
  }, [showPruneScoreModal]);

  useEffect(() => {
    if (selectedColors.length > 0) {
      handleColorSelect(selectedColors);
    }
  }, [selectedColors]);

  return (
    <ProjectPage pageName={pageName}>
      <ScrollArea className="px-3 h-[calc(100vh-60px)]">
        {referenceIds && referenceIds.length > 0 ? (
          <div className="space-y-4">
            <div className="w-full">
              <SummaryTable
                refreshReferenceData={(data) =>
                  refreshReferenceData(
                    data.referenceIds,
                    selectedElements,
                    selectedColors
                  )
                }
                handleSelect={selectChartDisplay}
                isExpanded={isSummaryExpanded}
              />
            </div>
            <Separator className="my-2" />
            <div className="flex flex-wrap gap-2 items-center">
              <div className="flex items-center gap-2">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}
                        className="h-10 w-10 flex-shrink-0"
                      >
                        {isSummaryExpanded ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronUpIcon />
                        )}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      {isSummaryExpanded ? (
                        <p>Collapse Summary</p>
                      ) : (
                        <p>Expand Summary</p>
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <ToggleGroup
                  type="single"
                  value={selectedElementType}
                  onValueChange={(value) => handleTypeSelect(value)}
                >
                  <ToggleGroupItem
                    value={ElementType.CLAIM}
                    disabled={
                      !currentProjectDetails.claims ||
                      currentProjectDetails.claims.length === 0
                    }
                    className={cn(
                      "flex-1",
                      selectedElementType === ElementType.CLAIM
                        ? "bg-tertiary text-primary-foreground"
                        : ""
                    )}
                  >
                    Claims
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    value={ElementType.FEATURE}
                    disabled={
                      !currentProjectDetails.features ||
                      currentProjectDetails.features.length === 0
                    }
                    className={cn(
                      "flex-1",
                      selectedElementType === ElementType.FEATURE
                        ? "bg-tertiary text-primary-foreground"
                        : ""
                    )}
                  >
                    Features
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
              <ReferenceSelect handleReferenceSelect={handleReferenceSelect} />
              <ElementSelect
                onElementSelect={handleClaimSelect}
                elementType={selectedElementType}
              />
              <ColorSelect
                colors={[
                  ChartColors.GREEN,
                  ChartColors.YELLOW,
                  ChartColors.RED,
                ]}
                selectedColors={selectedColors}
                setSelectedColors={updateSelectedColors}
              />
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">Prune</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={() => handlePruneMenuClick("color")}
                  >
                    Prune by Color
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() => handlePruneMenuClick("score")}
                  >
                    Prune by Score
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button onClick={() => setShowExportModal(true)}>Download</Button>
            </div>

            <div className="chart-container">
              {selectedReferenceDetails.length > 0 ? (
                <div className="w-full overflow-x-auto">
                  <DataTable fetchReferenceChartData={handleDataTableRefresh} />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center mt-12">
                  <p className="text-center">
                    Select reference(s) to view chart
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col mt-12">
            <H4>This project has no references.</H4>

            <Button
              className="mt-3"
              variant="outline"
              onClick={handleReferencesNavigate}
            >
              Add references
            </Button>
          </div>
        )}
      </ScrollArea>

      <ExportChartsModal
        open={showExportModal}
        handleClose={() => setShowExportModal(false)}
      />
      <PruneInvalidityColorModal
        open={showPruneColorModal}
        onOpenChange={setShowPruneColorModal}
      />
      <PruneInvalidityScoreModal
        open={showPruneScoreModal}
        handleClose={() => setShowPruneScoreModal(false)}
      />
    </ProjectPage>
  );
};

export default ElementChartsPage;
