import { create } from "zustand";
import { Organization, User, AdminProject, Invoice } from "@/types/types";

interface AdminState {
  organization: Organization;
  users: User[];
  projects: AdminProject[];
  invoices: Invoice[];
  currentBillingCycle: {
    balance: number;
    periodEnd: string;
    periodStart: string;
  };
  overview: {
    userCount: number;
    projectCount: number;
    portfolioCount: number;
    documentsProcessedCount: number;
    documentsExportedCount: number;
    balance: number;
  };
  plan: {};
  superAdminInvoices: Invoice[];
  superAdminOrganizations: Organization[];
  superAdminOverview: {
    totalOrganizations: number;
    totalRevenue: number;
    totalBalance: number;
    totalUsers: number;
    totalProjects: number;
    totalDocumentsProcessed: number;
    totalDocumentsExported: number;
  };
  superAdminSelectedOrganization: Organization;
}

interface AdminActions {
  updateOrganization: (organization: Organization) => void;
  updateUsers: (users: User[]) => void;
  updateInvoices: (invoices: Invoice[]) => void;
  updateCurrentBillingCycle: (currentBillingCycle: {}) => void;
  updateOverview: (overview: {}) => void;
  updatePlan: (plan: {}) => void;
  updateProjects: (projects: AdminProject[]) => void;
  clearStore: () => void;
  updateSuperAdminInvoices: (invoices: Invoice[]) => void;
  updateSuperAdminOrganizations: (organizations: Organization[]) => void;
  updateSuperAdminOverview: (overview: {}) => void;
  clearOrgLevelStore: () => void;
  updateSuperAdminSelectedOrganization: (organization: Organization) => void;
}

const initialState: AdminState = {
  organization: {
    organizationId: "",
    name: "",
  },
  users: null,
  projects: null,
  invoices: null,
  currentBillingCycle: {
    balance: 0,
    periodEnd: new Date().toISOString(),
    periodStart: new Date().toISOString(),
  },
  overview: {
    userCount: 0,
    projectCount: 0,
    portfolioCount: 0,
    documentsProcessedCount: 0,
    documentsExportedCount: 0,
    balance: 0,
  },
  plan: {},
  superAdminInvoices: null,
  superAdminOrganizations: null,
  superAdminOverview: {
    totalOrganizations: 0,
    totalRevenue: 0,
    totalBalance: 0,
    totalUsers: 0,
    totalProjects: 0,
    totalDocumentsProcessed: 0,
    totalDocumentsExported: 0,
  },
  superAdminSelectedOrganization: {
    organizationId: "",
    name: "",
  },
};

const useAdminStore = create<AdminState & AdminActions>((set) => ({
  ...initialState,

  updateOrganization: (organization: Organization) => {
    set({ organization });
  },

  updateUsers: (users: User[]) => {
    set({ users });
  },

  updateInvoices: (invoices: Invoice[]) => {
    set({ invoices });
  },

  updateCurrentBillingCycle: (
    currentBillingCycle: AdminState["currentBillingCycle"]
  ) => {
    set({ currentBillingCycle });
  },

  updateOverview: (overview: AdminState["overview"]) => {
    set({ overview });
  },

  updatePlan: (plan: {}) => {
    set({ plan });
  },

  updateProjects: (projects: AdminProject[]) => {
    set({ projects });
  },

  updateSuperAdminInvoices: (invoices: Invoice[]) => {
    set({ superAdminInvoices: invoices });
  },

  updateSuperAdminOrganizations: (organizations: Organization[]) => {
    set({ superAdminOrganizations: organizations });
  },

  updateSuperAdminOverview: (overview: AdminState["superAdminOverview"]) => {
    set({ superAdminOverview: overview });
  },

  updateSuperAdminSelectedOrganization: (organization: Organization) => {
    set({ superAdminSelectedOrganization: organization });
  },

  clearStore: () => {
    set(initialState);
  },
  clearOrgLevelStore: () => {
    set((state) => ({
      ...state,
      plan: {},
      invoices: null,
      projects: null,
      users: null,
      overview: {
        userCount: 0,
        projectCount: 0,
        portfolioCount: 0,
        documentsProcessedCount: 0,
        documentsExportedCount: 0,
        balance: 0,
      },
      currentBillingCycle: {
        balance: 0,
        periodEnd: new Date().toISOString(),
        periodStart: new Date().toISOString(),
      },
      superAdminSelectedOrganization: {
        organization_id: "",
        name: "",
      },
    }));
  },
}));

export default useAdminStore;
