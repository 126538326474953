/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, ChangeEvent, KeyboardEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { useViz, useProject, usePortfolio } from "@/hooks";
import { useProjectStore } from "@/store";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { PersonIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { InvalidityNavigationTabs, ShareProjectModal } from "./index";
import { ParentType } from "@/types/types";

/**
 * @description Invalidity project header containing title, navigation tabs, and sharing
 */
function ProjectHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateProjectDetails } = useViz();
  const { getUserProjects } = useProject();
  const { updatePortfolioDetails } = usePortfolio();
  const { user } = useAuthInfo();

  // Global state from store
  const {
    currentProjectId,
    currentProjectDetails,
    updateCurrentProjectDetails,
    currentParent,
    currentPortfolioDetails,
    currentPortfolioId,
    updateCurrentPortfolioDetails,
    updateCurrentProjectId,
  } = useProjectStore();
  const projectOwner =
    currentParent === ParentType.PROJ
      ? currentProjectDetails.owner
      : currentPortfolioDetails.owner;

  // Check if the current URL contains '/project'
  const isProjectPage = location.pathname.includes("/project");

  // Local state set up
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>(
    currentParent === ParentType.PROJ
      ? currentProjectDetails.name
      : currentPortfolioDetails.name
  );
  const [subProjectName, setSubProjectName] = useState<string>(
    currentParent === ParentType.PORT && currentProjectDetails
      ? currentProjectDetails.name
      : ""
  );
  const [isEditingSubProject, setIsEditingSubProject] =
    useState<boolean>(false);

  // Handle project name change
  const handleRename = (e: ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handlePortfolioClick = () => {
    if (isProjectPage && currentParent === ParentType.PORT) {
      updateCurrentProjectDetails({ id: "", name: "" });
      updateCurrentProjectId("");
      navigate(`/portfolio/${currentPortfolioId}`);
    } else {
      setIsEditing(true);
    }
  };

  // Save project name
  const handleSaveProjectName = async () => {
    setIsEditing(false);
    const finalName = projectName.trim() === "" ? "Untitled" : projectName;
    if (currentParent === ParentType.PROJ) {
      if (currentProjectDetails.name !== finalName) {
        updateCurrentProjectDetails({
          ...currentProjectDetails,
          name: finalName,
        });
        await updateProjectDetails(currentProjectId, { name: finalName });
      }
    } else if (currentParent === ParentType.PORT) {
      if (currentPortfolioDetails.name !== finalName) {
        updateCurrentPortfolioDetails({
          ...currentPortfolioDetails,
          name: finalName,
        });
        await updatePortfolioDetails(currentPortfolioId, { name: finalName });
      }
    }
    if (user) {
      await getUserProjects();
    }
  };

  const handleSubProjectRename = (e: ChangeEvent<HTMLInputElement>) => {
    setSubProjectName(e.target.value);
  };

  const handleSaveSubProjectName = async () => {
    setIsEditingSubProject(false);
    const finalName =
      subProjectName.trim() === "" ? "Untitled" : subProjectName;
    if (currentProjectDetails.name !== finalName) {
      updateCurrentProjectDetails({
        ...currentProjectDetails,
        name: finalName,
      });
      const updatedProjects = currentPortfolioDetails.projects.map((project) =>
        project.id === currentProjectId
          ? { ...project, name: finalName }
          : project
      );

      updateCurrentPortfolioDetails({
        ...currentPortfolioDetails,
        projects: updatedProjects,
      });
      await updateProjectDetails(currentProjectId, { name: finalName });
    }
  };

  return (
    <div className="flex items-center justify-between px-4 py-2 border-b">
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2 mr-4">
          {!isEditing ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    className={`text-base font-medium p-1 h-auto whitespace-nowrap ${
                      currentParent === ParentType.PORT ? "cursor-pointer" : ""
                    }`}
                    onClick={handlePortfolioClick}
                  >
                    {currentParent === ParentType.PROJ
                      ? currentProjectDetails.name
                      : currentPortfolioDetails.name}
                  </div>
                </TooltipTrigger>

                {!isProjectPage && (
                  <TooltipContent>
                    Rename{" "}
                    {currentParent === ParentType.PROJ
                      ? "project"
                      : "portfolio"}
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          ) : (
            <Input
              className="h-8 text-sm"
              autoFocus
              value={projectName}
              onChange={handleRename}
              onBlur={handleSaveProjectName}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  handleSaveProjectName();
                }
              }}
            />
          )}
          {currentParent === ParentType.PORT && currentProjectDetails.name && (
            <>
              <ChevronRightIcon className="w-4 h-4" />
              {!isEditingSubProject ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div
                        className="text-base font-medium p-1 h-auto whitespace-nowrap"
                        onClick={() => setIsEditingSubProject(true)}
                      >
                        {currentProjectDetails.name}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>Rename project</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <Input
                  autoFocus
                  value={subProjectName}
                  onChange={handleSubProjectRename}
                  onBlur={handleSaveSubProjectName}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                      handleSaveSubProjectName();
                    }
                  }}
                />
              )}
            </>
          )}
        </div>
        {isProjectPage && <InvalidityNavigationTabs />}
      </div>
      {projectOwner === user?.userId && (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setShowShareModal(true)}
          aria-label="Share project"
        >
          <PersonIcon className="w-5 h-5" />
        </Button>
      )}
      {showShareModal && (
        <ShareProjectModal
          open={showShareModal}
          handleClose={() => setShowShareModal(false)}
          projectTitle={
            currentParent === ParentType.PROJ && currentProjectDetails
              ? currentProjectDetails.name
              : currentPortfolioDetails?.name || "Untitled"
          }
        />
      )}
    </div>
  );
}

export default ProjectHeader;
