/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore, useProjectStore } from "../store";
import { useVector, useViz } from ".";
import { ParentType } from "../types/types";
import { nanoid } from "nanoid";
/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProcessReferences = () => {
  const { currentProjectId, currentParent, currentPortfolioId } =
    useProjectStore();
  const { addErrorMessage, addLoadingMessage, removeLoadingMessage } =
    useAppStateStore();
  const { uploadToVDB } = useVector();
  const { addReferencesToProject, fetchProjectData } = useViz();

  /**
   * Chunks an array into smaller arrays, helper function for processReferences
   * @param {string[]} array - The array to chunk
   * @param {number} chunkSize - The size of the chunk to process
   * @returns An array of chunks
   */
  async function chunkArray(
    array: string[],
    chunkSize: number
  ): Promise<string[][]> {
    const chunks: string[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const addAndProcessReferences = async (
    projectId: string,
    refNums: string[],
    isCheckboxChecked: boolean,
    // isCitation: boolean = false,
    isPortfolioCreation: boolean = false
  ) => {
    const key = nanoid();
    addLoadingMessage("Adding references to project...", key);

    // Limit the number of references to process
    refNums = refNums.slice(0, 100);

    const chunkSize = 10;
    const chunks = await chunkArray(refNums, chunkSize);

    const isCurrentParentPortfolio = currentParent === ParentType.PORT;
    let portfolioId = "";
    if (isCurrentParentPortfolio) {
      portfolioId = currentPortfolioId;
    }

    const processChunk = async (chunk: string[]) => {
      const response = await addReferencesToProject(
        projectId,
        chunk,
        false, // isCitation
        isPortfolioCreation,
        isCurrentParentPortfolio,
        portfolioId
      );
      if (!response.success) {
        addErrorMessage(
          response.message || "An error occurred while adding references."
        );
        return;
      } else {
        if (projectId === currentProjectId) {
          await fetchProjectData(currentProjectId);
        }
      }

      const referenceIds = response.data.map(
        (ref: { reference_id: string }) => ref.reference_id
      );

      // if (projectId === currentProjectId) {
      //   updateCurrentProjectDetails((prev) => ({
      //     ...prev,
      //     referenceDetails: [...prev.referenceDetails, ...response.data],
      //     referenceIds: [...prev.referenceIds, ...referenceIds],
      //   }));
      // }

      const skipInvalidity = !isCheckboxChecked;
      const uploadResponse = await uploadToVDB(
        projectId,
        referenceIds,
        skipInvalidity,
        true
      );
      if (!uploadResponse.success) {
        addErrorMessage(
          uploadResponse.message ||
            "An error occurred while uploading references."
        );
      }
    };

    const chunkPromises = chunks.map((chunk) =>
      processChunk(chunk).catch((error) => {
        console.error("An error occurred during processing the chunk:", error);
      })
    );

    await Promise.all(chunkPromises);

    // if (projectId === currentProjectId) {
    //   await fetchProjectData(currentProjectId);
    // }

    removeLoadingMessage(key);
  };

  return {
    addAndProcessReferences,
  };
};

export default useProcessReferences;
