/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState, CSSProperties } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";

interface ImageViewerProps {
  open: boolean;
  imageUrl: string;
  imageName: string;
  handleClose: () => void;
}

interface ImageSize {
  width: number | string;
  height: number | string;
}

/**
 * @description Renders a modal for viewing an image
 */
const ImageViewer: React.FC<ImageViewerProps> = ({
  open,
  imageUrl,
  imageName,
  handleClose,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [rotation, setRotation] = useState<number>(0);
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: "auto",
    height: "auto",
  });

  useEffect(() => {
    if (imageUrl && imageUrl !== "null") {
      setImage(imageUrl);
      setName(imageName);
      setRotation(0);
      resetImageSize(imageUrl);
    }
  }, [imageUrl, imageName]);

  const resetImageSize = (url: string) => {
    const img = new Image();
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
    };
    img.src = url;
  };

  const adjustImageSize = () => {
    setImageSize((prevSize) => {
      return { width: prevSize.height, height: prevSize.width };
    });
  };

  const imageStyle: CSSProperties = {
    maxWidth: "100%",
    maxHeight: "80vh",
    objectFit: "contain",
    transform: `rotate(${rotation}deg)`,
    width: imageSize.width,
    height: imageSize.height,
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="p-4">
        <div className="relative">
          <img
            src={image ?? undefined}
            alt={name || "Image"}
            style={imageStyle}
            className="max-w-full max-h-[80vh] object-contain"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageViewer;
