/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { usePortfolio } from "@/hooks";
import { useProjectStore, useAppStateStore } from "@/store";
import {
  parseCommaSeparatedString,
  isLikelyPatentNumber,
} from "@/utils/dataUtils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { nanoid } from "nanoid";

interface AddPatentsToPortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddPatentsToPortfolioModal: React.FC<AddPatentsToPortfolioModalProps> = ({
  open,
  handleClose,
}) => {
  const { addPatentsToPortfolio } = usePortfolio();
  const { addLoadingMessage, removeLoadingMessage } = useAppStateStore();

  // Modal state
  const [patentNumbers, setPatentNumbers] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [invalidPatentNumbers, setInvalidPatentNumbers] = useState<string[]>(
    []
  );

  // Global store state
  const { currentPortfolioDetails, currentPortfolioId } = useProjectStore();

  // Reset the modal state on close
  const handleCloseClick = () => {
    setPatentNumbers("");
    setError("");
    setInvalidPatentNumbers([]);
    handleClose();
  };

  // Handle the add to project button click
  const handleAddClick = async () => {
    const key = nanoid();
    addLoadingMessage("Adding patents to portfolio...", key);
    handleClose();

    const patentNumbersArray = parseCommaSeparatedString(patentNumbers);

    // Make sure the portfolio doesn't exceed the maximum number of patents
    if (
      currentPortfolioDetails.projects.length + patentNumbersArray.length >
      100
    ) {
      setError(
        "The maximum number of patents or applications that can be in a portfolio is 100."
      );
      removeLoadingMessage(key);
      return;
    }

    // Check if the patent numbers are valid
    const validPatentNumbers = [];
    for (const patentNumber of patentNumbersArray) {
      if (!isLikelyPatentNumber(patentNumber)) {
        setInvalidPatentNumbers([...invalidPatentNumbers, patentNumber]);
      } else {
        validPatentNumbers.push(patentNumber);
      }
    }
    // If there are no valid patent numbers, return
    if (validPatentNumbers.length === 0) {
      setError("No valid patent or application numbers input.");
      removeLoadingMessage(key);
      return;
    }

    if (validPatentNumbers.length > 0) {
      await addPatentsToPortfolio(currentPortfolioId, validPatentNumbers);
    }
    // await getPortfolioMetadata(currentProjectId);

    removeLoadingMessage(key);
    handleCloseClick();
  };

  const handlePatentNumbersChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    setPatentNumbers(newValue);
    if (newValue !== "") {
      setError("");
    }
    setInvalidPatentNumbers([]);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add subject patents to portfolio</DialogTitle>
          <DialogDescription>
            Enter a comma-separated list of published patents and applications
            numbers. If no country prefix is provided, it is assumed to be a US
            patent.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="space-y-2">
            <AutosizeTextarea
              id="patent-numbers"
              placeholder="US10952930, CN10952931B2, US20220183193A1, 10782732"
              value={patentNumbers}
              onChange={(e) => handlePatentNumbersChange(e)}
              className={error ? "border-red-500" : ""}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleAddClick} disabled={patentNumbers === ""}>
            Add to Portfolio
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddPatentsToPortfolioModal;
