/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react"; // Ensure correct import
import "./globals.css";

// // Uncomment and configure Sentry if needed
Sentry.init({
  dsn: "https://23f7d0517404216cfff767da65169df3@o4507141887164416.ingest.us.sentry.io/4507143156727808",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.tryandai\.com\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Import font
const link = document.createElement("link");
link.href =
  "https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);

const options: { api_host: string | undefined } = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  // <ThemeProvider theme={theme}>
  //   <StyledThemeProvider theme={theme}>
  <PostHogProvider
    apiKey={process.env.REACT_APP_POSTHOG_API_KEY as string}
    options={options}
  >
    <App />
  </PostHogProvider>
  //   </StyledThemeProvider>
  // </ThemeProvider>
  // </React.StrictMode>
);
reportWebVitals();
