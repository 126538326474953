/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { useProjectStore, useAppStateStore, useCreateProjectStore } from "@/store";
import { useAuthInfo } from "@propelauth/react";
import { usePortfolio } from "@/hooks";
import { useNavigate } from "react-router-dom";
import {
  ProjectDetailsStep,
  PortfolioPatentsStep,
  CreateProjectPageLayout,
} from "./components";
import { Paragraph } from "@/components/ui/typography";
/**
 * @description Create invalidity project flow
 */
const CreatePortfolioProjectPage: React.FC = () => {
  const { user } = useAuthInfo();
  const { getPortfolioMetadata, createPortfolioFromPatentNumbers } = usePortfolio();
  const navigate = useNavigate();

  // Global state from store
  const { updateCurrentProjectId, updateCurrentPortfolioId } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  const {
    isProjectCreationInProgress,
    subjectNumbers,
    referenceNumbers,
    projectName,
    resetCreateProjectStore,
    updateSpinnerText,
    updateSubjectNumbers,
    updateReferenceNumbers,
    updateMaxResults,
    updateIsProjectCreationInProgress,
    subjectMode,
    claims,
    features,
    referenceOption,
    referenceDetails,
    subjectDetails,
    toDate,
    files,
  } = useCreateProjectStore();

  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);
  // const [isReferencesCompleted, setIsReferencesCompleted] = useState<boolean>(false);

  const resetState = () => {
    resetCreateProjectStore();
    updateMaxResults(100);
  };

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetState();
    }
  }, []);

  useEffect(() => {
    if (
      (subjectMode === "claim" &&
        claims.length > 0 &&
        JSON.stringify(claims[0]) !== JSON.stringify({ "1": "" })) ||
      (subjectMode === "feature" &&
        features.length > 0 &&
        JSON.stringify(features[0]) !== JSON.stringify({ "1": "" }))
    ) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [subjectMode, claims, features]);

  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  useEffect(() => {
    if (
      (subjectMode === "numbers" && subjectDetails.length > 0) ||
      (subjectMode === "assignee" && subjectNumbers.length > 0)
    ) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [subjectMode, subjectDetails, subjectNumbers]);

  // useEffect(() => {
  //   if (
  //     referenceOption === "unique" ||
  //     (referenceOption === "same" &&
  //       (referenceNumbers.length > 0 ||
  //         referenceDetails.length > 0 ||
  //         files.length > 0))
  //   ) {
  //     setIsReferencesCompleted(true);
  //   } else {
  //     setIsReferencesCompleted(false);
  //   }
  // }, [toDate, referenceNumbers, referenceDetails, referenceOption, files]);

  const handlePortfolioCreation = async () => {
    updateSpinnerText(`Creating portfolio, this may take a few minutes...`);
    updateIsProjectCreationInProgress(true);

    const response = await createPortfolioFromPatentNumbers();
    if (!response.success) {
      addErrorMessage(
        response.message ||
          "An error occurred while creating this project. Try again later."
      );
      return;
    }

    await fetchAndNavigateToProject(response.data.id);
    updateIsProjectCreationInProgress(false);
  };

  /**
   * Fetches and navigates to a project at the end of creation
   * @param {string} projectId - The id of the project to fetch
   */
  const fetchAndNavigateToProject = async (portfolioId: string) => {
    updateCurrentPortfolioId(portfolioId);
    updateCurrentProjectId("");
    const response = await getPortfolioMetadata(portfolioId);
    if (!response.success) {
      addErrorMessage(
        "Error fetching project data. Please navigate to project from home."
      );
      navigate("/home");
      return;
    }
    updateIsProjectCreationInProgress(false);
    if (!user) {
      // TODO: update
      addErrorMessage("User not found");
      return;
    }
    navigate(`/portfolio/${portfolioId}`);
  };

  const searchFiltersContent = (
    <>
      <Paragraph>
        At least one of the following must be provided: assignee, CPC codes, country
        codes, or keywords.
      </Paragraph>
    </>
  );

  return (
    <CreateProjectPageLayout
      disabled={!isDetailsCompleted || !isSubjectCompleted}
      handleCreate={handlePortfolioCreation}
      isPortfolio={true}
      title="New Portfolio"
      resetState={resetState}
      isConfirmed={false}
    >
      <ProjectDetailsStep isPortfolio={true} startExpanded={true} />

      {/* Subjects step */}
      <PortfolioPatentsStep
        setInputNumbers={updateSubjectNumbers}
        inputNumbers={subjectNumbers}
        isCompleted={isSubjectCompleted}
        isReference={false}
      />

      {/* References step */}
      {/* <PortfolioPatentsStep
        setInputNumbers={updateReferenceNumbers}
        inputNumbers={referenceNumbers}
        isReference={true}
        isCompleted={isReferencesCompleted}
      /> */}
    </CreateProjectPageLayout>
  );
};

export default CreatePortfolioProjectPage;
