/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { useCreateProjectStore } from "@/store";
import { useCreateProject } from "@/hooks";
import {
  ProjectDetailsStep,
  ApplicationSubjectStep,
  CreateProjectPageLayout,
} from "./components";
import { ProjectType } from "@/types/types";

/**
 * @description Create invalidity project flow
 */
const CreateApplicationProjectPage: React.FC = () => {
  const { createProjectFromLanguage } = useCreateProject();

  // Global state from store
  const {
    updateIsProjectCreationInProgress,
    isProjectCreationInProgress,
    updateToDate,
    resetCreateProjectStore,
    toDate,
    projectName,
    claims,
    features,
    subjectMode,
    subjectDetails,
    disclosure,
    updateMaxResults,
  } = useCreateProjectStore();

  // Local state setup
  const [patentNotFound, setPatentNotFound] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);

  // Initialize toDate with filing date from subject details
  useEffect(() => {
    if (subjectDetails.length > 0 && subjectDetails[0].filingDate) {
      updateToDate(new Date(subjectDetails[0].filingDate));
    }
  }, [subjectDetails]);

  const resetState = () => {
    resetCreateProjectStore();
    setIsConfirmed(false);
    setPatentNotFound(false);
    updateMaxResults(5);
    updateToDate(new Date());
  };

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetState();
    }
  }, []);

  useEffect(() => {
    if (
      (subjectMode === "claim" &&
        claims.length > 0 &&
        JSON.stringify(claims[0]) !== JSON.stringify({ "1": "" })) ||
      (subjectMode === "feature" &&
        features.length > 0 &&
        JSON.stringify(features[0]) !== JSON.stringify({ "1": "" })) ||
      (subjectMode === "disclosure" &&
        disclosure.length > 0 &&
        JSON.stringify(disclosure[0]) !== JSON.stringify({ "1": "" }))
    ) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [claims, features, disclosure, subjectMode, claims, features]);

  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  const handleConfirm = async () => {
    if (!toDate || !projectName) {
      return;
    }
    setIsConfirmed(true);
    updateIsProjectCreationInProgress(true);
    await createProjectFromLanguage(ProjectType.APP);
    updateIsProjectCreationInProgress(false);
  };

  return (
    <CreateProjectPageLayout
      isConfirmed={isConfirmed}
      resetState={resetState}
      disabled={!isDetailsCompleted || !isSubjectCompleted}
      handleCreate={handleConfirm}
      isPortfolio={false}
      title="New Patent Application Project"
    >
      {/* Project details */}
      <ProjectDetailsStep startExpanded={true} isPortfolio={false} />

      {/* Application subject */}
      <ApplicationSubjectStep startExpanded={false} isCompleted={isSubjectCompleted} />
    </CreateProjectPageLayout>
  );
};

export default CreateApplicationProjectPage;
