/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import he from "he";
import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Patent } from "@/types/types";
import { splitId } from "@/utils/projectUtils";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import ViewerPatentLayout from "@/components/patents/ViewerPatentLayout";
import { H3 } from "@/components/ui/typography";

interface PatentViewerProps {
  open: boolean;
  handleClose: () => void;
  patent: Patent | null;
  citationText: string;
}

const PatentViewer: React.FC<PatentViewerProps> = ({
  open,
  handleClose,
  patent,
  citationText,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const highlightedRef = useRef<HTMLDivElement | HTMLTableRowElement | null>(null);
  useEffect(() => {
    if (open && patent) {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
      if (patent.body && !patent.fullBody) {
        patent.fullBody = patent.body.map((item) => item.text);
      }

      // Delay the scroll to ensure the content is rendered
      setTimeout(() => {
        if (highlightedRef.current && containerRef.current) {
          const container = containerRef.current;
          const highlightedElement = highlightedRef.current;

          // Set the scroll position without animation
          highlightedElement.scrollIntoView({ block: "center" });

          // Adjust the scroll position
          container.scrollTop = container.scrollTop - 100; // Adjust this value as needed
        }
      }, 0);
    }
  }, [open, patent, citationText]);
  console.log(citationText);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent
        className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-y-auto"
        hideCloseButton
      >
        {patent && (
          <div className="px-2">
            <DialogHeader className="sticky top-0 z-10 bg-background pt-2 px-3">
              <div className="flex justify-between items-center gap-2">
                <H3>{he.decode(patent.name) || ""}</H3>
                {isLikelyPatentNumber(patent.number) && (
                  <div className="flex items-center gap-1 bg-primary text-primary-foreground whitespace-nowrap p-2 pl-4 rounded-lg">
                    <span className="text-xl">{splitId(patent.number)}</span>
                    <Button variant="ghost" size="icon" asChild>
                      <a
                        href={`https://patents.google.com/patent/${patent.number}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ExternalLinkIcon className="h-4 w-4" />
                      </a>
                    </Button>
                  </div>
                )}
              </div>
              <Separator />
            </DialogHeader>

            <ViewerPatentLayout
              patent={patent}
              citationText={citationText}
              highlightedRef={highlightedRef}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PatentViewer;
