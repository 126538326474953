/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { usePortfolio } from "@/hooks";
import { Loader } from "@/components";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { CheckboxWithText } from "@/components/ui/checkbox";

interface PrunePortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Prune references modal
 */
const PrunePortfolioModal: React.FC<PrunePortfolioModalProps> = ({
  open,
  handleClose,
}) => {
  const { removeProjectsFromPortfolio } = usePortfolio();

  const { currentPortfolioId, updateCurrentPortfolioDetails, currentPortfolioDetails } =
    useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [pruneCount, setPruneCount] = useState<string>("");
  const [isChecked, setIsChecked] = useState(true);

  const onClose = () => {
    handleClose();
    resetModal();
  };

  const resetModal = () => {
    setIsLoading(false);
    setError("");
    setPruneCount("");
  };

  const handlePruneReferences = async () => {
    const projects = currentPortfolioDetails.projects;
    if (projects.length < parseInt(pruneCount)) {
      setError(
        "Number of projects to keep is greater than the number of projects in portfolio."
      );
      return;
    }

    if (projects.length === parseInt(pruneCount)) {
      setError(
        "Number of projects to keep is equal to the number of projects in portfolio."
      );
      return;
    }

    setIsLoading(true);
    const allProjectIds = projects.map((project) => project.id);

    const topNProjectIds = projects
      .sort((a, b) => b.score - a.score)
      .slice(0, parseInt(pruneCount))
      .map((project) => project.id);

    // Get all projects with type PFP
    const pfpProjectIds = projects
      .filter((project) => project.type === "PFP")
      .map((project) => project.id);

    // Combine the two lists, ensuring no duplicates
    const projectsToKeepIds = Array.from(
      new Set([...topNProjectIds, ...pfpProjectIds])
    );
    const projectsToRemoveIds = allProjectIds.filter(
      (id) => !projectsToKeepIds.includes(id)
    );

    const response = await removeProjectsFromPortfolio(
      currentPortfolioId,
      projectsToRemoveIds
    );
    if (!response.success) {
      addErrorMessage("Failed to prune portfolio.");
      setError("Failed to prune portfolio.");
      setIsLoading(false);
      return;
    }
    const newPortfolioDetails = {
      ...currentPortfolioDetails,
      projects: projects.filter((project) => !projectsToRemoveIds.includes(project.id)),
    };
    updateCurrentPortfolioDetails(newPortfolioDetails);
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <Loader message="Pruning portfolio..." />
          </div>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Prune patents from portfolio</DialogTitle>
              <DialogDescription>
                Enter the number of patents you want to keep in your portfolio. The
                weakest patents will be removed. This action cannot be undone.
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-4 py-2">
              <div className="grid gap-2">
                <Input
                  id="pruneCount"
                  type="number"
                  value={pruneCount}
                  onChange={(e) => setPruneCount(e.target.value)}
                  className={error ? "border-red-500" : ""}
                  placeholder="Number of patents to keep"
                />
                {error && <p className="text-sm text-red-500">{error}</p>}
              </div>

              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="keepFullProjects"
                  checked={isChecked}
                  onCheckedChange={(checked) => setIsChecked(checked as boolean)}
                  label="Keep all patents with full projects created"
                />
              </div>
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                onClick={handlePruneReferences}
                disabled={
                  pruneCount === "" ||
                  isNaN(Number(pruneCount)) ||
                  Number(pruneCount) < 1
                }
              >
                Prune Portfolio
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PrunePortfolioModal;
