/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppStateStore, useAdminStore, useUserStore } from "@/store";
import { useDocumentTitle, useOrgs } from "@/hooks";
import { Loader } from "@/components";
import { RoleType, Organization } from "@/types/types";
import { PlanDetailsCard, CurrentBillingDetailsCard, OverviewDetailsCard, UsageLogTable, EditOrganizationModal,  ConfirmRemoveOrgModal, AdminPageLayout, UsersTable, InvoicesTable } from "@/features/admin/components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";

/**
 * @description Super admin page component
 */
function SuperAdminOrganizationDetailsPage() {
  useDocumentTitle("Super Admin - Organization Details - &AI");
  const navigate = useNavigate();
  const { updateOrganization, deleteOrganization, getAdminDetailsForOrg } = useOrgs();

  // Global state from store
  const { role } = useUserStore();
  const {
    invoices,
    projects,
    plan,
    clearOrgLevelStore,
    superAdminSelectedOrganization,
  } = useAdminStore();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();

  // Local state setup
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false);

  const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role]);

  const updateOrg = async (origOrg: Organization, newOrg: Organization) => {
    if (newOrg !== origOrg) {
      const response = await updateOrganization(origOrg, newOrg);
      if (!response.success) {
        addErrorMessage(response.message || "");
        return;
      }
    }
    setShowEditOrgModal(false);
  };

  const removeOrg = async (confirmOrgName: string) => {
    if (superAdminSelectedOrganization.name === confirmOrgName) {
      const response = await deleteOrganization(
        superAdminSelectedOrganization.name,
        superAdminSelectedOrganization.organizationId
      );
      if (!response.success) {
        addErrorMessage(response.message || "");
        setShowRemoveOrgModal(false);
        return;
      } else {
        addSuccessMessage(
          `Organization ${superAdminSelectedOrganization.name} deleted.`
        );
        clearOrgLevelStore();
        setShowRemoveOrgModal(false);
        navigate("/superadmin/organizations");
      }
    }
  };

  const handleBackClick = () => {
    clearOrgLevelStore();
    navigate("/superadmin/organizations");
  };

  useEffect(() => {
    if (getAdminDetailsForOrg === null) {
      getAdminDetailsForOrg(superAdminSelectedOrganization.organizationId);
    }
  }, [getAdminDetailsForOrg, invoices]);

  return (
    <AdminPageLayout isSuperAdmin={true}>
      {isSuperAdmin && (
        <div className="space-y-8 mt-2">
          {invoices === null ? (
            <Loader />
          ) : (
            <div className="space-y-4">
              <div className="flex justify-between">
                <Breadcrumb className="hidden md:flex">
                  <BreadcrumbList>
                    <BreadcrumbItem onClick={handleBackClick}>
                      <BreadcrumbLink>Organizations</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      {superAdminSelectedOrganization.name}
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
                <div className="flex gap-2">
                  <Button variant="outline" onClick={() => setShowEditOrgModal(true)}>
                    Edit
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => setShowRemoveOrgModal(true)}
                  >
                    Delete
                  </Button>
                </div>
              </div>

              <OverviewDetailsCard />
              <div className="flex gap-3">
                <PlanDetailsCard />
                <CurrentBillingDetailsCard />
              </div>

              {invoices?.length > 0 && <InvoicesTable invoices={invoices} />}
              <UsersTable
                orgName={superAdminSelectedOrganization.name}
                orgId={superAdminSelectedOrganization.organizationId}
              />
              {projects?.length > 0 && <UsageLogTable projects={projects} />}
            </div>
          )}
        </div>
      )}
      <EditOrganizationModal
        open={showEditOrgModal}
        onClose={() => setShowEditOrgModal(false)}
        org={plan}
        updateOrg={updateOrg}
      />
      <ConfirmRemoveOrgModal
        open={showRemoveOrgModal}
        onClose={() => setShowRemoveOrgModal(false)}
        org={plan}
        removeOrg={removeOrg}
      />
    </AdminPageLayout>
  );
}

export default SuperAdminOrganizationDetailsPage;
