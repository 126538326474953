/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMemo } from "react";
import { useUserStore } from "@/store";
import { PlaceholderBox } from "@/components";
import { UsersTable, AdminPageLayout } from "@/features/admin/components";
import { RoleType } from "@/types/types";
/**
 * @description Admin page component
 */
function AdminUsersPage() {
  const { organizationId, organizationName, role } = useUserStore();

  const isAdmin = useMemo(
    () => role === RoleType.ADMIN || role === RoleType.OWNER,
    [role]
  );

  return (
    <AdminPageLayout isSuperAdmin={false}>
      {isAdmin ? (
        <div className="mt-2">
          <UsersTable orgName={organizationName} orgId={organizationId} />
        </div>
      ) : (
        <PlaceholderBox
          title="You do not have permission to access this page."
          subtitle="Please contact your administrator for access."
        />
      )}
    </AdminPageLayout>
  );
}

export default AdminUsersPage;
