/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import SidebarPage from "@/components/layout/SidebarPage";
import { useDocumentTitle } from "@/hooks";
import { PageContentBox } from "@/styled";
import { H4 } from "@/components/ui/typography";

/**
 * @description Renders the unauthorized page
 */
const UnauthorizedPage: React.FC = () => {
  // Set page title
  useDocumentTitle("Unauthorized - &AI");

  return (
    <SidebarPage>
      <PageContentBox>
        <div className="flex flex-1 flex-col justify-center items-center mt-2">
          <H4>You do not have access to this project.</H4>
        </div>
      </PageContentBox>
    </SidebarPage>
  );
};

export default UnauthorizedPage;
