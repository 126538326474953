/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { DotsVerticalIcon, ArchiveIcon } from "@radix-ui/react-icons";
import { Project, ProjectType } from "@/types/types";
import { useViz, usePortfolio } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import ArchivedProjectsModal from "./ArchivedProjectsModal";
import DeleteProjectModal from "./DeleteProjectModal";
import { H4 } from "@/components/ui/typography";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";

interface ProjectsTableProps {
  tableType: string;
  handleClickProject?: (project: Project) => Promise<void>;
  isInArchive: boolean;
  height: string;
}

/**
 * @description Projects table
 */
const ProjectsTable: React.FC<ProjectsTableProps> = ({
  tableType,
  handleClickProject,
  isInArchive,
  height,
}) => {
  const { updateProjectDetails, deleteProject } = useViz();
  const { updatePortfolioDetails } = usePortfolio();
  const {
    updateProjectsList,
    updateArchivedProjectsList,
    archivedProjectsList,
    projectsList,
  } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (isInArchive) {
      setProjects(sortProjectsByDate(archivedProjectsList));
    } else {
      setProjects(sortProjectsByDate(projectsList));
    }
  }, [projectsList, archivedProjectsList, isInArchive]);

  // Add this new function to sort projects by date
  const sortProjectsByDate = (projects: Project[]) => {
    return [...projects].sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
  };

  const handleMenuItemClick = (action: string, project: Project) => {
    if (action === "archive") {
      handleArchiveProject(project);
    } else if (action === "delete") {
      setProjectToDelete(project);
      setShowDeleteModal(true);
    }
  };

  const onProjectCellClick = (project: Project) => {
    handleClickProject(project);
  };

  const handleArchiveProject = async (selectedProject: Project) => {
    if (isInArchive) {
      if (selectedProject.type !== "PFA") {
        await updateProjectDetails(selectedProject.id, { status: "ACTIVE" });
      } else {
        await updatePortfolioDetails(selectedProject.id, { status: "ACTIVE" });
      }

      const filteredArchivedProjectsList = archivedProjectsList.filter(
        (project) => project.id !== selectedProject.id
      );
      updateArchivedProjectsList(filteredArchivedProjectsList);
      const updatedStatusSelected = { ...selectedProject, status: "ACTIVE" };
      updateProjectsList([...projectsList, updatedStatusSelected]);
    } else {
      if (selectedProject.type !== "PFA") {
        await updateProjectDetails(selectedProject.id, { status: "ARCHIVED" });
      } else {
        await updatePortfolioDetails(selectedProject.id, { status: "ARCHIVED" });
      }

      const filteredProjectsList = projectsList.filter(
        (project) => project.id !== selectedProject.id
      );
      updateProjectsList(filteredProjectsList);
      const updatedStatusSelected = { ...selectedProject, status: "ARCHIVED" };
      updateArchivedProjectsList([...archivedProjectsList, updatedStatusSelected]);
    }
  };

  const handleDeleteProject = async () => {
    if (isInArchive) {
      const filteredArchivedProjectsList = archivedProjectsList.filter(
        (project) => project.id !== projectToDelete.id
      );
      updateArchivedProjectsList(filteredArchivedProjectsList);
    } else {
      const filteredProjectsList = projectsList.filter(
        (project) => project.id !== projectToDelete.id
      );
      updateProjectsList(filteredProjectsList);
    }
    const deleteResponse = await deleteProject(projectToDelete.id);
    if (!deleteResponse.success) {
      addErrorMessage("Error deleting project");
    }

    setProjectToDelete(null);
  };

  const handleArchiveClose = () => {
    setShowArchiveModal(false);
  };

  return (
    <>
      {projects.length === 0 ? (
        <>
          {!isInArchive && (
            <div>
              {archivedProjectsList.length > 0 && (
                <div className="flex flex-col items-center gap-2 mt-16">
                  <H4>You have no active projects.</H4>
                  <Button variant="outline" onClick={() => setShowArchiveModal(true)}>
                    View Archived Projects
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div>
          <Table className="mb-2">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[15%]">Type</TableHead>
                <TableHead className="w-[30%]">Name</TableHead>
                <TableHead className="w-[20%]">Owner</TableHead>
                <TableHead className="w-[25%]">Last modified</TableHead>
                <TableHead className="w-[10%] text-right">
                  {!isInArchive && archivedProjectsList.length > 0 && (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => setShowArchiveModal(true)}
                    >
                      <ArchiveIcon className="h-4 w-4" />
                    </Button>
                  )}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableCell className="p-0 m-0 w-[100%]" colSpan={5}>
              <div className={cn("flex flex-col", height)}>
                <ScrollArea className="flex-grow">
                  <TableBody>
                    {projects.map((project) => (
                      <TableRow
                        key={project.id}
                        className={tableType === "active" ? "cursor-pointer" : ""}
                      >
                        <TableCellCondensed
                          onClick={
                            tableType === "active"
                              ? () => onProjectCellClick(project)
                              : undefined
                          }
                          className="w-[15%]"
                        >
                          <Badge variant="outline" className="py-1">
                            {project.type === ProjectType.INV
                              ? "Invalidity"
                              : project.type === ProjectType.APP
                                ? "Application"
                                : "Portfolio"}
                          </Badge>
                        </TableCellCondensed>
                        <TableCellCondensed
                          onClick={
                            tableType === "active"
                              ? () => onProjectCellClick(project)
                              : undefined
                          }
                          className="font-medium w-[30%]"
                        >
                          {project.name}
                        </TableCellCondensed>
                        <TableCellCondensed
                          onClick={
                            tableType === "active"
                              ? () => onProjectCellClick(project)
                              : undefined
                          }
                          className="w-[20%]"
                        >
                          {project.createdBy}
                        </TableCellCondensed>
                        <TableCellCondensed
                          onClick={
                            tableType === "active"
                              ? () => onProjectCellClick(project)
                              : undefined
                          }
                          className="w-[25%]"
                        >
                          {(() => {
                            const parseDate = (dateString: string) => {
                              const utcDate = new Date(dateString);
                              return new Date(
                                utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
                              );
                            };

                            const localDate = parseDate(project.updatedAt);
                            const today = new Date();
                            const yesterday = new Date(today);
                            yesterday.setDate(yesterday.getDate() - 1);

                            const timeFormatter = new Intl.DateTimeFormat(undefined, {
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            });

                            const dateFormatter = new Intl.DateTimeFormat(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            });

                            const formattedTime = timeFormatter.format(localDate);

                            if (localDate.toDateString() === today.toDateString()) {
                              return `Today at ${formattedTime}`;
                            } else if (
                              localDate.toDateString() === yesterday.toDateString()
                            ) {
                              return `Yesterday at ${formattedTime}`;
                            } else {
                              return dateFormatter.format(localDate);
                            }
                          })()}
                        </TableCellCondensed>
                        <TableCellCondensed className="w-[10%] text-right">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button aria-haspopup="true" size="icon" variant="ghost">
                                <DotsVerticalIcon className="h-4 w-4" />
                                <span className="sr-only">Toggle menu</span>
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <DropdownMenuItem
                                onClick={() => handleMenuItemClick("archive", project)}
                              >
                                {isInArchive ? "Unarchive" : "Archive"}
                              </DropdownMenuItem>
                              {project.createdBy === "me" && (
                                <DropdownMenuItem
                                  onClick={() => handleMenuItemClick("delete", project)}
                                >
                                  Delete
                                </DropdownMenuItem>
                              )}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCellCondensed>
                      </TableRow>
                    ))}
                  </TableBody>
                </ScrollArea>
              </div>
            </TableCell>
          </Table>
        </div>
      )}
      <ArchivedProjectsModal open={showArchiveModal} handleClose={handleArchiveClose} />
      <DeleteProjectModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteProject}
        name={projectToDelete ? projectToDelete.name : ""}
      />
    </>
  );
};

export default ProjectsTable;
