/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { ReactNode } from "react";
import { cn } from "@/lib/utils";

/**
 * @description Image scroll box component
 * @param {object} children - The children elements to be rendered inside the scroll box
 */
interface ImageScrollBoxProps {
  children: ReactNode;
  className?: string;
}

const ImageScrollBox: React.FC<ImageScrollBoxProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "whitespace-nowrap overflow-x-auto overflow-y-hidden",
        "scrollbar-thin scrollbar-track-transparent",
        "py-1 mb-2.5",
        className
      )}
      role="region"
      aria-label="Image scroll box"
    >
      {children}
    </div>
  );
};

export default ImageScrollBox;
