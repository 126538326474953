/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useRef, useEffect } from "react";
import { PlusIcon, CheckIcon } from "@radix-ui/react-icons";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useCreateProjectStore } from "@/store";
import ItemTag from "./ItemTag";

interface ItemTagsProps {
  items: string[];
  setItems: React.Dispatch<React.SetStateAction<string[]>>;
  type: string;
  disabled?: boolean;
  isInCreate?: boolean;
}

const VALIDATION_SCHEMES: Record<ItemTagsProps["type"], RegExp> = {
  cpc: /^([A-HY])([0-9]{1,2})?([A-Z])?([0-9]{1,4})?(\/?[0-9]{1,10})?$/,
  keyword: /^[a-zA-Z0-9\s]+$/,
  country: /^[a-zA-Z]{2,3}$/,
  assignee: /.+/,
};

const PLACEHOLDERS: Record<ItemTagsProps["type"], string> = {
  cpc: "Enter a CPC code (e.g., H, H04M3/436)",
  keyword: "Enter a keyword",
  country: "Enter a country code (e.g., US, EP, JP)",
  assignee: "Enter an assignee (must be exact match)",
};

function ItemTags({
  items,
  setItems,
  type,
  disabled = false,
  isInCreate = false,
}: ItemTagsProps) {
  const {
    addKeyword,
    addAssignee,
    addCpc,
    addCountry,
    removeKeyword,
    removeAssignee,
    removeCpc,
    removeCountry,
  } = useCreateProjectStore();

  const scrollBoxRef = useRef<HTMLDivElement>(null);
  const [newItem, setNewItem] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [showNewField, setShowNewField] = useState(items.length === 0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
    }
  }, [items]);

  const validateItem = (item: string): boolean => {
    if (item.trim() === "") {
      setError("Item cannot be empty");
      return false;
    }
    if (!VALIDATION_SCHEMES[type].test(item)) {
      setError(`Invalid ${type}`);
      return false;
    }
    return true;
  };

  const handleAddItem = () => {
    const trimmedItem = newItem.trim();
    if (validateItem(trimmedItem)) {
      if (isInCreate) {
        if (type === "keyword") {
          addKeyword(trimmedItem);
        } else if (type === "assignee") {
          addAssignee(trimmedItem);
        } else if (type === "cpc") {
          addCpc(trimmedItem);
        } else if (type === "country") {
          addCountry(trimmedItem);
        }
      } else {
        setItems((prev) => [...prev, trimmedItem]);
      }
      setNewItem("");
      setError(null);
      setShowNewField(false);
    }
  };

  const handleRemoveItem = (item: string, index: number) => {
    if (isInCreate) {
      if (type === "keyword") {
        removeKeyword(item);
      } else if (type === "assignee") {
        removeAssignee(item);
      } else if (type === "cpc") {
        removeCpc(item);
      } else if (type === "country") {
        removeCountry(item);
      }
    } else {
      setItems((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleBlur = () => {
    timeoutRef.current = setTimeout(() => {
      if (newItem.trim() !== "") {
        handleAddItem();
      }
      setShowNewField(false);
    }, 100);
  };

  return (
    <div>
      <div className="max-h-[400px] overflow-y-auto mb-3" ref={scrollBoxRef}>
        <div className="flex flex-wrap gap-2">
          {Array.isArray(items) &&
            items.map((item, index) => (
              <ItemTag index={index} label={item} handleRemoveItem={handleRemoveItem} />
            ))}
          {!disabled && (showNewField || items.length === 0) && (
            <div className={items.length === 0 ? "w-full" : "relative"}>
              <div className="relative">
                <Input
                  // autoFocus={items.length !== 0}
                  onBlur={handleBlur}
                  value={newItem}
                  onChange={(e) => setNewItem(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleAddItem()}
                  placeholder={PLACEHOLDERS[type]}
                  className={`${error ? "border-red-500" : ""} pr-8`}
                />
                {newItem.trim() !== "" && (
                  <Button
                    size="icon"
                    variant="ghost"
                    className="absolute right-1 top-1/2 transform -translate-y-1/2 h-6 w-6 p-0"
                    onClick={handleAddItem}
                  >
                    <CheckIcon className="h-4 w-4" />
                  </Button>
                )}
              </div>
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>
          )}
          {!disabled && !showNewField && items.length > 0 && (
            <Button
              size="icon"
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => setShowNewField(true)}
            >
              <PlusIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ItemTags;
