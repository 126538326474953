/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import he from "he";
import { useNavigate } from "react-router-dom";
import { useAppStateStore, useProjectStore } from "@/store";
import { SourceNumberButton, InfoPopover } from "@/components";
import { Project } from "@/types/types";
import { useViz, usePortfolio } from "@/hooks";
import { ConfirmRemovePatentModal, CreateProjectFromPortfolioModal } from "./";
import { ProjectType } from "@/types/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import {
  Table,
  TableBody,
  TableCell,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { DotsVerticalIcon } from "@radix-ui/react-icons";

const PortfolioTable: React.FC = () => {
  const navigate = useNavigate();

  const {
    updateCurrentProjectId,
    currentPortfolioDetails,
    updateCurrentPortfolioDetails,
    currentPortfolioId,
  } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { fetchProjectData } = useViz();
  const { removeProjectsFromPortfolio } = usePortfolio();

  const [fullProjectCreationModalOpen, setFullProjectCreationModalOpen] =
    React.useState(false);
  const [confirmRemovePatentModalOpen, setConfirmRemovePatentModalOpen] =
    React.useState(false);
  const [selectedPatentToRemove, setSelectedPatentToRemove] =
    React.useState<Project | null>(null);
  const [selectedPortfolioItem, setSelectedPortfolioItem] =
    React.useState<Project | null>(null);

  // const handleExpand = (project: Project) => {
  //   setSelectedPortfolioItem(project);
  //   setFullProjectCreationModalOpen(true);
  // };

  const handleCloseModal = () => {
    setFullProjectCreationModalOpen(false);
    setSelectedPortfolioItem(null);
  };

  const handleSelectProject = async (projectId: string) => {
    await fetchProjectData(projectId);
    updateCurrentProjectId(projectId);
    navigate(`/portfolio/${currentPortfolioId}/project/${projectId}/subject`);
  };

  const handleRemovePatentFromPortfolio = async (projectId: string) => {
    const repsonse = await removeProjectsFromPortfolio(currentPortfolioDetails.id, [
      projectId,
    ]);
    if (repsonse.success) {
      const newProjectList = currentPortfolioDetails.projects.filter(
        (project) => project.id !== projectId
      );
      updateCurrentPortfolioDetails({
        ...currentPortfolioDetails,
        projects: newProjectList,
      });
    } else {
      addErrorMessage("Error removing project from portfolio");
    }
  };

  const handleRemovePatentClick = (project: Project) => {
    setSelectedPatentToRemove(project);
    setConfirmRemovePatentModalOpen(true);
  };

  const handleCloseConfirmRemovePatentModal = () => {
    setConfirmRemovePatentModalOpen(false);
    setSelectedPatentToRemove(null);
  };

  const validityStrengthContent =
    "Validity score is calculated by comparing the prior art references to the subject patent claims. A higher score indicates a lower risk of invalidation with respect to the references.";

  return (
    <>
      {currentPortfolioDetails.projects &&
        currentPortfolioDetails.projects?.length > 0 && (
          <Table>
            <TableHeader>
              <TableRow>
                {/* <TableHead className="w-[8%]">
                  <div className="flex flex-row items-center gap-1 ">
                    Score
                    <InfoPopover content={validityStrengthContent} />
                  </div>
                </TableHead> */}
                <TableHead className="w-[13%]">Name</TableHead>
                <TableHead className="w-[13%]">Number</TableHead>
                <TableHead className="w-[41%]">Title</TableHead>
                <TableHead className="w-[18%]">Assignee</TableHead>
                <TableHead className="w-[10%]">Priority Date</TableHead>
                {/* <TableHead className="w-[8%]">Top Reference</TableHead>
                <TableHead className="w-[20%]">Top Reference Claims</TableHead> */}
                <TableHead className="w-[5%]" />
              </TableRow>
            </TableHeader>
            <TableCell className="p-0 m-0 w-[100%]" colSpan={9}>
              <div className="flex flex-col h-[calc(100vh-155px)]">
                <ScrollArea className="flex-grow">
                  <TableBody>
                    {currentPortfolioDetails.projects.map((project) => {
                      // const claimBoxWidth = `${100 / project.claim_scores.length}%`;

                      return (
                        <TableRow key={project.id} className="cursor-pointer">
                          {/* <TableCellCondensed
                            className="w-[8%]"
                            onClick={() => handleSelectProject(project.id)}
                          >
                            {project.score}
                          </TableCellCondensed> */}
                          <TableCellCondensed
                            className="w-[13%]"
                            onClick={() => handleSelectProject(project.id)}
                          >
                            {project.name}
                          </TableCellCondensed>
                          <TableCellCondensed className="w-[13%]">
                            <SourceNumberButton
                              sourceNumber={
                                project.subject.number ? project.subject.number : ""
                              }
                              prefix={project.subject.prefix || ""}
                            />
                          </TableCellCondensed>
                          <TableCellCondensed
                            className="w-[41%]"
                            onClick={() => handleSelectProject(project.id)}
                          >
                            {he.decode(project.subject.name)}
                          </TableCellCondensed>
                          <TableCellCondensed
                            className="w-[18%]"
                            onClick={() => handleSelectProject(project.id)}
                          >
                            {project.subject.assignee}
                          </TableCellCondensed>
                          <TableCellCondensed
                            className="w-[10%]"
                            onClick={() => handleSelectProject(project.id)}
                          >
                            {new Date(project.subject.filing_date).toLocaleDateString()}
                          </TableCellCondensed>
                          {/* <TableCellCondensed className="bg-accent w-[8%]">
                            {project.strongest_reference &&
                            project.strongest_reference.number &&
                            isLikelyPatentNumber(project.strongest_reference.number) ? (
                              <SourceNumberButton
                                sourceNumber={
                                  project.strongest_reference.number
                                    ? project.strongest_reference.number
                                    : ""
                                }
                                prefix={project.strongest_reference.prefix || ""}
                              />
                            ) : (
                              project.strongest_reference.name || "-"
                            )}
                          </TableCellCondensed>
                          <TableCellCondensed className="bg-accent w-[20%]">
                            <div className="flex flex-row min-w-[200px]">
                              {project.claim_scores.length > 0 ? (
                                <>
                                  {project.claim_scores.map((claim) => (
                                    <div
                                      key={`${claim.label}-${claim.color}-${Math.random()}`}
                                      style={{
                                        backgroundColor: claim.color,
                                        width: claimBoxWidth,
                                        height: "50px",
                                      }}
                                    />
                                  ))}
                                </>
                              ) : (
                                "No references"
                              )}
                            </div>
                          </TableCellCondensed> */}
                          <TableCellCondensed className="w-[5%]">
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0">
                                  <DotsVerticalIcon className="h-4 w-4" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                {/* {project.type !== "PFP" && (
                                  <DropdownMenuItem
                                    onClick={() => handleExpand(project)}
                                  >
                                    Create Full Project
                                  </DropdownMenuItem>
                                )} */}
                                <DropdownMenuItem
                                  onClick={() => handleRemovePatentClick(project)}
                                >
                                  Remove from Portfolio
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </TableCellCondensed>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </ScrollArea>
              </div>
            </TableCell>
          </Table>
        )}
      <CreateProjectFromPortfolioModal
        open={fullProjectCreationModalOpen}
        handleClose={handleCloseModal}
        name={selectedPortfolioItem?.name}
        portfolioItem={selectedPortfolioItem}
        setSelectedPortfolioItem={setSelectedPortfolioItem}
      />

      <ConfirmRemovePatentModal
        open={confirmRemovePatentModalOpen}
        onClose={handleCloseConfirmRemovePatentModal}
        name={selectedPatentToRemove?.name}
        removePatentFromPortfolio={handleRemovePatentFromPortfolio}
        projectId={selectedPatentToRemove?.id}
      />
    </>
  );
};

export default PortfolioTable;
