import { create } from "zustand";
import { Patent, PATENT_TYPES } from "../types/types";
import { Dayjs } from "dayjs";

interface CreateProjectStore {
  subjectNumbers: string[];
  referenceNumbers: string[];
  subjectDetails: Patent[];
  referenceDetails: Patent[];
  spinnerText: string;
  projectError: boolean;
  isProjectCreationInProgress: boolean;
  toDate: Date;
  fromDate: Date;
  cpcCodes: string[];
  countryCodes: string[];
  keywords: string[];
  types: string[];
  statuses: string[];
  assignees: string[];
  maxResults: number;
  useCpcCodes: boolean;
  context: string[];
  features: { [key: string]: string }[];
  claims: { [key: string]: string }[];
  abstract: string;
  subjectMode: string;
  referenceOption: string;
  referenceMode: string;
  clientNumber: string;
  projectName: string;
  files: File[];
  disclosure: string;
  disclosureMode: string;
  updateSubjectDetails: (details: Patent[]) => void;
  updateReferenceDetails: (details: Patent[]) => void;
  updateSpinnerText: (text: string) => void;
  updateProjectError: (error: boolean) => void;
  updateIsProjectCreationInProgress: (inProgress: boolean) => void;
  updateSubjectNumbers: (numbers: string[]) => void;
  updateReferenceNumbers: (numbers: string[]) => void;
  updateToDate: (date: Date) => void;
  updateFromDate: (date: Date) => void;
  updateCpcCodes: (codes: string[]) => void;
  updateCountryCodes: (codes: string[]) => void;
  updateKeywords: (keywords: string[]) => void;
  updateTypes: (types: string[]) => void;
  updateStatuses: (statuses: string[]) => void;
  updateAssignees: (assignees: string[]) => void;
  updateMaxResults: (maxResults: number) => void;
  updateUseCpcCodes: (useCpcCodes: boolean) => void;
  updateAbstract: (abstract: string) => void;
  updateContext: (context: string[]) => void;
  updateSubjectMode: (mode: string) => void;
  updateReferenceOption: (option: string) => void;
  updateReferenceMode: (mode: string) => void;
  updateFeatures: (features: { [key: string]: string }[]) => void;
  updateClaims: (claims: { [key: string]: string }[]) => void;
  updateClientNumber: (number: string) => void;
  updateProjectName: (name: string) => void;
  addKeyword: (keyword: string) => void;
  addAssignee: (assignee: string) => void;
  addCpc: (code: string) => void;
  addCountry: (code: string) => void;
  removeCpc: (code: string) => void;
  removeCountry: (code: string) => void;
  removeKeyword: (keyword: string) => void;
  removeAssignee: (assignee: string) => void;
  resetCreateProjectStore: () => void;
  updateFiles: (files: File[]) => void;
  addFile: (file: File) => void;
  updateDisclosure: (disclosure: string) => void;
  updateDisclosureMode: (mode: string) => void;
}

const initialState: Omit<
  CreateProjectStore,
  | "updateSubjectNumbers"
  | "updateReferenceNumbers"
  | "updateSubjectDetails"
  | "updateReferenceDetails"
  | "updateSpinnerText"
  | "updateProjectError"
  | "updateIsProjectCreationInProgress"
  | "updateToDate"
  | "updateFromDate"
  | "updateCpcCodes"
  | "updateCountryCodes"
  | "updateKeywords"
  | "updateTypes"
  | "updateStatuses"
  | "addKeyword"
  | "addAssignee"
  | "updateAssignees"
  | "updateContext"
  | "updateMaxResults"
  | "updateUseCpcCodes"
  | "updateAbstract"
  | "updateSubjectMode"
  | "updateReferenceOption"
  | "updateReferenceMode"
  | "updateFeatures"
  | "updateClaims"
  | "updateClientNumber"
  | "updateProjectName"
  | "resetCreateProjectStore"
  | "addCpc"
  | "addCountry"
  | "removeCpc"
  | "removeCountry"
  | "removeKeyword"
  | "removeAssignee"
  | "updateFiles"
  | "addFile"
  | "updateDisclosure"
  | "updateDisclosureMode"
> = {
  subjectNumbers: [],
  referenceNumbers: [],
  subjectDetails: [],
  referenceDetails: [],
  spinnerText: "",
  projectError: false,
  isProjectCreationInProgress: false,
  toDate: null,
  fromDate: null,
  cpcCodes: [],
  countryCodes: [],
  keywords: [],
  types: PATENT_TYPES,
  statuses: ["Active", "Inactive"],
  assignees: [],
  maxResults: 5,
  useCpcCodes: false,
  features: [{ "1": "" }],
  claims: [{ "1": "" }],
  abstract: "",
  context: [],
  subjectMode: "",
  referenceOption: "",
  referenceMode: "",
  clientNumber: "",
  projectName: "",
  files: [],
  disclosure: "",
  disclosureMode: "",
};

const useCreateProjectStore = create<CreateProjectStore>((set) => ({
  ...initialState,

  resetCreateProjectStore: () => set(() => initialState),

  updateSubjectDetails: (details) => set(() => ({ subjectDetails: details })),

  updateReferenceDetails: (details) => set(() => ({ referenceDetails: details })),

  updateSpinnerText: (text) => set(() => ({ spinnerText: text })),

  updateProjectError: (error) => set(() => ({ projectError: error })),

  updateIsProjectCreationInProgress: (inProgress) =>
    set(() => ({ isProjectCreationInProgress: inProgress })),

  updateSubjectNumbers: (numbers) => set(() => ({ subjectNumbers: numbers })),

  updateReferenceNumbers: (numbers) => set(() => ({ referenceNumbers: numbers })),

  updateToDate: (date) => set(() => ({ toDate: date })),

  updateFromDate: (date) => set(() => ({ fromDate: date })),

  updateCpcCodes: (codes) => set(() => ({ cpcCodes: codes })),

  addCpc: (code) => set((state) => ({ cpcCodes: [...state.cpcCodes, code] })),

  removeCpc: (code) =>
    set((state) => ({ cpcCodes: state.cpcCodes.filter((c) => c !== code) })),

  updateCountryCodes: (codes) => set(() => ({ countryCodes: codes })),

  addCountry: (code) =>
    set((state) => ({ countryCodes: [...state.countryCodes, code] })),

  removeCountry: (code) =>
    set((state) => ({ countryCodes: state.countryCodes.filter((c) => c !== code) })),

  updateKeywords: (keywords) => set(() => ({ keywords: keywords })),

  removeKeyword: (keyword) =>
    set((state) => ({ keywords: state.keywords.filter((k) => k !== keyword) })),

  addKeyword: (keyword) => set((state) => ({ keywords: [...state.keywords, keyword] })),

  updateTypes: (types) => set(() => ({ types: types })),

  updateStatuses: (statuses) => set(() => ({ statuses: statuses })),

  updateAssignees: (assignees) => set(() => ({ assignees: assignees })),

  addAssignee: (assignee) =>
    set((state) => ({
      assignees: Array.isArray(state.assignees)
        ? [...state.assignees, assignee]
        : [assignee],
    })),

  removeAssignee: (assignee) =>
    set((state) => ({ assignees: state.assignees.filter((a) => a !== assignee) })),

  updateMaxResults: (maxResults) => set(() => ({ maxResults: maxResults })),

  updateUseCpcCodes: (useCpcCodes) => set(() => ({ useCpcCodes: useCpcCodes })),

  updateAbstract: (abstract) => set(() => ({ abstract: abstract })),

  updateSubjectMode: (mode) => set(() => ({ subjectMode: mode })),

  updateReferenceOption: (option) => set(() => ({ referenceOption: option })),

  updateReferenceMode: (mode) => set(() => ({ referenceMode: mode })),

  updateFeatures: (features) => set(() => ({ features: features })),

  updateClaims: (claims) => set(() => ({ claims: claims })),

  updateClientNumber: (number) => set(() => ({ clientNumber: number })),

  updateProjectName: (name) => set(() => ({ projectName: name })),

  updateFiles: (files) => set(() => ({ files: files })),

  addFile: (file) => set((state) => ({ files: [...state.files, file] })),

  updateContext: (context) => set(() => ({ context: context })),

  updateDisclosure: (disclosure) => set(() => ({ disclosure: disclosure })),

  updateDisclosureMode: (mode) => set(() => ({ disclosureMode: mode })),
}));

export default useCreateProjectStore;
