import React, { useState, useRef } from "react";
import {
  PlusIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  Pencil1Icon,
} from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ElementType } from "@/types/types";
import { UpdateFeatureClaimModal } from "@/features/project/documents/components";
import { Label } from "@/components/ui/label";

interface FeatureClaimListProps {
  itemType: string;
  editingBool?: boolean;
  startCollapsed?: boolean;
  list: { [key: string]: string }[];
  highlightedRef?: React.MutableRefObject<HTMLTableRowElement | null>;
  citationText?: string;
}

const FeatureClaimList: React.FC<FeatureClaimListProps> = ({
  itemType,
  editingBool,
  startCollapsed,
  list,
  highlightedRef,
  citationText,
}) => {
  const sectionTitle = itemType === ElementType.FEATURE ? "Features" : "Claims";
  const [isOpen, setIsOpen] = useState(!startCollapsed);
  const [showEditModal, setShowEditModal] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  const handleEditButton = () => {
    setShowEditModal(true);
  };

  return (
    <div className="border rounded-lg mt-3 p-2 w-full" ref={listRef}>
      {list?.length === 0 ? (
        <div className="flex items-center justify-between gap-2 pl-2">
          <Label className="mb-0">{sectionTitle}</Label>
          {editingBool && (
            <Button
              variant="ghost"
              size="icon"
              onClick={handleEditButton}
              aria-label="add"
            >
              <PlusIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
      ) : (
        <Collapsible open={isOpen} onOpenChange={setIsOpen}>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 pl-2">
              <Label className="mb-0">{sectionTitle}</Label>
              {editingBool && (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={handleEditButton}
                  aria-label="edit"
                >
                  <Pencil1Icon className="h-4 w-4" />
                </Button>
              )}
            </div>
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="icon">
                {isOpen ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent>
            <Table>
              <TableBody>
                {list?.map((item, index) => {
                  if (!item) return null;
                  const [id, text] = Object.entries(item)[0];
                  const isHighlighted =
                    citationText && text.slice(0, 75) === citationText.slice(0, 75);
                  return (
                    <TableRow
                      key={index}
                      className={isHighlighted ? "bg-blue-100" : ""}
                      ref={isHighlighted ? highlightedRef : null}
                    >
                      <TableCell className="font-medium">{id}</TableCell>
                      <TableCell>{String(text)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CollapsibleContent>
        </Collapsible>
      )}

      <UpdateFeatureClaimModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        itemType={itemType as ElementType}
      />
    </div>
  );
};

export default FeatureClaimList;
