/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";

interface ReferenceImageBoxProps {
  children: React.ReactNode;
}

const ImageContainer: React.FC<ReferenceImageBoxProps> = ({ children }) => {
  return (
    <div className="inline-flex items-start border border-gray-300 hover:border-gray-500  rounded-md mr-1 mb-2.5 transition-colors">
      {children}
    </div>
  );
};

export { ImageContainer };
