/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { ReactNode } from "react";
import { SidebarPage } from "@/components";
import ProjectHeader from "../../features/project/components/ProjectHeader";

/**
 * @description The project page component
 * @param {ReactNode} children - The children of the component
 */
interface ProjectPageProps {
  children: ReactNode;
  pageName: string;
}

const ProjectPage: React.FC<ProjectPageProps> = ({ children, pageName }) => {
  return (
    <SidebarPage pageName={pageName}>
      <ProjectHeader />
      {children}
    </SidebarPage>
  );
};

export default ProjectPage;
