/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore, useAppStateStore } from "../store";
import { useApi, useVector } from "./";
import {
  ApiResponse,
  SummaryChartData,
  ElementType,
  ParentType,
} from "../types/types";
import { toCamelCase } from "../utils/dataUtils";
import { getColumnHeaders } from "../utils/projectUtils";

/**
 * @description Hook for handling visualization operations relating to claim chart projects
 * @returns {object} - The visualization operations
 */
const useViz = () => {
  const { postRequest, getRequest, postRequestFile, handleError } = useApi();
  const { retrieveInvalidityForClaimForAllReferences } = useVector();

  const {
    subjectDetails,
    updateCurrentProjectDetails,
    selectedReferenceDetails,
    removeSelectedReferenceDetails,
    updateSubjectDetails,
    chartData,
    summaryChartData,
    updateChartData,
    updateSummaryChartData,
    updateSelectedReferenceDetails,
    updateSummaryChartHeaders,
    updateSummaryChartRowNames,
    updateSummaryChartRowHeightCollapsed,
    updateSelectedElements,
    updateSelectedColors,
    updateSelectedElementType,
    updateElementEditIndex,
    currentParent,
    currentProjectDetails,
    currentProjectId,
    currentPortfolioId,
  } = useProjectStore();
  const { updateSearchChatProjectId, addErrorMessage } = useAppStateStore();

  /**
   * @description Attaches reference ids to a project
   * @param {string} project_id - The id of the project to attach the reference ids to
   * @param {array} reference_ids - The ids of the references to attach to the project
   */
  const attachReferenceIdsToProject = async (
    project_id: string,
    reference_ids: string[]
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest(
        "post_attach_reference_ids_to_project",
        {
          project_id: project_id,
          reference_ids: reference_ids,
        }
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error attaching reference ids to project");
    }
  };

  /**
   * @description Fetches the project metadata
   * @param {string} projectId - The id of the project to fetch the metadata for
   */
  const getProjectMetadata = async (
    projectId: string
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_project_metadata", {
        project_id: projectId,
      });
      const projectMetadata = response.data;

      // Update project details in the store
      updateCurrentProjectDetails({
        id: projectMetadata.id,
        name: projectMetadata.name,

        subjectId: projectMetadata.subject.id,
        referenceIds: projectMetadata.reference.map(
          (reference: any) => reference.id
        ),
        referenceDetails: toCamelCase(projectMetadata.reference),
        documentIds: [
          projectMetadata.subject.id,
          ...projectMetadata.reference.map((reference: any) => reference.id),
        ],
        priorityDate: projectMetadata.priority_date,
        keywords: projectMetadata.keywords,
        context: projectMetadata.context,
        type: projectMetadata.type,
        owner: projectMetadata.created_by,
        documentsToNumbers: projectMetadata.id_to_number,
        documentsToNicknames: projectMetadata.id_to_nickname,
        introductionLanguage: projectMetadata.introduction_language,
        features: projectMetadata.features,
        claims: projectMetadata.claims,
      });
      const subject = projectMetadata.subject;
      updateSubjectDetails({
        id: subject.id,
        number: subject.number,
        name: subject.name,
        publicationDate: subject.publication_date,
        filingDate: subject.filing_date,
        inventors: subject.inventors,
        assignee: subject.assignee,
        primaryExaminer: subject.primary_examiner,
        nickname: subject.nickname,
        note: subject.notes,
        abstract: subject.abstract,
        body: subject.body,
        figureUrls: subject.figure_urls,
        fullBody: subject.full_body,
        isPatent: subject.is_patent,
      });

      updateSelectedElements([]);
      updateSelectedColors([]);
      updateSelectedElementType(ElementType.CLAIM);
      updateSelectedReferenceDetails([]);
      updateElementEditIndex(-1);
      return { success: true, data: projectMetadata };
    } catch (error) {
      return handleError(error, "Error fetching project metadata");
    }
  };

  /**
   * @description Fetches the summary chart data
   * @param {string} projectId - The id of the project to fetch the summary chart data for
   * @param {string} type - The type of the summary chart data to fetch
   */
  const getSummaryChartData = async (
    projectId: string,
    type: string
  ): Promise<ApiResponse> => {
    try {
      if (!projectId) {
        return { success: false, data: [], status: 400 };
      }
      let response;
      if (type === "claim") {
        response = await getRequest("get_summary_chart_data", {
          project_id: projectId,
        });
      } else {
        response = await getRequest("get_feature_summary_data", {
          project_id: projectId,
        });
      }
      const summaryData = response.data;
      updateSummaryChartHeaders(getColumnHeaders(summaryData));
      // updateSummaryChartRowNames(
      //   summaryData.map((row: SummaryChartData) => row.claim_number)
      // );
      const totalContainerHeight = 250 - 60;
      const numRows = summaryData.length;
      const rowHeightCollapsed = totalContainerHeight / numRows;
      updateSummaryChartRowHeightCollapsed(rowHeightCollapsed);
      updateSummaryChartData(summaryData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error fetching summary chart data");
    }
  };

  /**
   * @description Fetches the reference chart data
   * @param {string} projectId - The id of the project to fetch the reference chart data for
   * @param {array} referenceIds - The ids of the references to fetch the chart data for
   * @param {string} type - The type of the reference chart data to fetch
   * @param {array} subset - The subset of the reference chart data to fetch
   */
  const getReferenceChartData = async (
    projectId: string,
    referenceIds: string[],
    is_feature: boolean,
    subset: string[],
    colors: string[]
  ): Promise<ApiResponse> => {
    try {
      const claimResponse = await getRequest("get_reference_chart_data", {
        project_id: projectId,
        reference_ids: referenceIds,
        is_feature: is_feature,
        subset: subset,
        colors: colors,
      });
      updateChartData(claimResponse.data);
      return {
        success: true,
        data: claimResponse.data,
        status: claimResponse.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching reference chart data");
    }
  };

  /**
   * @description Fetches the reference metadata
   * @param {array} referenceIds - The ids of the references to fetch the metadata for
   */
  const getReferenceMetadata = async (
    referenceIds: string[]
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_reference_metadata", {
        reference_ids: referenceIds,
      });
      const referenceMetadata = response.data;
      updateSelectedReferenceDetails(referenceMetadata);
      return {
        success: true,
        data: referenceMetadata,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching reference metadata");
    }
  };

  /**
   * @description Fetches the documents to numbers mapping for a given project
   * @param {string} projectId - The id of the project to fetch the documents to numbers for
   * @returns An array of documents to numbers
   */
  const getDocumentsToNumbers = async (
    projectId: string
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_project_document_ids_to_numbers", {
        project_id: projectId,
      });
      // updateCurrentProjectDetails({
      //   ...currentProjectDetails,
      //   documentsToNumbers: response.data,
      // });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error fetching documents to numbers");
    }
  };

  /**
   * @description Deletes a reference from a project
   * @param {string} projectId - The id of the project to delete the reference from
   * @param {string} referenceId - The id of the reference to delete
   */
  const deleteReferenceFromProject = async (
    projectId: string,
    referenceId: string
  ): Promise<ApiResponse> => {
    try {
      const newReferenceIds = currentProjectDetails.referenceIds.filter(
        (id) => id !== referenceId
      );
      const newReferenceDetails = currentProjectDetails.referenceDetails.filter(
        (ref) => ref.id !== referenceId
      );
      updateCurrentProjectDetails({
        ...currentProjectDetails,
        referenceIds: newReferenceIds,
        referenceDetails: newReferenceDetails,
      });

      const response = await postRequest("delete_reference_from_project", {
        project_id: projectId,
        reference_id: referenceId,
      });

      if (selectedReferenceDetails !== null) {
        removeSelectedReferenceDetails(referenceId);
      }
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting reference from project");
    }
  };

  /**
   * @description Deletes a reference citation from a project
   * @param {string} invalidityCitationId - The id of the invalidity citation to delete
   */
  const deleteReferenceCitation = async (
    invalidityCitationId: string
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_reference_citation", {
        invalidity_citation_id: invalidityCitationId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting reference citation");
    }
  };

  /**
   * @description Adds a PDF to a project
   * @param {string} projectId - The id of the project to add the PDF to
   * @param {File} file - The file to add to the project
   * @param {object} options - Additional options to pass to the request
   */
  const addPDFToProject = async (
    projectId: string,
    file: File
  ): Promise<ApiResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("project_id", projectId);

    try {
      const response = await postRequestFile("post_sync_reducto_job", formData);
      const referenceIds = Array.isArray(response.data)
        ? response.data.map((item) => item.reference_id)
        : [response.data.reference_id];
      await addToDocumentsAdded(
        currentParent === ParentType.PORT
          ? currentPortfolioId
          : currentProjectId,
        referenceIds,
        currentParent === ParentType.PORT
      );

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Failed to add reference to project");
    }
  };

  /**
   * @description Adds multiple references to a project
   * @param {string} projectId - The id of the project to add the references to
   * @param {array} reference_numbers - The numbers of the references to add to the project
   * @param {string} is_citation - Whether the references are citations or not
   */
  const addReferencesToProject = async (
    projectId: string,
    referenceNumbers: string[],
    isCitation = false,
    isPortfolioCreation = false,
    isCurrentParentPortfolio = false,
    portfolioId = ""
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_references_to_project", {
        project_id: projectId,
        reference_numbers: referenceNumbers,
        is_citation: isCitation,
        is_portfolio_creation: isPortfolioCreation,
      });

      const referenceIds = response.data.map((doc: any) => doc.reference_id);
      await addToDocumentsAdded(
        isCurrentParentPortfolio ? portfolioId : projectId,
        referenceIds,
        isCurrentParentPortfolio
      );

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(
        error,
        `Failed to add references ${referenceNumbers} to the project`
      );
    }
  };

  const deleteProject = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_project", {
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting project");
    }
  };

  /**
   * @description Updates the language of a reference citation
   * @param {string} citationId - The id of the citation to update the language for
   * @param {object} options - Additional options to pass to the request
   */
  const updateReferenceCitation = async (
    citationId: string,
    options: { [key: string]: any } = {}
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      citation_id: citationId,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest(
        "post_update_reference_citation",
        payload
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating citation language");
    }
  };

  /**
   * @description Adds a reference citation to the project
   * @param {string} invalidityId - The id of the invalidity to add the citation to
   * @param {string} organizationId - The id of the organization to add the citation to
   * @param {string} color - The color of the citation to add
   * @param {string} text - The text of the citation to add
   * @param {string} paragraph - The paragraph of the citation to add
   * @param {array} figureRefs - The references of the figures to add to the citation
   * @param {array} figureUrls - The URLs of the figures to add to the citation
   */
  const addReferenceCitation = async (
    invalidityId: string,
    referenceId: string,
    color: string,
    text: string,
    paragraph: string,
    projectId: string,
    isFeature: boolean,
    figureUrls: string[],
    figureRefs: string[],
    claimNumber: string
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_reference_citation", {
        invalidity_id: invalidityId,
        color: color,
        text: text,
        paragraph: paragraph,
        figure_urls: figureUrls,
        figure_refs: figureRefs,
        score: null,
        project_id: projectId,
        is_feature: isFeature,
        document_id: referenceId,
        claim_number: claimNumber,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding reference citation");
    }
  };

  /**
   * @description Updates the project details
   * @param {string} project_id - The id of the project to update the details for
   * @param {object} options - Additional options to pass to the request
   */
  const updateProjectDetails = async (
    project_id: string,
    options: any
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      project_id: project_id,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest(
        "post_update_project_details",
        payload
      );
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error updating project details");
    }
  };

  /**
   * @description Get project metadata and summary chart data
   * @param {string} projectId - The id of the project to fetch the data for
   */
  const fetchProjectData = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await getProjectMetadata(projectId);
      // await getDocumentsToNumbers(projectId);
      updateSummaryChartData([]);
      updateChartData([]);

      if ("data" in response) {
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      return handleError(error, "Error fetching project data");
    }
  };

  /**
   * @description Adds a claim or feature to the project
   * @param {string} projectId - The id of the project to add the claim or feature to
   * @param {string} claimNumber - The claim number of the claim or feature to add
   * @param {string} claimText - The text of the claim or feature to add
   * @param {number} prevClaimIndex - The index of the previous claim or feature to add
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const addClaimOrFeatureToProject = async (
    projectId: string,
    claimNumber: string,
    claimText: string,
    prevClaimIndex: number,
    is_feature: boolean,
    isInChart: boolean
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        claim_text: claimText,
        prev_claim_index: prevClaimIndex.toString(),
        is_feature: is_feature,
      });

      const invalidityResponse =
        await retrieveInvalidityForClaimForAllReferences(
          projectId,
          claimNumber,
          claimText,
          is_feature
        );
      if (!invalidityResponse.success) {
        addErrorMessage(`Error finding invalidity for claim ${claimNumber}`);
      }

      if (
        !is_feature &&
        currentProjectDetails &&
        currentProjectDetails.claims
      ) {
        const newClaim = {
          [claimNumber]: claimText,
        };
        const insertIndex = prevClaimIndex + 1;
        updateCurrentProjectDetails({
          ...currentProjectDetails,
          claims: [
            ...currentProjectDetails.claims.slice(0, insertIndex),
            newClaim,
            ...currentProjectDetails.claims.slice(insertIndex),
          ],
        });
      } else if (
        is_feature &&
        currentProjectDetails &&
        currentProjectDetails.features
      ) {
        const newFeature = {
          [claimNumber]: claimText,
        };
        const insertIndex = prevClaimIndex + 1;
        updateCurrentProjectDetails({
          ...currentProjectDetails,
          features: [
            ...currentProjectDetails.features.slice(0, insertIndex),
            newFeature,
            ...currentProjectDetails.features.slice(insertIndex),
          ],
        });
      }

      return {
        success: true,
        data: invalidityResponse.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error adding claim or feature to project");
    }
  };

  /**
   * @description Splits a claim or feature into multiple claims or features
   * @param {string} projectId - The id of the project to split the claim or feature in
   * @param {string} claimNumber - The claim number of the claim or feature to split
   * @param {array} newNumbers - The numbers of the new claims or features to create
   * @param {array} newTexts - The texts of the new claims or features to create
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const splitClaimOrFeature = async (
    projectId: string,
    claimNumber: string,
    newNumbers: string[],
    newTexts: string[],
    is_feature: boolean,
    isInChart: boolean
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_split_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        new_claim_numbers: newNumbers,
        new_claim_texts: newTexts,
        is_feature: is_feature,
      });

      const invalidityResponse =
        await retrieveInvalidityForClaimForAllReferences(
          projectId,
          newNumbers[0],
          newTexts[0],
          is_feature
        );
      if (!invalidityResponse.success) {
        addErrorMessage(`Error finding invalidity for claim ${claimNumber}`);
      }

      const invalidityResponse2 =
        await retrieveInvalidityForClaimForAllReferences(
          projectId,
          newNumbers[1],
          newTexts[1],
          is_feature
        );
      if (!invalidityResponse2.success) {
        addErrorMessage(`Error finding invalidity for claim ${claimNumber}`);
      }

      if (
        !is_feature &&
        currentProjectDetails &&
        currentProjectDetails.claims
      ) {
        const claimIndex = currentProjectDetails.claims.findIndex(
          (claim) => Object.keys(claim)[0] === claimNumber
        );
        if (claimIndex !== -1) {
          const newClaims = [
            ...currentProjectDetails.claims.slice(0, claimIndex),
            ...newNumbers.map((number, index) => ({
              [number]: newTexts[index],
            })),
            ...currentProjectDetails.claims.slice(claimIndex + 1),
          ];
          updateCurrentProjectDetails({
            ...currentProjectDetails,
            claims: newClaims,
          });
        }
      } else if (
        is_feature &&
        currentProjectDetails &&
        currentProjectDetails.features
      ) {
        const featureIndex = currentProjectDetails.features.findIndex(
          (feature) => Object.keys(feature)[0] === claimNumber
        );
        if (featureIndex !== -1) {
          const newFeatures = [
            ...currentProjectDetails.features.slice(0, featureIndex),
            ...newNumbers.map((number, index) => ({
              [number]: newTexts[index],
            })),
            ...currentProjectDetails.features.slice(featureIndex + 1),
          ];
          updateCurrentProjectDetails({
            ...currentProjectDetails,
            features: newFeatures,
          });
        }
      }

      return {
        success: true,
        data: invalidityResponse.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error splitting claim or feature");
    }
  };

  /**
   * @description Edits a claim or feature in the project
   * @param {string} projectId - The id of the project to edit the claim or feature in
   * @param {string} claimNumber - The claim number of the claim or feature to edit
   * @param {string} newNumber - The new claim number of the claim or feature to edit
   * @param {string} newText - The new text of the claim or feature to edit
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const editClaimOrFeature = async (
    projectId: string,
    claimNumber: string,
    newNumber: string,
    newText: string,
    is_feature: boolean,
    isInChart: boolean
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_edit_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        new_claim_number: newNumber,
        new_claim_text: newText,
        is_feature: is_feature,
      });

      const invalidityResponse =
        await retrieveInvalidityForClaimForAllReferences(
          projectId,
          newNumber,
          newText,
          is_feature
        );
      if (!invalidityResponse.success) {
        addErrorMessage(`Error finding invalidity for claim ${claimNumber}`);
      }

      if (
        !is_feature &&
        currentProjectDetails &&
        currentProjectDetails.claims
      ) {
        const updatedClaims = currentProjectDetails.claims.map((claim) => {
          if (Object.keys(claim)[0] === claimNumber) {
            return { [newNumber]: newText };
          }
          return claim;
        });
        updateCurrentProjectDetails({
          ...currentProjectDetails,
          claims: updatedClaims,
        });
      } else if (
        is_feature &&
        currentProjectDetails &&
        currentProjectDetails.features
      ) {
        const updatedFeatures = currentProjectDetails.features.map(
          (feature) => {
            if (Object.keys(feature)[0] === claimNumber) {
              return { [newNumber]: newText };
            }
            return feature;
          }
        );
        updateCurrentProjectDetails({
          ...currentProjectDetails,
          features: updatedFeatures,
        });
      }

      return {
        success: true,
        data: invalidityResponse.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error editing claim or feature");
    }
  };

  /**
   * @description Deletes a claim or feature from the project
   * @param {string} projectId - The id of the project to delete the claim or feature from
   * @param {string} claimNumber - The claim number of the claim or feature to delete
   * @param {boolean} is_feature - Whether the reference is a feature or not
   */
  const deleteClaimOrFeature = async (
    projectId: string,
    claimNumber: string,
    is_feature: boolean,
    claimIndex: number,
    isInChart: boolean
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_claim", {
        project_id: projectId,
        claim_number: claimNumber,
        is_feature: is_feature,
      });

      if (
        !is_feature &&
        currentProjectDetails &&
        currentProjectDetails.claims
      ) {
        updateCurrentProjectDetails({
          ...currentProjectDetails,
          claims: currentProjectDetails.claims.filter(
            (_, index) => index !== claimIndex
          ),
        });
      } else if (
        is_feature &&
        currentProjectDetails &&
        currentProjectDetails.features
      ) {
        updateCurrentProjectDetails({
          ...currentProjectDetails,
          features: currentProjectDetails.features.filter(
            (_, index) => index !== claimIndex
          ),
        });
      }

      if (chartData && summaryChartData) {
        const newChartData = chartData.filter(
          (_, index) => index !== claimIndex
        );
        const newSummaryChartData = summaryChartData.filter(
          (_, index) => index !== claimIndex
        );
        updateChartData(newChartData);
        const newSummaryChartHeaders = getColumnHeaders(newSummaryChartData);
        updateSummaryChartRowNames(
          newSummaryChartData.map((row: SummaryChartData) => row.claim_number)
        );
        updateSummaryChartHeaders(newSummaryChartHeaders);
        updateSummaryChartData(newSummaryChartData);
        // updateSummaryChartHeaders(getColumnHeaders(newSummaryChartData));
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      addErrorMessage(
        `Error deleting claim ${claimNumber} from project ${currentProjectDetails.name} `
      );
      return handleError(error, "Error deleting claim or feature");
    }
  };

  /**
   * @description Updates the citation URLs for a reference
   * @param {string} projectId - The id of the project to update the citation URLs for
   * @param {string} citationId - The id of the citation to update the citation URLs for
   * @param {object} options - Additional options to pass to the request
   */
  const updateCitationUrls = async (
    citationId: string,
    options: { [key: string]: any } = {}
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        citation_id: citationId,
      };
      // Append additional options to payload if they exist
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          payload[key] = options[key];
        }
      });
      const response = await postRequest("post_update_citation_urls", payload);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating citation URLs");
    }
  };

  /**
   * @description Uploads an image to S3
   * @param {File} file - The file to upload to S3
   */
  const uploadImageToS3 = async (file: File): Promise<ApiResponse> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postRequestFile(
        "post_upload_image_to_s3",
        formData
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading image to S3");
    }
  };

  /**
   * @description Updates the note for a reference in the project
   * @param {string} projectId - The id of the project to update the note for
   * @param {string} referenceId - The id of the reference to update the note for
   * @param {string} note - The new note for the reference
   */
  const updateProjectReferenceNote = async (
    projectId: string,
    referenceId: string,
    note: string
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_update_project_reference_note", {
        project_id: projectId,
        reference_id: referenceId,
        note: note,
      });
      const newReferenceDetails = [...currentProjectDetails.referenceDetails];
      newReferenceDetails.map((reference) => {
        if (reference.id === referenceId) {
          reference.note = note;
        }
      });
      updateCurrentProjectDetails({
        ...currentProjectDetails,
        referenceDetails: newReferenceDetails,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating project reference note");
    }
  };

  /**
   * @description Updates the note for a reference in the project
   * @param {string} projectId - The id of the project to update the note for
   * @param {string} referenceId - The id of the reference to update the note for
   * @param {string} newName - The new note for the reference
   */
  const updateProjectReferenceNickname = async (
    projectId: string,
    referenceId: string,
    newName: string
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest(
        "post_update_project_reference_nickname",
        {
          project_id: projectId,
          reference_id: referenceId,
          name: newName,
        }
      );
      const nicknames = { ...currentProjectDetails.documentsToNicknames };
      nicknames[referenceId] = newName;
      updateCurrentProjectDetails({
        ...currentProjectDetails,
        documentsToNicknames: nicknames,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating project reference note");
    }
  };

  /**
   * @description Gets the search chat project id
   */
  const getSearchChatProjectId = async (): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_search_chat_project_id");
      updateSearchChatProjectId(response.data.id);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting search chat project id");
    }
  };

  /**
   * @description Gets the full document for a reference
   * @param {string} projectId - The id of the project to get the full document for
   * @param {string} referenceId - The id of the reference to get the full document for
   */
  const getFullDocument = async (
    projectId: string,
    referenceId: string
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_full_document", {
        project_id: projectId,
        reference_id: referenceId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting full document");
    }
  };

  /**
   * @description Gets the user files for a user
   * @param {string} userEmail - The email of the user to get the files for
   */
  const getUserFiles = async (userEmail: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_user_files", {
        user_email: userEmail,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting user files");
    }
  };

  const getElementChartExport = async (
    projectId: string,
    referenceIds: string[],
    includeFeatures: boolean,
    includeClaims: boolean,
    includeSummary: boolean,
    introductionLanguage: string,
    colors: string[],
    features: string[],
    claims: string[],
    splitFiles: boolean,
    exportType: string
  ): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_project_export", {
        project_id: projectId,
        reference_ids: referenceIds,
        include_features: includeFeatures,
        include_claims: includeClaims,
        include_summary: includeSummary,
        introduction_language: introductionLanguage,
        color_subset: colors,
        feature_subset: features,
        claim_subset: claims,
        export_type: exportType,
        split_files: splitFiles,
      });

      const downloadFile = async (url: string, fileType: string) => {
        const fileResponse = await fetch(url);
        if (!fileResponse.ok)
          throw new Error(`Failed to download the ${fileType} file`);
        const fileBlob = await fileResponse.blob();
        const localUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = localUrl;
        const fileName = currentProjectDetails.name.replace(/[\s']/g, "");
        const extension = splitFiles
          ? "zip"
          : fileType === "word"
            ? "docx"
            : "xlsx";
        link.setAttribute("download", `${fileName}_${fileType}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(localUrl);
      };

      if (response.data.urls) {
        // Handle multiple URLs
        if (response.data.urls.word) {
          await downloadFile(response.data.urls.word, "word");
        }
        if (response.data.urls.excel) {
          await downloadFile(response.data.urls.excel, "excel");
        }
      } else if (response.data.url) {
        // Handle single URL
        await downloadFile(response.data.url, exportType);
      } else {
        if (process.env.NODE_ENV !== "production") {
          console.error("No download URL found");
        }
        addErrorMessage("Error downloading file. Try again later.");
        return;
      }
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting element chart export for word");
    }
  };

  /**
   * @description Updates the document details
   * @param {string} documentId - The id of the document to update the details for
   * @param {object} options - Additional options to pass to the request
   */
  const updateDocumentDetails = async (
    documentId: string,
    options: any
  ): Promise<ApiResponse> => {
    try {
      const payload: { [key: string]: any } = {
        document_id: documentId,
      };
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          payload[key] = options[key];
        }
      });
      const response = await postRequest(
        "post_update_document_details",
        payload
      );
      updateSubjectDetails({ ...subjectDetails, abstract: payload.abstract });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error updating document details");
    }
  };

  /**
   * @description Updates documents to added documents for billing
   * @param {string} id - The id of the document to update the details for
   * @param {array} referenceIds - The ids of the references to update the details for
   * @param {boolean} is_portfolio - Whether the documents are in a portfolio
   */
  const addToDocumentsAdded = async (
    id: string,
    referenceIds: string[],
    is_portfolio: boolean
  ): Promise<ApiResponse> => {
    try {
      const filteredReferenceIds = referenceIds.filter(
        (id) => id && id.trim() !== ""
      );
      const response = await postRequest("post_add_to_documents_added", {
        id: id,
        reference_ids: filteredReferenceIds,
        is_portfolio: is_portfolio,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding to documents added");
    }
  };

  const uploadFile = async (file: File): Promise<ApiResponse> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postRequest("post_upload_file", formData);
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading file");
    }
  };

  return {
    getProjectMetadata,
    attachReferenceIdsToProject,
    getDocumentsToNumbers,
    getSummaryChartData,
    getReferenceChartData,
    getReferenceMetadata,
    deleteReferenceFromProject,
    deleteReferenceCitation,
    fetchProjectData,
    addReferencesToProject,
    updateProjectDetails,
    addClaimOrFeatureToProject,
    splitClaimOrFeature,
    editClaimOrFeature,
    deleteClaimOrFeature,
    addPDFToProject,
    addReferenceCitation,
    updateCitationUrls,
    uploadImageToS3,
    updateProjectReferenceNote,
    getSearchChatProjectId,
    getFullDocument,
    getUserFiles,
    updateReferenceCitation,
    updateDocumentDetails,
    updateProjectReferenceNickname,
    deleteProject,
    getElementChartExport,
    addToDocumentsAdded,
    uploadFile,
  };
};

export default useViz;
