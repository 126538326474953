/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { useCreateProject } from "@/hooks";
import {
  ProjectDetailsStep,
  InvaliditySubjectStep,
  CreateProjectPageLayout,
} from "./components";
import { ProjectType } from "@/types/types";
import { useCreateProjectStore } from "@/store";

/**
 * @description Create invalidity project flow
 */
const CreateProjectPage: React.FC = () => {
  const { createProjectFromLanguage, createProjectFromNumber } =
    useCreateProject();

  // Global state from store
  const {
    updateIsProjectCreationInProgress,
    isProjectCreationInProgress,
    updateToDate,
    updateCpcCodes,
    resetCreateProjectStore,
    toDate,
    projectName,
    updateSpinnerText,
    subjectMode,
    subjectDetails,
    updateMaxResults,
    subjectNumbers,
    claims,
    features,
  } = useCreateProjectStore();

  // Local state setup
  const [patentNotFound, setPatentNotFound] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);
  // const [isReferencesCompleted, setIsReferencesCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (
      subjectDetails.length > 0 &&
      subjectDetails[0].filingDate &&
      subjectDetails[0].cpcMain
    ) {
      updateToDate(new Date(subjectDetails[0].filingDate));
      if (subjectDetails[0].cpcAdditional) {
        const cpc = [].concat(
          subjectDetails[0].cpcMain,
          subjectDetails[0].cpcAdditional
        );
        updateCpcCodes(cpc);
      } else {
        updateCpcCodes(subjectDetails[0].cpcMain);
      }
    }
  }, [subjectDetails]);

  const resetState = () => {
    resetCreateProjectStore();
    updateMaxResults(20);
    updateToDate(new Date());
    setIsConfirmed(false);
    setPatentNotFound(false);
  };

  useEffect(() => {
    if (
      (subjectMode === "patent" && subjectNumbers.length > 0) ||
      (subjectMode === "claim" &&
        claims.length > 0 &&
        JSON.stringify(claims[0]) !== JSON.stringify({ "1": "" })) ||
      (subjectMode === "feature" &&
        features.length > 0 &&
        JSON.stringify(features[0]) !== JSON.stringify({ "1": "" }))
    ) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [subjectMode, subjectNumbers, subjectMode]);

  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetState();
    }
  }, []);

  const handleCreateProject = async () => {
    setIsConfirmed(true);
    updateIsProjectCreationInProgress(true);
    updateSpinnerText("Creating project...");
    if (subjectMode === "patent") {
      await createProjectFromNumber(ProjectType.INV);
    } else {
      await createProjectFromLanguage(ProjectType.INV);
    }
    updateIsProjectCreationInProgress(false);
  };

  return (
    <CreateProjectPageLayout
      isConfirmed={isConfirmed}
      resetState={resetState}
      disabled={!isDetailsCompleted || !isSubjectCompleted}
      handleCreate={handleCreateProject}
      isPortfolio={false}
      title="New Invalidity Project"
    >
      <ProjectDetailsStep startExpanded={true} isPortfolio={false} />
      <InvaliditySubjectStep
        isCompleted={isSubjectCompleted}
        startExpanded={false}
      />
    </CreateProjectPageLayout>
  );
};

export default CreateProjectPage;
