"use client";
import * as React from "react";
import { cn } from "@/lib/utils";

export type CitationTextareaRef = {
  textArea: HTMLTextAreaElement;
  maxHeight: number;
  minHeight: number;
};

type CitationTextareaProps = {
  maxHeight?: number;
  minHeight?: number;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const CitationTextarea = React.forwardRef<
  CitationTextareaRef,
  CitationTextareaProps
>(
  (
    {
      maxHeight = Number.MAX_SAFE_INTEGER,
      minHeight = 36,
      className,
      onChange,
      value,
      ...props
    }: CitationTextareaProps,
    ref: React.Ref<CitationTextareaRef>
  ) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [containerHeight, setContainerHeight] = React.useState(minHeight);

    React.useEffect(() => {
      const textArea = textAreaRef.current;
      if (!textArea) return;

      const updateHeight = () => {
        textArea.style.height = "auto";
        const scrollHeight = textArea.scrollHeight;
        const newHeight = Math.min(Math.max(scrollHeight, minHeight), maxHeight);
        setContainerHeight(newHeight);
        textArea.style.height = `${newHeight}px`;
      };

      updateHeight();

      textArea.addEventListener("input", updateHeight);
      window.addEventListener("resize", updateHeight);

      return () => {
        textArea.removeEventListener("input", updateHeight);
        window.removeEventListener("resize", updateHeight);
      };
    }, [minHeight, maxHeight, value]);

    React.useImperativeHandle(ref, () => ({
      textArea: textAreaRef.current as HTMLTextAreaElement,
      focus: () => textAreaRef.current?.focus(),
      maxHeight,
      minHeight,
    }));

    return (
      <div
        style={{
          height: `${containerHeight}px`,
          minHeight: `${minHeight}px`,
          maxHeight: `${maxHeight}px`,
        }}
        className="relative overflow-hidden"
      >
        <textarea
          {...props}
          value={value}
          ref={textAreaRef}
          onChange={(e) => {
            onChange?.(e);
          }}
          className={cn(
            "absolute top-0 left-0 w-full h-full resize-none overflow-hidden",
            "rounded-md border border-input bg-background px-3 py-2 text-sm",
            "ring-offset-background placeholder:text-muted-foreground",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
            "disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
        />
      </div>
    );
  }
);

CitationTextarea.displayName = "CitationTextarea";
