import React, { useState, useEffect } from "react";
import { RadioGroup, RadioGroupWithText } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  CreateStepGroup,
  PatentNumbersInputCreate,
  SearchFilters,
  AssigneePortfolioTable,
} from ".";
import { Patent } from "@/types/types";
import { useCreateProjectStore } from "@/store";
import { usePortfolio } from "@/hooks";
import { Loader, UploadedFileDisplay, DragAndDropFileArea } from "@/components";

const PortfolioPatentsStep: React.FC<{
  handleSearchAgain?: () => void;
  isReference: boolean;
  setInputNumbers: (numbers: string[]) => void;
  inputNumbers: string[];
  isCompleted: boolean;
}> = ({ isReference, setInputNumbers, inputNumbers, isCompleted }) => {
  const [findClicked, setFindClicked] = useState(false);
  const {
    updateToDate,
    updateCpcCodes,
    toDate,
    fromDate,
    referenceOption,
    assignees,
    cpcCodes,
    countryCodes,
    keywords,
    types,
    statuses,
    maxResults,
    subjectNumbers,
    referenceNumbers,
    referenceMode,
    subjectMode,
    files,
    addFile,
    updateFiles,
    updateSubjectDetails,
    updateReferenceDetails,
    updateAssignees,
    updateFromDate,
    updateMaxResults,
    updateCountryCodes,
    updateKeywords,
    updateTypes,
    updateStatuses,
    updateReferenceOption,
    updateReferenceMode,
    updateSubjectMode,
    updateReferenceNumbers,
    updateSubjectNumbers,
  } = useCreateProjectStore();

  const { getPatentsForAssignee } = usePortfolio();
  const [foundPatents, setFoundPatents] = useState<Patent[]>([]);
  const [isAssigneeLoading, setIsAssigneeLoading] = useState(false);
  const [fileTypeError, setFileTypeError] = useState<string | null>(null);

  useEffect(() => {
    setFindClicked(false);
  }, []);

  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      for (let selectedFile of selectedFiles) {
        const isFileTypeValid =
          selectedFile.type === "application/pdf" || selectedFile.type === "text/plain";
        const isFileSizeValid = selectedFile.size <= 5 * 1024 * 1024; // 5MB

        if (isFileTypeValid && isFileSizeValid) {
          setFileTypeError(null);
          addFile(selectedFile);
        } else {
          let errorMessage = "";
          if (!isFileTypeValid) {
            errorMessage +=
              "File type is not supported. Please upload a PDF or text file. ";
          }
          setFileTypeError(errorMessage);
        }
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    updateFiles(files.filter((_, i) => i !== index));
  };

  const handlePortfolioSearchForPatents = async () => {
    if (!assignees) {
      return;
    }
    setIsAssigneeLoading(true);
    setFindClicked(true);

    const fromDateString = fromDate ? fromDate.toISOString().split("T")[0] : "";
    const toDateString = toDate ? toDate.toISOString().split("T")[0] : "";
    const response = await getPatentsForAssignee(
      assignees,
      fromDateString,
      toDateString,
      cpcCodes,
      types
    );

    updateAssignees([]);
    updateFromDate(null);
    updateToDate(null);
    updateCpcCodes([]);
    updateTypes([]);

    setIsAssigneeLoading(false);
    setFoundPatents(response.data);
  };

  const handleReset = () => {
    if (isReference) {
      updateReferenceDetails([]);
      updateReferenceNumbers([]);
    } else {
      updateSubjectDetails([]);
      updateSubjectNumbers([]);
    }
    setFindClicked(false);
    setIsAssigneeLoading(false);
    setFoundPatents([]);
    updateMaxResults(100);
  };

  return (
    <CreateStepGroup
      stepName={isReference ? "Reference Options" : "Subjects"}
      isCompleted={isCompleted}
      showSearchAgain={
        (!isReference && subjectNumbers?.length > 0) ||
        (isReference && referenceNumbers?.length > 0)
      }
      handleSearchAgain={handleReset}
    >
      <div className="space-y-6">
        {isReference && referenceNumbers?.length === 0 && !findClicked && (
          <RadioGroup
            defaultValue={referenceOption}
            onValueChange={(value) => updateReferenceOption(value as "unique" | "same")}
            className="flex flex-wrap gap-4"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupWithText
                value="unique"
                id="unique"
                label="Find unique references for each subject"
              />
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupWithText
                value="same"
                id="same"
                label="Use the same reference set across all subjects"
              />
            </div>
          </RadioGroup>
        )}

        {(!isReference || (isReference && referenceOption === "same")) && (
          <>
            {((isReference && referenceNumbers?.length === 0) ||
              (!isReference && subjectNumbers?.length === 0)) &&
              !findClicked && (
                <RadioGroup
                  defaultValue={isReference ? referenceMode : subjectMode}
                  onValueChange={(value) =>
                    isReference
                      ? updateReferenceMode(value as "assignee" | "numbers" | "upload")
                      : updateSubjectMode(value as "assignee" | "numbers" | "upload")
                  }
                  className="flex flex-wrap gap-4"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupWithText
                      value="numbers"
                      id="numbers"
                      label="Enter patent/application numbers"
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupWithText
                      value="assignee"
                      id="assignee"
                      label="Search for patents"
                    />
                  </div>
                  {isReference && (
                    <div className="flex items-center space-x-2">
                      <RadioGroupWithText
                        value="upload"
                        id="upload"
                        label="Upload files"
                      />
                    </div>
                  )}
                </RadioGroup>
              )}

            {((isReference && referenceMode === "numbers") ||
              (!isReference && subjectMode === "numbers")) && (
              <PatentNumbersInputCreate
                isReference={isReference}
                isInvalidity={false}
                setFindClicked={setFindClicked}
              />
            )}

            {isReference && referenceMode === "upload" && (
              <div className="space-y-4">
                <div className="flex flex-wrap gap-2">
                  {files.map((file, index) => (
                    <UploadedFileDisplay
                      file={file}
                      key={index}
                      onRemove={() => handleRemoveFile(index)}
                    />
                  ))}
                </div>
                {fileTypeError && (
                  <Alert variant="destructive">
                    <AlertDescription>{fileTypeError}</AlertDescription>
                  </Alert>
                )}
                <DragAndDropFileArea
                  handleFiles={handleFileChange}
                  supportedFileTypes={[".pdf", ".txt"]}
                />
              </div>
            )}

            {((isReference && referenceMode === "assignee") ||
              (!isReference && subjectMode === "assignee")) && (
              <>
                {foundPatents.length > 0 && (
                  <AssigneePortfolioTable
                    patents={foundPatents}
                    setSelectedReferences={setInputNumbers}
                    selectedReferences={inputNumbers}
                  />
                )}
                {foundPatents.length === 0 && !isAssigneeLoading && (
                  <>
                    <SearchFilters
                      assignees={assignees}
                      setAssignees={updateAssignees}
                      fromDate={fromDate}
                      setFromDate={updateFromDate}
                      toDate={toDate}
                      setToDate={updateToDate}
                      maxResults={maxResults}
                      setMaxResults={updateMaxResults}
                      cpcCodes={cpcCodes}
                      setCpcCodes={updateCpcCodes}
                      countryCodes={countryCodes}
                      setCountryCodes={updateCountryCodes}
                      keywords={keywords}
                      setKeywords={updateKeywords}
                      disabled={false}
                      types={types}
                      setTypes={updateTypes}
                      statuses={statuses}
                      setStatuses={updateStatuses}
                      isInCreate={true}
                    />
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={handlePortfolioSearchForPatents}
                        disabled={!assignees.length}
                      >
                        Search for patents
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}

        {isAssigneeLoading && <Loader message="Searching for patents" />}
      </div>
    </CreateStepGroup>
  );
};

export default PortfolioPatentsStep;
