/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppStateStore, useProjectStore } from "@/store";
import { useViz, usePortfolio } from "@/hooks";
import { Project, ProjectType } from "@/types/types";
import { Loader } from "@/components";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

/**
 * @description Create project from portfolio modal
 *
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} handleContinue - Function to continue the modal.
 */
interface CreateProjectFromPortfolioModalProps {
  open: boolean;
  handleClose: () => void;
  name: string;
  portfolioItem: Project;
  setSelectedPortfolioItem: (portfolioItem: Project) => void;
}

const CreateProjectFromPortfolioModal: React.FC<
  CreateProjectFromPortfolioModalProps
> = ({ open, handleClose, name, portfolioItem, setSelectedPortfolioItem }) => {
  const { getProjectMetadata } = useViz();
  const { createProjectFromPortfolio } = usePortfolio();
  const {
    updateCurrentProjectId,
    currentPortfolioDetails,
    updateCurrentPortfolioDetails,
    currentPortfolioId,
  } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleCreateProject = async () => {
    setIsLoading(true);
    // setFullProjectCreationModalOpen(false);
    const projectId = portfolioItem?.project_id || portfolioItem?.id;
    if (!portfolioItem) {
      addErrorMessage("No portfolio item selected");
      return;
    }
    const response = await createProjectFromPortfolio(
      projectId,
      currentPortfolioDetails.id
    );
    const updatedProjects = currentPortfolioDetails.projects.map((project) =>
      project.id === portfolioItem.id
        ? { ...portfolioItem, type: ProjectType.PFP }
        : project
    );
    updateCurrentPortfolioDetails({
      ...currentPortfolioDetails,
      projects: updatedProjects,
    });

    if (response.success) {
      updateCurrentProjectId(projectId);
      await getProjectMetadata(projectId);
      navigate(`/portfolio/${currentPortfolioId}/project/${projectId}/subject`);
      setSelectedPortfolioItem(null);
    } else {
      addErrorMessage("Error creating project from portfolio");
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        {isLoading ? (
          <Loader message="Creating project..." />
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Create project from portfolio</DialogTitle>
              <DialogDescription>
                You are about to create a new project from the portfolio subject{" "}
                <strong>{name}</strong>. This may result in a project creation charge.
              </DialogDescription>
            </DialogHeader>

            <DialogFooter>
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleCreateProject}>Confirm</Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateProjectFromPortfolioModal;
