import { create } from "zustand";
import { AlertMessage, User, LoadingMessage } from "../types/types";

interface AppState {
  isLoading: boolean;
  loadingMessages: LoadingMessage[];
  statusMessages: AlertMessage[];
  isError: boolean;
  orgUsers: User[];
  isStoreEmpty: boolean;
  searchChatProjectId: string;

  updateIsLoading: (isLoading: boolean) => void;
  updateIsError: (isError: boolean) => void;
  updateOrgUsers: (orgUsers: User[]) => void;
  updateIsStoreEmpty: (isStoreEmpty: boolean) => void;
  updateSearchChatProjectId: (searchChatProjectId: string) => void;
  addErrorMessage: (message: string) => void;
  addSuccessMessage: (message: string) => void;
  removeStatusMessage: (key: number) => void;
  addLoadingMessage: (message: string, key: string) => void;
  removeLoadingMessage: (key: string) => void;
}

const initialState: Omit<
  AppState,
  | "updateIsLoading"
  | "updateIsError"
  | "updateOrgUsers"
  | "updateIsStoreEmpty"
  | "updateSearchChatProjectId"
  | "addErrorMessage"
  | "addSuccessMessage"
  | "removeStatusMessage"
  | "updateCreateProjectSpinnerText"
  | "updateCreateProjectError"
  | "addLoadingMessage"
  | "removeLoadingMessage"
> = {
  isLoading: false,
  loadingMessages: [],
  isError: false,
  statusMessages: [],
  orgUsers: [],
  isStoreEmpty: true,
  searchChatProjectId: "",
};

const useAppStateStore = create<AppState>((set) => ({
  ...initialState,

  updateIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),

  updateIsError: (isError: boolean) => set(() => ({ isError })),

  updateOrgUsers: (orgUsers: User[]) => set(() => ({ orgUsers })),

  updateIsStoreEmpty: (isStoreEmpty: boolean) => set(() => ({ isStoreEmpty })),

  updateSearchChatProjectId: (searchChatProjectId: string) =>
    set(() => ({ searchChatProjectId })),

  addErrorMessage: (message: string) =>
    set((state) => ({
      statusMessages: [
        ...state.statusMessages,
        { message, key: new Date().getTime(), severity: "error" },
      ],
    })),

  addSuccessMessage: (message: string) =>
    set((state) => ({
      statusMessages: [
        ...state.statusMessages,
        { message, key: new Date().getTime(), severity: "success" },
      ],
    })),

  removeStatusMessage: (key: number) =>
    set((state) => ({
      statusMessages: state.statusMessages.filter((msg) => msg.key !== key),
    })),

  addLoadingMessage: (message: string, key: string) =>
    set((state) => ({
      loadingMessages: [...state.loadingMessages, { message, key }],
    })),

  removeLoadingMessage: (key: string) =>
    set((state) => ({
      loadingMessages: state.loadingMessages.filter((msg) => msg.key !== key),
    })),
}));

export default useAppStateStore;