/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect } from "react";
import { useProjectStore } from "@/store";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ElementType } from "@/types/types";

interface ElementSelectProps {
  onElementSelect: (selectedElements: string[]) => void;
  elementType: ElementType.CLAIM | ElementType.FEATURE;
}

function ElementSelect({ onElementSelect, elementType }: ElementSelectProps) {
  const { currentProjectDetails } = useProjectStore();
  const [open, setOpen] = useState(false);
  const [elements, setElements] = useState<string[]>([]);
  const [selectedElements, setSelectedElements] = useState<string[]>([]);

  useEffect(() => {
    if (elementType === ElementType.CLAIM) {
      const claimLabels =
      currentProjectDetails?.claims?.map((claim) => Object.keys(claim)[0]) || [];
      setElements(claimLabels);
      setSelectedElements(claimLabels);
    } else {
      const featureLabels =
        currentProjectDetails?.features?.map((feature) => Object.keys(feature)[0]) || [];
      setElements(featureLabels);
      setSelectedElements(featureLabels);
    }
  }, [currentProjectDetails, elementType]);

  const handleElementToggle = (element: string) => {
    setSelectedElements((prev) =>
      prev.includes(element) ? prev.filter((e) => e !== element) : [...prev, element]
    );
  };

  const handleSelectAll = () => {
    setSelectedElements((prev) =>
      prev.length === elements.length ? [] : [...elements]
    );
  };

  const handleClose = () => {
    onElementSelect(selectedElements);
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          <div className="flex items-center space-x-1 truncate">
            {selectedElements.length === elements.length ? (
              <span>All {elementType}s</span>
            ) : selectedElements.length > 0 ? (
              <>
                {selectedElements.slice(0, 2).map((element) => (
                  <span
                    key={element}
                    className="text-secondary-foreground px-1 rounded border"
                  >
                    {element}
                  </span>
                ))}
                {selectedElements.length > 2 && (
                  <span className="text-secondary-foreground">
                    +{selectedElements.length - 2} more
                  </span>
                )}
              </>
            ) : (
              <span>Select {elementType}s</span>
            )}
          </div>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <ScrollArea className="h-[300px]">
          <div className="p-1">
            <Button
              variant="ghost"
              className="w-full justify-start font-bold"
              onClick={handleSelectAll}
            >
              <div className="flex items-center">
                {selectedElements.length === elements.length && (
                  <CheckIcon className="mr-2 h-4 w-4" />
                )}
                <span>Select All</span>
              </div>
            </Button>
            {elements.map((element) => (
              <Button
                key={element}
                variant="ghost"
                className="w-full justify-start"
                onClick={() => handleElementToggle(element)}
              >
                <div className="flex items-center">
                  {selectedElements.includes(element) && (
                    <CheckIcon className="mr-2 h-4 w-4" />
                  )}
                  <span>{element}</span>
                </div>
              </Button>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
}

export default ElementSelect;
